import { PropsWithChildren, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { useIBCreate } from 'src/container/ib-project';
import { useIBProposalCreate } from 'src/container/ib-proposal-create';
import { useIBProposalUpdate } from 'src/container/ib-proposal-update';
import { RequestIBDto, RequestIBProposalDto, ResponseIBProposalDto } from 'src/generated/model';
import { meState } from 'src/store';
import AlertV2 from '../common/AlertV2';
import { ButtonV2 } from '../common/ButtonV2';
import { Typography } from '../common/Typography';
import ColorSVGIcon from '../icon/ColorSVGIcon';
import { InputField } from './InputField';

interface CoordinatorEE_FAQ_AddRefProps {
  modalOpen: boolean;
  setModalClose: () => void;
  projectId?: number;
  handleBack?: () => void;
  proposalData?: ResponseIBProposalDto;
  onSuccess: (action: 'save' | 'requestApproval') => void;
  // create : 프로젝트 생성 / update : 제안서 수정 / proposal : 후순위 제안서 생성
  type: 'create' | 'update' | 'proposal';
  ablePropragation?: boolean;
}

export function CoordinatorEE_FAQ_AddRef({
  modalOpen,
  setModalClose,
  handleBack,
  type,
  projectId,
  onSuccess,
  proposalData,
  ablePropragation = false,
}: PropsWithChildren<CoordinatorEE_FAQ_AddRefProps>) {
  const me = useRecoilValue(meState);
  const [isOpen, setIsOpen] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestIBProposalDto>({
    defaultValues:
      type === 'update' && proposalData
        ? proposalData
        : {
            // rank 값은 어디서 받아오는 게 효율적일지 고민..
            rank: 1,
            modelPaper: '',
            modelPaperSummary: '',
            researchTopic: '',
            researchQuestion: '',
            researchNeed: '',
            researchMethod: '',
            subject: '',
          },
  });

  const { createIBProject, isLoading, isError, error } = useIBCreate({
    onSuccess: () => {
      setModalClose();
      onSuccess('save');
    },
    onError: (error) => {
      console.error('IB 프로젝트 생성 중 오류 발생:', error);
    },
  });

  const { createIBProposal } = useIBProposalCreate({
    onSuccess: () => {
      setModalClose();
      onSuccess('save');
    },
    onError: (error) => {
      console.error('IB 프로젝트 생성 중 오류 발생:', error);
    },
  });

  const { updateIBProposal } = useIBProposalUpdate({
    onSuccess: () => {
      setModalClose();
      onSuccess('save');
    },
    onError: (error) => {
      console.error('IB 프로젝트 생성 중 오류 발생:', error);
    },
  });

  const onSubmit = (formProposalData: RequestIBProposalDto) => {
    if (isLoading) return;

    if (type === 'proposal' && projectId !== undefined) {
      createIBProposal({ id: projectId, data: formProposalData });
    } else if (type === 'update' && projectId !== undefined && proposalData) {
      const proposalId = proposalData.id;
      if (proposalId !== undefined) {
        updateIBProposal({ id: projectId, proposalId, data: formProposalData });
      } else {
        console.error('수정할 제안서 ID가 없습니다.');
      }
    } else {
      if (!me?.id) {
        console.error('Leader ID가 없습니다. 로그인 상태를 확인하세요.');
        return;
      }
      const requestData: RequestIBDto = {
        title: 'EE 제안서',
        ibType: 'EE',
        description: 'EE 제안서입니다.',
        leaderId: me.id,
        proposals: [formProposalData],
      };

      createIBProject(requestData);
    }
  };

  return (
    <div
      className={`fixed inset-0 z-60 flex h-screen w-full items-center justify-center bg-black bg-opacity-50 ${
        !modalOpen && 'hidden'
      }`}
      onClick={(e) => {
        if (!ablePropragation) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div className={`relative w-[848px] overflow-hidden rounded-xl bg-white px-8`}>
        <div className="sticky top-0 z-10 flex h-[88px] items-center justify-between bg-white/70 pb-6 pt-8 backdrop-blur-[20px]">
          <Typography variant="title1">제안서 작성</Typography>
          <ColorSVGIcon.Close color="gray700" size={32} onClick={setModalClose} />
        </div>

        <form>
          <div className="scroll-box flex max-h-[608px] flex-col gap-6 overflow-auto pb-8 pt-4">
            <InputField label="제목" name="modelPaper" control={control} placeholder="제목을 입력해주세요" />
            <InputField
              label="내용"
              name="modelPaperSummary"
              control={control}
              placeholder="내용을 입력해주세요"
              type="textarea"
            />
            <div>TODO : 파일을 첨부해주세요.</div>
          </div>

          <div
            className={
              'sticky bottom-0 flex h-[104px] justify-end gap-4 border-t border-t-primary-gray-100 bg-white/70 pb-8 pt-6 backdrop-blur-[20px]'
            }
          >
            <div className="flex justify-end gap-3">
              <ButtonV2 variant="solid" color="gray100" size={48} onClick={handleBack}>
                이전
              </ButtonV2>
              <ButtonV2 type="submit" variant="solid" color="orange800" size={48} onClick={handleSubmit(onSubmit)}>
                저장하기
              </ButtonV2>
            </div>
          </div>
        </form>
      </div>
      {isOpen && (
        <AlertV2 confirmText="확인" message={`참고자료가 \n저장되었습니다`} onConfirm={() => setIsOpen(!isOpen)} />
      )}
    </div>
  );
}
