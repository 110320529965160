// P-T-012 : 코디선생님 프로젝트 메인 화면
// /teacher/ib/coordinatorPage
// https://www.notion.so/superschoolofficial/P-T-012-126e90ac0a99800e94d4da062bdc5417
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { Typography } from 'src/components/common/Typography';
import IBLayout from 'src/components/ib/IBLayout';
import SolidSVGIcon from 'src/components/icon/SolidSVGIcon';

type ProjectType = 'IB' | 'CAS' | 'EE' | 'TOK';

export const CoordinatorDetailEEFaqPage = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const [type, setType] = useState<ProjectType>('IB');

  const title = '소논문 연구 및 현장 조사를 위한 윤리적 지침(한국어)';
  const createAt = '2024.08.01';
  const writer = '고을레라';
  const content = '소논문에 관련된 IB 공식문서입니다.';

  return (
    <div className="col-span-6">
      <div className="h-screen w-full ">
        <IBLayout
          topContent={
            <div className="">
              <div className="h-[164px] w-full  pt-16">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <BadgeV2 color="gray" size={24} type="solid_strong">
                      프로젝트 관리
                    </BadgeV2>
                    <BadgeV2 color="dark_green" size={24} type="solid_strong">
                      EE
                    </BadgeV2>
                  </div>
                  <Breadcrumb
                    data={{
                      '프로젝트 관리': '/teacher/ib/coordinatorPage/EE',
                      'EE 상세': '',
                    }}
                  />
                </div>
                <div className=" mt-3 flex h-10 w-full flex-row items-center justify-between gap-2">
                  <Typography variant="heading">참고자료 및 FAQ</Typography>
                </div>
              </div>
            </div>
          }
          hasContour={false}
          bottomContent={
            <div className="flex h-full w-full ">
              <div className="my-6 w-full rounded-lg bg-white p-6">
                <section>
                  <Typography variant="title1" className="text-gray-900">
                    {title}
                  </Typography>
                  <div className="mt-1 flex">
                    <Typography variant="body3" className="text-gray-500">
                      {createAt}
                    </Typography>
                    <SolidSVGIcon.Bullet color="gray400" />
                    <Typography variant="body3" className="text-gray-500">
                      {writer}
                    </Typography>
                  </div>
                </section>
                <section className="py-6">
                  <div className="h-[1px] bg-gray-100"></div>
                </section>
                <section className="pb-10">
                  <Typography variant="body1" className="text-gray-900">
                    {content}
                  </Typography>
                </section>
                <section className="pb-10">파일 목록영역</section>
                <section className="flex justify-between">
                  <ButtonV2 variant="outline" size={40} color="gray400">
                    수정
                  </ButtonV2>
                  <ButtonV2
                    variant="solid"
                    size={40}
                    color="gray100"
                    onClick={() => push('/teacher/ib/coordinatorPage/EE')}
                  >
                    목록 돌아가기
                  </ButtonV2>
                </section>
              </div>
            </div>
          }
          bottomBgColor="bg-gray-50"
        />
      </div>
    </div>
  );
};
