import { useHistory } from 'react-router-dom';
import { ResponseIBDto } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';
import { BadgeV2 } from '../common/BadgeV2';
import { ButtonV2 } from '../common/ButtonV2';
import { Typography } from '../common/Typography';

interface ProjectCardProps {
  data: ResponseIBDto;
  userType?: 'student' | 'teacher';
}

export default function ProjectCard({ data, userType = 'student' }: ProjectCardProps) {
  switch (data.ibType) {
    case 'CAS_NORMAL':
      return <CasProjectCard data={data} userType={userType} />;
    case 'CAS_PROJECT':
      return <CasProjectCard data={data} userType={userType} />;
    case 'EE':
      return <EeProjectCard data={data} userType={userType} />;
    case 'TOK_ESSAY':
      return <TokProjectCard data={data} userType={userType} />;
    default:
      return null;
  }
}

function CasProjectCard({ data }: ProjectCardProps) {
  const { t } = useLanguage();
  return (
    <div className="flex h-[308px] w-[308px] cursor-pointer flex-col rounded-xl border border-primary-gray-200 bg-white px-5 shadow">
      <nav className="flex w-full flex-row items-center justify-between pt-5">
        <BadgeV2 color="dark_green" size={24} type="solid_strong">
          CAS
        </BadgeV2>
        <BadgeV2 color="gray" size={24} type="line">
          {t(`IBStatus.${data.status}`)}
        </BadgeV2>
      </nav>
      <main>{data.title}</main>
    </div>
  );
}
function EeProjectCard({ data, userType }: ProjectCardProps) {
  const { t } = useLanguage();
  const { push } = useHistory();
  const approvedProposal = data.proposals?.find((proposal) => proposal.status === 'ACCEPT');

  return (
    <div
      className={`flex ${
        data.status !== 'COMPLETE' && 'h-[308px]'
      } box-border w-[308px] cursor-pointer flex-col rounded-xl border border-primary-gray-200 bg-white shadow`}
    >
      {/* flag 영역 */}
      {userType === 'teacher' && data.leader.studentGroup && (
        <div className="box-border flex flex-row items-center border-b border-b-primary-gray-100 px-5 py-3">
          <Typography variant="body3" className="text-primary-gray-700">
            {data.leader.studentGroup.group.grade}
            {String(data.leader.studentGroup.group.klass).padStart(2, '0')}
            {String(data.leader.studentGroup.studentNumber).padStart(2, '0')}
          </Typography>
          <span className="mx-1">·</span>
          <Typography variant="body3" className="text-primary-gray-700">
            {data.leader.name}
          </Typography>
        </div>
      )}
      <div className="flex w-[308px] flex-1 flex-col justify-between px-5 pt-5">
        <div>
          <nav className="box-border flex w-full flex-row items-center justify-between">
            <BadgeV2 color="dark_green" size={24} type="solid_strong">
              EE
            </BadgeV2>
            <BadgeV2
              color={
                data.status === 'WAIT_PLAN_APPROVE' && approvedProposal
                  ? 'blue'
                  : data.status === 'IN_PROGRESS' || data.status === 'WAIT_COMPLETE'
                  ? 'blue'
                  : data.status === 'REJECT_MENTOR' ||
                    data.status === 'REJECT_PLAN' ||
                    data.status === 'REJECT_COMPLETE'
                  ? 'red'
                  : data.status === 'COMPLETE'
                  ? 'green'
                  : 'gray'
              }
              size={24}
              type="line"
            >
              {data.status === 'WAIT_PLAN_APPROVE' && approvedProposal ? '보완완료' : t(`IBStatus.${data.status}`)}
            </BadgeV2>
          </nav>
          {/* info 영역 */}
          <main
            className="box-border flex flex-col gap-3 py-6"
            onClick={() => push(userType === 'teacher' ? `/teacher/ib/ee/${data.id}` : `/ib/student/ee/${data.id}`)}
          >
            <Typography variant="title2">{data.title}</Typography>
            <div className="flex w-full flex-col gap-1">
              {data.status === 'PENDING' && data.proposals && (
                <>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      1순위
                    </Typography>
                    <Typography variant="body3" className="font-normal">
                      {data.proposals[0].subject}
                    </Typography>
                  </span>
                  {data.proposals.length > 1 && (
                    <span className="flex flex-row gap-1">
                      <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                        2순위
                      </Typography>
                      <Typography variant="body3" className="font-normal">
                        {data.proposals[1].subject}
                      </Typography>
                    </span>
                  )}
                </>
              )}

              {data.status === 'WAIT_MENTOR' && data.proposals && (
                <>
                  {data.proposals.length > 2 ? (
                    <>
                      <span className="flex flex-row gap-1">
                        <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                          후순위
                        </Typography>
                        <Typography variant="body3" className="font-normal">
                          -
                        </Typography>
                      </span>
                      <span className="flex flex-row gap-1">
                        <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                          지도교사
                        </Typography>
                        <Typography variant="body3" className="font-normal">
                          미정
                        </Typography>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="flex flex-row gap-1">
                        <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                          1순위
                        </Typography>
                        <Typography variant="body3" className="font-normal">
                          {data.proposals[0].subject}
                        </Typography>
                      </span>
                      {data.proposals.length > 1 && (
                        <span className="flex flex-row gap-1">
                          <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                            2순위
                          </Typography>
                          <Typography variant="body3" className="font-normal">
                            {data.proposals[1].subject}
                          </Typography>
                        </span>
                      )}
                    </>
                  )}
                </>
              )}

              {data.status === 'WAITING_FOR_NEXT_PROPOSAL' && data.proposals && (
                <>
                  {data.proposals.length > 2 ? (
                    <>
                      <span className="flex flex-row gap-1">
                        <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                          후순위
                        </Typography>
                        <Typography variant="body3" className="font-normal">
                          {data.proposals[2].subject}
                        </Typography>
                      </span>
                      <span className="flex flex-row gap-1">
                        <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                          지도교사
                        </Typography>
                        <Typography variant="body3" className="font-normal">
                          미정
                        </Typography>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="flex flex-row gap-1">
                        <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                          후순위
                        </Typography>
                        <Typography variant="body3" className="font-normal">
                          -
                        </Typography>
                      </span>
                      {data.proposals.length > 1 && (
                        <span className="flex flex-row gap-1">
                          <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                            지도교사
                          </Typography>
                          <Typography variant="body3" className="font-normal">
                            미정
                          </Typography>
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
              {data.status === 'REJECT_PLAN' && data.proposals && approvedProposal && (
                <>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지식영역
                    </Typography>
                    {/* TODO 제안서 반려 시 반려된 과목 정보 불러오기 */}
                    <Typography variant="body3" className="font-normal">
                      {approvedProposal.subject}
                    </Typography>
                  </span>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지도교사
                    </Typography>
                    {/* TODO 제안서 반려 시 지정된 멘토 정보 불러오기 */}
                    <Typography variant="body3" className="font-normal">
                      {data.mentor.name}&nbsp;선생님
                    </Typography>
                  </span>
                </>
              )}

              {data.status === 'IN_PROGRESS' && data.proposals && approvedProposal && (
                <>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지식영역
                    </Typography>
                    <Typography variant="body3" className="font-normal">
                      {approvedProposal.subject}
                    </Typography>
                  </span>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지도교사
                    </Typography>
                    <Typography variant="body3" className="font-normal">
                      {data.mentor.name}&nbsp;선생님
                    </Typography>
                  </span>
                </>
              )}

              {data.status === 'WAIT_COMPLETE' && data.proposals && approvedProposal && (
                <>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지식영역
                    </Typography>
                    <Typography variant="body3" className="font-normal">
                      {approvedProposal.subject}
                    </Typography>
                  </span>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지도교사
                    </Typography>
                    <Typography variant="body3" className="font-normal">
                      {data.mentor.name}&nbsp;선생님
                    </Typography>
                  </span>
                </>
              )}

              {data.status === 'WAIT_PLAN_APPROVE' && data.proposals && approvedProposal && (
                <>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지식영역
                    </Typography>
                    <Typography variant="body3" className="font-normal">
                      {approvedProposal.subject}
                    </Typography>
                  </span>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지도교사
                    </Typography>
                    <Typography variant="body3" className="font-normal">
                      {data.mentor.name}&nbsp;선생님
                    </Typography>
                  </span>
                </>
              )}
              {data.status === 'COMPLETE' && data.proposals && approvedProposal && (
                <>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지식영역
                    </Typography>
                    <Typography variant="body3" className="font-normal">
                      {approvedProposal.subject}
                    </Typography>
                  </span>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지도교사
                    </Typography>
                    <Typography variant="body3" className="font-normal">
                      {data.mentor.name}&nbsp;선생님
                    </Typography>
                  </span>
                </>
              )}
              {data.status === 'REJECT_COMPLETE' && data.proposals && approvedProposal && (
                <>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지식영역
                    </Typography>
                    <Typography variant="body3" className="font-normal">
                      {approvedProposal.subject}
                    </Typography>
                  </span>
                  <span className="flex flex-row gap-1">
                    <Typography variant="body3" className="w-14 font-normal text-primary-gray-500">
                      지도교사
                    </Typography>
                    <Typography variant="body3" className="font-normal">
                      {data.mentor.name}&nbsp;선생님
                    </Typography>
                  </span>
                </>
              )}
            </div>
          </main>
        </div>
        <footer className="box-border pb-5">
          {data.status === 'IN_PROGRESS' && (
            <div className="flex flex-row items-center justify-between gap-2">
              <ButtonV2
                size={40}
                color="gray100"
                variant="solid"
                className="w-full"
                onClick={() =>
                  push(userType === 'teacher' ? `/teacher/ib/ee/${data.id}` : `/ib/student/ee/${data.id}`, {
                    type: 'RRS',
                  })
                }
              >
                {userType === 'student' ? 'RRS 작성' : 'RRS 보기'}
              </ButtonV2>
              <ButtonV2
                size={40}
                color="gray100"
                variant="solid"
                className="w-full"
                onClick={() =>
                  push(userType === 'teacher' ? `/teacher/ib/ee/${data.id}` : `/ib/student/ee/${data.id}`, {
                    type: 'ESSAY',
                  })
                }
              >
                {userType === 'student' ? '에세이 작성' : '에세이 보기'}
              </ButtonV2>
            </div>
          )}
          {data.status === 'WAIT_COMPLETE' &&
            (userType === 'student' ? (
              <div className="flex items-center">
                <ButtonV2
                  size={40}
                  color="gray100"
                  variant="solid"
                  className="w-full"
                  onClick={() => push(`/ib/student/ee/${data.id}`)}
                >
                  에세이 상세보기
                </ButtonV2>
              </div>
            ) : // 선생님일 경우
            data.rppf?.status === 'WAIT_COMPLETE' ? (
              <>
                {data.rppf?.academicIntegrityConsent ? (
                  <div className="flex items-center">
                    <ButtonV2
                      size={40}
                      color="orange100"
                      variant="solid"
                      className="w-full"
                      onClick={() =>
                        push(userType === 'teacher' ? `/teacher/ib/ee/${data.id}` : `/ib/student/ee/${data.id}`, {
                          type: 'RRS',
                        })
                      }
                    >
                      완료승인
                    </ButtonV2>
                  </div>
                ) : (
                  <div className="flex flex-row items-center justify-between gap-2">
                    <ButtonV2
                      size={40}
                      color="gray100"
                      variant="solid"
                      className="w-full"
                      onClick={() =>
                        push(userType === 'teacher' ? `/teacher/ib/ee/${data.id}` : `/ib/student/ee/${data.id}`, {
                          type: 'RRS',
                        })
                      }
                    >
                      보완요청
                    </ButtonV2>
                    <ButtonV2
                      size={40}
                      color="orange100"
                      variant="solid"
                      className="w-full"
                      onClick={() =>
                        push(userType === 'teacher' ? `/teacher/ib/ee/${data.id}` : `/ib/student/ee/${data.id}`, {
                          type: 'ESSAY',
                        })
                      }
                    >
                      IB제출정보 기입
                    </ButtonV2>
                  </div>
                )}
              </>
            ) : null)}
          {data.status === 'REJECT_PLAN' && (
            <div className="flex items-center">
              <ButtonV2
                size={40}
                color="gray100"
                variant="solid"
                className="w-full"
                onClick={() =>
                  push(
                    userType === 'teacher'
                      ? `/teacher/ib/ee/${data.id}/proposal/${approvedProposal?.id}`
                      : `/ib/student/ee/${data.id}/proposal/${approvedProposal?.id}`,
                  )
                }
              >
                반려사유 확인하기
              </ButtonV2>
            </div>
          )}
          {(data.status === 'PENDING' ||
            data.status === 'WAIT_MENTOR' ||
            data.status === 'WAITING_FOR_NEXT_PROPOSAL') && (
            <div className="flex items-center">
              <ButtonV2
                size={40}
                color="gray100"
                variant="solid"
                className="w-full"
                onClick={() => push(userType === 'teacher' ? `/teacher/ib/ee/${data.id}` : `/ib/student/ee/${data.id}`)}
              >
                제안서 목록보기
              </ButtonV2>
            </div>
          )}
          {data.status === 'REJECT_MENTOR' && (
            <div className="flex items-center">
              <ButtonV2
                size={40}
                color="gray100"
                variant="solid"
                className="w-full"
                onClick={() =>
                  push(
                    userType === 'teacher'
                      ? `/teacher/ib/ee/${data.id}/proposal/${approvedProposal?.id}`
                      : `/ib/student/ee/${data.id}/proposal/${approvedProposal?.id}`,
                  )
                }
              >
                반려사유 확인하기
              </ButtonV2>
            </div>
          )}
          {data.status === 'REJECT_COMPLETE' && (
            <div className="flex items-center">
              <ButtonV2
                size={40}
                color="gray100"
                variant="solid"
                className="w-full"
                onClick={() =>
                  push(
                    userType === 'teacher'
                      ? `/teacher/ib/ee/${data.id}/proposal/${approvedProposal?.id}`
                      : `/ib/student/ee/${data.id}/proposal/${approvedProposal?.id}`,
                  )
                }
              >
                반려사유 확인하기
              </ButtonV2>
            </div>
          )}
          {data.status === 'WAIT_PLAN_APPROVE' && (
            <div className="flex items-center">
              <ButtonV2
                size={40}
                color="gray100"
                variant="solid"
                className="w-full"
                onClick={() =>
                  push(
                    userType === 'teacher'
                      ? `/teacher/ib/ee/${data.id}/proposal/${approvedProposal?.id}`
                      : `/ib/student/ee/${data.id}/proposal/${approvedProposal?.id}`,
                  )
                }
              >
                제안서 상세보기
              </ButtonV2>
            </div>
          )}
        </footer>
      </div>
    </div>
  );
}
function TokProjectCard({ data }: ProjectCardProps) {
  const { t } = useLanguage();
  return (
    <div className="flex h-[308px] w-[308px] cursor-pointer flex-col rounded-xl border border-primary-gray-200 bg-white px-5 shadow">
      <nav className="flex w-full flex-row items-center justify-between pt-5">
        <BadgeV2 color="dark_green" size={24} type="solid_strong">
          TOK
        </BadgeV2>
        <BadgeV2 color="gray" size={24} type="line">
          {t(`IBStatus.${data.status}`)}
        </BadgeV2>
      </nav>
      <main>{data.title}</main>
    </div>
  );
}
