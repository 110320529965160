import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useGroupsFindAllKlass } from 'src/generated/endpoint';
import { LayeredTabs, Tab } from '../common/LayeredTabs';
import SelectBar from '../common/SelectBar';
import EssayView from './overview/EssayView';
import ProposalView from './overview/ProposalView';
import RPPFView from './overview/RPPFView';
import RRSView from './overview/RRSView';
import clsx from 'clsx';

// TODO : 이미 등록된 타입 파일 찾을 시 변경 필요
type IBProject = 'CAS' | 'EE' | 'TOK';
type EEProject = 'PROPOSAL' | 'ESSAY' | 'RPPF' | 'RRS' | '';

export default function TeacherIBOverview() {
  const [projectType, setProjectType] = useState<IBProject>('EE');
  const [EEType, setEEType] = useState<EEProject>('PROPOSAL');
  const [selectedOptions, setSelectedOptions] = useState({
    grade: 1,
    klass: 0,
  });

  const { data: klassGroups } = useGroupsFindAllKlass();
  const grades = _(klassGroups)
    .map('grade')
    .uniq()
    .map((grade) => ({ id: grade, value: grade, text: `${grade}학년` }))
    .orderBy('value')
    .value();

  const klasses = _(klassGroups)
    .filter((group) => group.grade === selectedOptions.grade)
    .map('klass')
    .uniq()
    .map((klass, index) => ({ id: index + 1, value: klass, text: `${klass}반` }))
    .concat([{ id: 0, value: 0, text: '반 전체' }])
    .orderBy('value')
    .value();

  const handleOptionChange = (optionType: 'grade' | 'klass', value: number) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [optionType]: value,
    }));
  };

  useEffect(() => {
    if (projectType === 'EE') {
      setEEType('PROPOSAL');
    } else {
      setEEType('');
    }
  }, [projectType]);

  return (
    <main className="w-full">
      <header className="flex flex-row gap-3 py-5">
        <div className="flex flex-row items-center gap-2">
          <SelectBar
            options={grades}
            value={selectedOptions.grade}
            onChange={(value: number) => handleOptionChange('grade', value)}
            placeholder="학년 선택"
            size={40}
            className="w-30"
          />
          <SelectBar
            options={klasses}
            value={selectedOptions.klass}
            onChange={(value: number) => handleOptionChange('klass', value)}
            placeholder="반 선택"
            size={40}
            className="w-30"
            priorityFontClass="text-primary-gray-900"
          />
        </div>
        <div>
          <LayeredTabs.TwoDepth
            onChange={(selectedType: IBProject) => setProjectType(selectedType)}
            value={projectType}
          >
            <Tab
              value="CAS"
              childrenWrapperClassName={clsx(
                projectType === 'CAS' ||
                  projectType === 'EE' ||
                  'relative after:absolute after:right-0 after:h-[14px] after:w-[1px] after:bg-primary-gray-200 after:content-[""] after:z-10',
              )}
            >
              <p className={clsx({ 'text-primary-gray-700': projectType === 'CAS' })}>CAS</p>
            </Tab>
            <Tab
              value="EE"
              childrenWrapperClassName={clsx(
                projectType === 'EE' ||
                  projectType === 'TOK' ||
                  'relative after:absolute after:right-0 after:h-[14px] after:w-[1px] after:bg-primary-gray-200 after:content-[""] after:z-10',
              )}
            >
              <p className={clsx({ 'text-primary-gray-700': projectType === 'EE' })}>EE</p>
            </Tab>
            <Tab value="TOK">
              <p className={clsx({ 'text-primary-gray-700': projectType === 'TOK' })}>TOK</p>
            </Tab>
          </LayeredTabs.TwoDepth>
        </div>
      </header>
      <section className="flex flex-col">
        <nav className="pb-10 pt-3">
          {projectType === 'EE' && (
            <LayeredTabs.ThirdDepth onChange={(selectedType: EEProject) => setEEType(selectedType)} value={EEType}>
              <Tab value="PROPOSAL">
                <p>제안서</p>
              </Tab>
              <Tab value="ESSAY">
                <p>에세이</p>
              </Tab>
              <Tab value="RPPF">
                <p>RPPF</p>
              </Tab>
              <Tab value="RRS">
                <p>RRS</p>
              </Tab>
            </LayeredTabs.ThirdDepth>
          )}
        </nav>
        <article>
          {EEType === 'PROPOSAL' && (
            <ProposalView grade={selectedOptions.grade} klass={selectedOptions.klass} ibType={'EE'} />
          )}
          {EEType === 'ESSAY' && (
            <EssayView grade={selectedOptions.grade} klass={selectedOptions.klass} ibType={'EE'} />
          )}
          {EEType === 'RPPF' && <RPPFView grade={selectedOptions.grade} klass={selectedOptions.klass} ibType={'EE'} />}
          {EEType === 'RRS' && <RRSView grade={selectedOptions.grade} klass={selectedOptions.klass} ibType={'EE'} />}
        </article>
      </section>
    </main>
  );
}
