import { useChecklistGetitems, useChecklistGetitemsByStudent } from 'src/generated/endpoint';

export const useCheckListGetByTeacher = () => {
  const { data, isLoading } = useChecklistGetitems({ location: 'ESSAY' });
  const CheckList = data?.items;
  return {
    CheckList,
    isLoading,
  };
};

export const useCheckListGetByStudent = (studentId: number) => {
  const { data, isLoading } = useChecklistGetitemsByStudent(studentId, { location: 'ESSAY' });
  const CheckList = data?.items;
  return {
    CheckList,
    isLoading,
  };
};
