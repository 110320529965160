import { PropsWithChildren, useState } from 'react';
import SVGIcon from '../icon/SVGIcon';
import { Typography } from './Typography';

// 아코디언 Props 인터페이스
interface AccordionProps {
  title: string;
  count?: number;
  isFirst?: boolean;
}

const Accordion = ({ title, count, isFirst = false, children }: PropsWithChildren<AccordionProps>) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`border-b border-b-primary-gray-200 ${isFirst ? 'pb-10 pt-5' : 'py-10'}`}>
      {/* 상단 타이틀 및 열림/닫힘 아이콘 */}
      <div className="flex cursor-pointer items-center justify-between gap-4" onClick={toggleAccordion}>
        <span className="flex flex-row items-center gap-1">
          <Typography variant="title3">{title}</Typography>
          <Typography variant="title3" className="text-primary-orange-800">
            {count}
          </Typography>
        </span>
        <span>
          {isOpen ? (
            <SVGIcon.Arrow color="gray700" rotate={90} size={16} weight="bold" />
          ) : (
            <SVGIcon.Arrow color="gray700" rotate={270} size={16} weight="bold" />
          )}
        </span>
      </div>

      {/* 하단의 내용 */}
      {isOpen && <div className="pt-6">{children}</div>}
    </div>
  );
};

export default Accordion;
