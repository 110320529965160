import { useRPPFUpdateIBEditStstusUnlock, useRPPFUpdateRPPFEditStatusRequestUnlock } from 'src/generated/endpoint';
import { RequestIBCommentDto } from 'src/generated/model';

interface UseIBRPPFUpdateOptions {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
  onClose?: () => void;
}

interface approveEditStatusProps {
  ibId: number;
  rppfId: number;
}

interface updateEditStatusProps {
  ibId: number;
  rppfId: number;
  data: RequestIBCommentDto;
}

export const useIBRPPFApproveStatus = ({ onSuccess, onError, onClose }: UseIBRPPFUpdateOptions) => {
  const { mutate, isLoading, isError, error } = useRPPFUpdateIBEditStstusUnlock({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('RPPF 수정 승인 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const approveEditStatus = ({ ibId, rppfId }: approveEditStatusProps) => {
    mutate({ ibId, rppfId });
  };

  return {
    approveEditStatus,
    isLoading,
    isError,
    error,
  };
};
export const useIBRPPFUpdateEditStatus = ({ onSuccess, onError, onClose }: UseIBRPPFUpdateOptions) => {
  const { mutate, isLoading, isError, error } = useRPPFUpdateRPPFEditStatusRequestUnlock({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('RPPF 수정 요청 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const updateEditStatus = ({ ibId, rppfId, data }: updateEditStatusProps) => {
    mutate({ ibId, rppfId, data });
  };

  return {
    updateEditStatus,
    isLoading,
    isError,
    error,
  };
};
