import { useState } from 'react';
import { RadioV2 } from 'src/components/common/RadioV2';
import { Typography } from 'src/components/common/Typography';
import { useIBProposalStatus, useIBProposalSubmissionStatus } from 'src/container/ib-overview';
import { IBGetSubmissionStatusCountParams, IBGetSubmissionStatusStatus } from 'src/generated/model';
import ProposalOverviewPanel from './ProposalOverviewPanel';
import { useHistory } from 'react-router-dom';

export default function ProposalView({ grade, klass, ibType }: IBGetSubmissionStatusCountParams) {
  const { push } = useHistory();

  const [status, setStatus] = useState<IBGetSubmissionStatusStatus>('SUBMIT_STATUS');

  const { data } = useIBProposalStatus({
    grade,
    klass: klass === 0 ? undefined : klass,
    ibType,
  });

  const { students = [] } = useIBProposalSubmissionStatus({
    grade,
    klass: klass === 0 ? undefined : klass,
    ibType,
    status,
  });

  return (
    <div>
      <Typography variant="title1">제안서</Typography>
      <RadioV2.Group
        selectedValue={status}
        onChange={(value: IBGetSubmissionStatusStatus) => setStatus(value)}
        className="flex flex-row gap-4 py-6"
      >
        <RadioV2.Label
          title="미제출"
          currentNum={data?.notSubmitted || 0}
          TotalNum={data?.total || 0}
          value="SUBMIT_STATUS"
        />
        <RadioV2.Label
          title="승인 대기중"
          currentNum={data?.pendingApproval || 0}
          TotalNum={data?.total || 0}
          value="PENDING_APPROVAL"
        />
        <RadioV2.Label
          title="반려 - 수정필요"
          currentNum={data?.rejected || 0}
          TotalNum={data?.total || 0}
          value="REJECT"
        />
        <RadioV2.Label
          title="반려 - 후순위 제안서 필요"
          currentNum={data?.waitForNextProposal || 0}
          TotalNum={data?.total || 0}
          value="WAITING_FOR_NEXT_PROPOSAL"
        />
      </RadioV2.Group>

      {status === 'SUBMIT_STATUS' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">제안서 미제출</Typography>
          <ProposalOverviewPanel
            title="미제출"
            buttonText="미제출자 알림 보내기"
            buttonHandler={() => alert('미제출자 알림 보내기')}
            data={students}
            type="not"
          />
          <ProposalOverviewPanel
            goDetailPage={(studentIbId) => push(`/teacher/ib/ee/${studentIbId}`)}
            title="제출"
            buttonText="제안서 전체 다운로드"
            buttonHandler={() => alert('제안서 전체 다운로드')}
            data={students}
            type="submit"
          />
        </div>
      )}
      {status === 'PENDING_APPROVAL' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">제안서 승인 대기중</Typography>
          <ProposalOverviewPanel
            title="승인 대기중"
            buttonText="대기자 알림 보내기"
            goDetailPage={(studentIbId) => push(`/teacher/ib/ee/${studentIbId}`)}
            buttonHandler={() => alert('대기자 알림 보내기')}
            data={students}
          />
        </div>
      )}
      {status === 'REJECT' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">반려 - 수정필요</Typography>
          <ProposalOverviewPanel
            title="수정필요"
            buttonText="알림 보내기"
            goDetailPage={(studentIbId) => push(`/teacher/ib/ee/${studentIbId}`)}
            buttonHandler={() => alert('알림 보내기')}
            data={students}
          />
        </div>
      )}
      {status === 'WAITING_FOR_NEXT_PROPOSAL' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">반려 - 후순위 제안서 필요</Typography>
          <ProposalOverviewPanel
            title="후순위 제안서 필요"
            buttonText="알림 보내기"
            goDetailPage={(studentIbId) => push(`/teacher/ib/ee/${studentIbId}`)}
            buttonHandler={() => alert('알림 보내기')}
            data={students}
          />
        </div>
      )}
    </div>
  );
}
