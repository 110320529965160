import { useIBGetIB, useIBGetIBByStudent } from 'src/generated/endpoint';
import { IBGetIBIbType, ResponseIBDto } from 'src/generated/model';
import { makeStudNum5 } from 'src/util/status';

export const useIBGetByStudent = (id: number) => {
  const { data, isLoading, refetch } = useIBGetIBByStudent(id);

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useIBGetById = (id: number, ibType: IBGetIBIbType, options?: { enabled?: boolean }) => {
  const { data, isLoading, refetch } = useIBGetIB(id, { ibType }, { query: { enabled: options?.enabled } });

  // 학번 조합
  const getKlassNum = (data: ResponseIBDto | undefined): string | undefined => {
    if (!data?.leader?.studentGroup?.group) return undefined;
    const { grade, klass: classNum } = data.leader.studentGroup.group;
    const studentNum = data.leader.studentGroup.studentNumber;
    return makeStudNum5({ grade, classNum, studentNum });
  };

  const klassNum = getKlassNum(data);

  return {
    data,
    isLoading,
    refetch,
    klassNum,
  };
};
