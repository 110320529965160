import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import NODATA from 'src/assets/images/no-data.png';
import { SuperModal } from 'src/components';
import { Blank, Textarea } from 'src/components/common';
import AlertV2 from 'src/components/common/AlertV2';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { Typography } from 'src/components/common/Typography';
import { Feedback } from 'src/components/ib/Feedback';
import IBLayout from 'src/components/ib/IBLayout';
import { InputField } from 'src/components/ib/InputField';
import { ProposalViewField } from 'src/components/ib/ProposalViewField';
import SVGIcon from 'src/components/icon/SVGIcon';
import { PopupModal } from 'src/components/PopupModal';
import { EE_SUBJECTS } from 'src/constants/ib';
import { useIBGetById } from 'src/container/ib-project-get-student';
import {
  useIBProposalApproveStatus,
  useIBProposalStatusReject,
  useIBProposalUpdate,
} from 'src/container/ib-proposal-update';
import { useIBRejectPlanByProposal, useIBUpdateIBProposalStatusInProgress } from 'src/generated/endpoint';
import { RequestIBProposalUpdateDto } from 'src/generated/model';
import { meState } from 'src/store';

export const EEProposalDetailPage = () => {
  const { id, proposalId } = useParams<{ id: string; proposalId: string }>();
  const { push } = useHistory();
  const me = useRecoilValue(meState);
  const { data, klassNum } = useIBGetById(Number(id), 'EE');
  const proposal = data?.proposals?.filter((el) => el.id === Number(proposalId))?.[0];
  const [denyConfirmModalOpen, setDenyConfirmModalOpen] = useState(false); // 제안서 반려 완료 Alert
  const [rejectModalOpen, setRejectModalOpen] = useState(false); // 제안서 보완 요청 Modal
  const [rejectConfirmModalOpen, setRejectConfirmModalOpen] = useState(false); // 제안서 보완 요청 완료 Alert
  const [rejectReason, setRejectReason] = useState(''); // 제안서 보완 요청 피드백
  const [approveModalOpen, setApproveModalOpen] = useState(false); // 제안서 승인 Alert
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editConfirmModalOpen, setEditConfirmModalOpen] = useState<boolean>(false); // 제안서 수정 완료 Alert
  const [approveAlertOpen, setApproveAlertOpen] = useState<boolean>(false); // 제안서 수정 승인 Alert

  // 제안서 승인 api 호출
  const { mutate: approve, isLoading: approveLoading } = useIBUpdateIBProposalStatusInProgress({
    mutation: {
      onSuccess: () => {
        setApproveModalOpen(true);
      },
    },
  });

  // 담당교사 지정된 이후 제안서 보완요청 api 호출
  const { mutate: rejectPlan, isLoading: rejectPlanLoading } = useIBRejectPlanByProposal({
    mutation: {
      onSuccess: () => {
        setRejectConfirmModalOpen(true); // 보완 요청 완료 Alert
      },
    },
  });

  // 제안서 반려 api 호출
  const { rejectProposal } = useIBProposalStatusReject({
    onSuccess: () => {
      setDenyConfirmModalOpen(true); // 반려 요청 완료 Alert
    },
    onError: (error) => {
      console.error('IB 제안서 반려 중 오류 발생:', error);
    },
  });

  // 제안서 수정 api 호출
  const { updateIBProposal } = useIBProposalUpdate({
    onSuccess: () => {
      setEditConfirmModalOpen(true);
    },
    onError: (error) => {
      console.error('IB 프로젝트 생성 중 오류 발생:', error);
    },
  });

  // 제안서 수정 승인 api 호출
  const { approveIBProposalStatus } = useIBProposalApproveStatus({
    onSuccess: () => {
      setApproveAlertOpen(true);
    },
    onError: (error) => {
      console.error('IB 프로젝트 생성 중 오류 발생:', error);
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    register,
    reset,
    formState: { errors },
  } = useForm<RequestIBProposalUpdateDto>({
    defaultValues: proposal || {},
  });

  // 수정 버튼 클릭
  const onEdit = () => {
    setEditMode(true);
    reset(proposal);
  };

  // 수정 시 저장하기 버튼 클릭
  const onSubmit = (data: RequestIBProposalUpdateDto) => {
    if (isLoading) return;

    updateIBProposal({ id: Number(id), proposalId: Number(proposalId), data });
    setEditMode(false);
  };

  if (!data || !me) {
    return <Blank />;
  }

  if (!proposal) {
    return (
      <div className="col-span-6 flex h-screen w-full items-center justify-center">해당 제안서를 찾을 수 없습니다.</div>
    );
  }

  const isLoading = approveLoading || rejectPlanLoading;

  return (
    <>
      {isLoading && <Blank />}
      <div className="col-span-6 h-screen w-full">
        <IBLayout
          className="bg-gray-50"
          topBgColor="bg-white"
          topContent={
            <div>
              <div className="w-full pb-8 pt-16">
                <div className="flex flex-col items-start gap-3">
                  <div className="flex w-full flex-row items-center justify-between">
                    <div className="flex flex-row gap-1">
                      <BadgeV2 color="dark_green" size={24} type="solid_strong">
                        EE
                      </BadgeV2>
                      <BadgeV2 color="gray" size={24} type="solid_regular">
                        제안서
                      </BadgeV2>
                      <BadgeV2 color="gray" size={24} type="line">
                        {proposal.rank}순위
                      </BadgeV2>
                    </div>
                    <Breadcrumb
                      data={{
                        진행상태: '/teacher/project',
                        EE: `/teacher/ib/ee/${id}`,
                        '제안서 상세': `/teacher/ib/ee/${id}/proposal/${proposalId}`,
                      }}
                    />
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <Typography variant="heading" className="max-w-[848px]">
                      {proposal.researchTopic}
                    </Typography>
                    <div className="flex items-center space-x-2">
                      <div className="rounded-lg border border-orange-100 bg-orange-50 px-4 py-2 text-16 font-semibold text-orange-800">
                        {klassNum} · {data.leader?.name}
                      </div>
                      {data?.activityFrequency && (
                        <div className="rounded-lg bg-orange-50 px-4 py-3 text-13 text-gray-300">
                          <span className="text-orange-800">알림</span>{' '}
                          <span className="ml-2 text-gray-700">
                            {/* TODO: 백엔드에서 내려주는 데이터 확인 필요 */}
                            {data?.activityFrequency}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          hasContour={false}
          bottomContent={
            <div className="flex flex-grow flex-col">
              <div className="flex h-full flex-row gap-4 py-6">
                <div className="flex w-[848px] flex-col rounded-xl bg-white p-6">
                  <div className="flex flex-col gap-12">
                    {editMode ? (
                      <>
                        <form>
                          <div className="scroll-box flex h-full flex-col gap-6 overflow-auto">
                            <InputField
                              label="지식영역"
                              name="subject"
                              control={control}
                              placeholder="지식영역을 선택해주세요"
                              type="select"
                              options={EE_SUBJECTS}
                              size={40}
                            />
                            <InputField
                              label="모델 논문"
                              control={control}
                              placeholder="모델 논문을 입력해주세요"
                              {...register('modelPaper')}
                            />
                            <InputField
                              label="모델 논문 요약"
                              control={control}
                              placeholder="모델 논문 요약을 입력해주세요"
                              className="h-40"
                              type="textarea"
                              {...register('modelPaperSummary')}
                            />
                            <InputField
                              label="연구주제"
                              control={control}
                              placeholder="연구주제를 입력해주세요"
                              {...register('researchTopic')}
                            />
                            <InputField
                              label="연구 질문"
                              control={control}
                              placeholder="연구 질문을 입력해주세요"
                              className="h-40"
                              type="textarea"
                              {...register('researchQuestion')}
                            />
                            <InputField
                              label="연구의 필요성"
                              control={control}
                              placeholder="연구의 필요성을 입력해주세요"
                              className="h-40"
                              type="textarea"
                              {...register('researchNeed')}
                            />
                            <InputField
                              label="연구 방법"
                              control={control}
                              placeholder="연구 방법을 입력해주세요"
                              className="h-40"
                              type="textarea"
                              {...register('researchMethod')}
                            />
                          </div>
                        </form>
                      </>
                    ) : (
                      <>
                        <ProposalViewField label="지식영역">
                          <div className="flex gap-2">
                            <SVGIcon.Note size={20} color="gray700" weight="bold" className="cursor-default" />
                            <Typography variant="body2">{proposal.subject}</Typography>
                          </div>
                        </ProposalViewField>
                        <ProposalViewField label="모델 논문" value={proposal.modelPaper} />
                        <ProposalViewField label="모델 논문 요약" value={proposal.modelPaperSummary} />
                        <ProposalViewField label="연구 주제" value={proposal.researchTopic} />
                        <ProposalViewField label="연구 질문" value={proposal.researchQuestion} />
                        <ProposalViewField label="연구의 필요성" value={proposal.researchNeed} />
                        <ProposalViewField label="연구 방법" value={proposal.researchMethod} />
                      </>
                    )}
                  </div>
                  <div className="mt-10 flex w-full items-center justify-between">
                    {editMode ? (
                      <>
                        <ButtonV2 size={40} variant="solid" color="gray100" onClick={() => setEditMode(!editMode)}>
                          취소
                        </ButtonV2>
                        <ButtonV2
                          size={40}
                          variant="solid"
                          color="orange100"
                          onClick={() => void handleSubmit(onSubmit)()}
                        >
                          저장하기
                        </ButtonV2>
                      </>
                    ) : (
                      <>
                        <ButtonV2 size={40} variant="outline" color="gray400" onClick={onEdit}>
                          수정
                        </ButtonV2>
                        <ButtonV2
                          size={40}
                          variant="solid"
                          color="gray100"
                          onClick={() => push(`/teacher/ib/ee/${id}`)}
                        >
                          목록 돌아가기
                        </ButtonV2>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex h-[720px] w-[416px] flex-col gap-6 rounded-xl bg-white p-6">
                  <Typography variant="title1">진행기록</Typography>
                  {proposal?.status === 'ACCEPT' ||
                  (proposal?.status === 'SUBMIT' && data?.status === 'REJECT_PLAN') ? (
                    <div className="h-full w-full">
                      <Feedback referenceId={Number(id)} referenceTable="IB" user={me} />
                    </div>
                  ) : (
                    <div className="flex flex-col items-center gap-6 py-20">
                      <div className="h-12 w-12 px-[2.50px]">
                        <img src={NODATA} className="h-12 w-[43px] object-cover" />
                      </div>
                      <Typography variant="body2">진행기록이 없습니다.</Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          }
          floatingButton={
            <div className="fixed bottom-0 w-inherit bg-white py-5">
              {data.status === 'WAIT_COMPLETE' || data.status === 'COMPLETE' ? null : data.editStatus ===
                  'REQUEST_UNLOCK' && data.status === 'IN_PROGRESS' ? (
                <div className="mx-auto flex w-[1280px] items-center justify-center">
                  <ButtonV2
                    className="w-[168px]"
                    size={48}
                    variant="solid"
                    color="orange800"
                    onClick={() => {
                      approveIBProposalStatus(Number(id));
                    }}
                  >
                    제안서 수정 승인
                  </ButtonV2>
                </div>
              ) : data.status === 'IN_PROGRESS' ? (
                <div className="mx-auto flex w-[1280px] items-center justify-center">
                  <ButtonV2 variant="solid" color="orange800" size={48} className="w-[416px]" disabled={true}>
                    제안서 승인
                  </ButtonV2>
                </div>
              ) : data.status === 'REJECT_PLAN' ? (
                <div className="mx-auto flex w-[1280px] items-center justify-center">
                  <ButtonV2 variant="solid" color="gray100" size={48} className="w-[416px]" disabled={true}>
                    제안서 보완요청
                  </ButtonV2>
                </div>
              ) : (
                <div className="mx-auto flex w-[1280px] items-center justify-end">
                  <div className="flex items-center gap-4">
                    <ButtonV2
                      className="w-[168px]"
                      size={48}
                      variant="solid"
                      color="gray700"
                      onClick={() => setRejectModalOpen(true)}
                    >
                      제안서 보완요청
                    </ButtonV2>
                    <ButtonV2
                      className="w-[168px]"
                      size={48}
                      variant="solid"
                      color="orange800"
                      onClick={() => {
                        approve({ id: Number(id), proposalId: Number(proposalId) });
                      }}
                    >
                      제안서 승인
                    </ButtonV2>
                  </div>
                </div>
              )}
            </div>
          }
        />
      </div>

      {/* 제안서 보완 요청 Modal */}
      <PopupModal
        modalOpen={rejectModalOpen}
        setModalClose={() => {
          setRejectModalOpen(false);
          setRejectReason('');
        }}
        title="제안서 보완 요청"
        bottomBorder={false}
        footerButtons={
          <ButtonV2
            size={48}
            variant="solid"
            color="orange800"
            className="w-[88px]"
            disabled={!Boolean(rejectReason.length).valueOf()}
            onClick={() => rejectPlan({ id: Number(id), proposalId: proposal.id, data: { content: rejectReason } })}
          >
            전달하기
          </ButtonV2>
        }
      >
        <div className="flex flex-col gap-6">
          <Typography variant="body1">학생에게 제안서에 대한 피드백을 남겨주세요.</Typography>
          <Textarea
            className="resize-none rounded-lg p-4"
            placeholder="내용을 입력해주세요."
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          />
        </div>
      </PopupModal>

      {/* 제안서 반려 완료 Alert */}
      <SuperModal
        modalOpen={denyConfirmModalOpen}
        setModalClose={() => setDenyConfirmModalOpen(false)}
        hasClose={false}
        width="w-[416px]"
      >
        <div className="w-full">
          <Typography variant="title2" className="p-8 text-center">
            제안서를
            <br />
            반려하였습니다
          </Typography>
          <div className="p-5 pt-0">
            <ButtonV2
              variant="solid"
              color="orange800"
              size={48}
              className="w-full"
              onClick={() => {
                setDenyConfirmModalOpen(false);
              }}
            >
              확인
            </ButtonV2>
          </div>
        </div>
      </SuperModal>

      {/* 제안서 수정 완료 Alert*/}
      <SuperModal
        modalOpen={editConfirmModalOpen}
        setModalClose={() => setEditConfirmModalOpen(false)}
        hasClose={false}
        width="w-[416px]"
      >
        <div className="w-full">
          <Typography variant="title2" className="p-8 text-center">
            제안서가
            <br />
            수정되었습니다
          </Typography>
          <div className="p-5 pt-0">
            <ButtonV2
              variant="solid"
              color="orange800"
              size={48}
              onClick={() => setEditConfirmModalOpen(false)}
              className="w-full"
            >
              확인
            </ButtonV2>
          </div>
        </div>
      </SuperModal>

      {/* 제안서 보완 요청 완료 Alert */}
      <SuperModal
        modalOpen={rejectConfirmModalOpen}
        setModalClose={() => setRejectConfirmModalOpen(false)}
        hasClose={false}
        width="w-[416px]"
      >
        <div className="w-full">
          <Typography variant="title2" className="p-8 text-center">
            제안서 보완을
            <br />
            요청하였습니다
          </Typography>
          <div className="p-5 pt-0">
            <ButtonV2
              variant="solid"
              color="orange800"
              size={48}
              onClick={() => {
                setRejectConfirmModalOpen(false);
                setRejectModalOpen(false);
              }}
              className="w-full"
            >
              확인
            </ButtonV2>
          </div>
        </div>
      </SuperModal>

      {/* 제안서 승인 완료 Alert */}
      <SuperModal
        modalOpen={approveModalOpen}
        setModalClose={() => setApproveModalOpen(false)}
        hasClose={false}
        width="w-[416px]"
      >
        <div className="w-full">
          <div className="flex flex-col gap-2 p-8 text-center">
            <Typography variant="title2">제안서가 승인되었습니다</Typography>
            <Typography variant="body2">
              제안서가 승인되어 학생의 EE 프로젝트가 <br />
              진행중으로 처리되었습니다.
            </Typography>
          </div>
          <div className="p-5 pt-0">
            <ButtonV2
              variant="solid"
              color="orange800"
              size={48}
              className="w-full"
              onClick={() => setApproveModalOpen(false)}
            >
              확인
            </ButtonV2>
          </div>
        </div>
      </SuperModal>
      {approveAlertOpen && (
        <AlertV2
          message={`제안서 수정권한 요청이\n승인되었습니다`}
          confirmText="확인"
          onConfirm={() => setApproveAlertOpen(!approveAlertOpen)}
        />
      )}
    </>
  );
};
