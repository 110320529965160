import { useRRSFindAll } from 'src/generated/endpoint';

export const useRRSGetByIBIdFindAll = (ibId: number) => {
  const { data: RRS, isLoading, refetch } = useRRSFindAll(ibId);
  const data = RRS?.items;
  return {
    data,
    isLoading,
    refetch,
  };
};
