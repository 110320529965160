import clsx from 'clsx';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import AlertV2 from 'src/components/common/AlertV2';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { TextareaV2 } from 'src/components/common/TextareaV2';
import { Typography } from 'src/components/common/Typography';
import { Feedback } from 'src/components/ib/Feedback';
import IBLayout from 'src/components/ib/IBLayout';
import RppfIbSubmitInformPopup from 'src/components/ib/RppfIbSubmitInformPopup';
import { PopupModal } from 'src/components/PopupModal';
import { useIBApproveComplete } from 'src/container/ib-project';
import { useIBGetById } from 'src/container/ib-project-get-student';
import { useIBRPPFCreate } from 'src/container/ib-rppf-create';
import { useRPPFGetById } from 'src/container/ib-rppf-findId';
import { useIBRPPFApproveStatus } from 'src/container/ib-rppf-update-edit-status';
import { useRPPFUpdateRPPFStatusReject } from 'src/generated/endpoint';
import { RequestCreateRPPFDto } from 'src/generated/model';
import { meState } from 'src/store';
import { LocationState } from 'src/type/ib';

export const EERppfDetailPage = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { id, rppfId } = useParams<{ id: string; rppfId: string }>();

  const { title: locationTitle, student: locationStudentData } = location.state || {};

  const { data: ibData, klassNum: ibKlassNum } = useIBGetById(Number(id), 'EE', {
    enabled: !(locationTitle && locationStudentData),
  });
  const data = location.state?.data || ibData;

  const acceptProposalTitle = data?.proposals?.find((proposal) => proposal.status === 'ACCEPT')?.researchTopic;

  const title = acceptProposalTitle;
  const klassNum = location.state?.student.klassNum || ibKlassNum;

  const [rejectModalOpen, setRejectModalOpen] = useState(false); // 제안서 보완 요청 Modal
  const [rejectReason, setRejectReason] = useState(''); // 제안서 보완 요청 피드백
  const [ibModalType, setIbModalType] = useState<'CREATE' | 'VIEW' | null>(null); // IB Modal 타입 관리

  const me = useRecoilValue(meState);
  const { data: rppf } = useRPPFGetById(Number(id), Number(rppfId));

  const { createIBRPPF } = useIBRPPFCreate({
    onSuccess: () => {
      setAlertMessage(`RPPF가\n수정되었습니다`);
    },
    onError: (error) => {
      console.error('RPPF 수정 중 오류 발생:', error);
    },
  });

  const { mutate: rejectPlan, isLoading: rejectPlanLoading } = useRPPFUpdateRPPFStatusReject({
    mutation: {
      onSuccess: () => {
        setAlertMessage(`RPPF 보완을\n요청하였습니다`);
        setRejectModalOpen(!rejectModalOpen);
      },
    },
  });

  // RPPF 수정 승인 api 호출
  const { approveEditStatus } = useIBRPPFApproveStatus({
    onSuccess: () => {
      setAlertMessage(`RPPF 수정권한 요청이\n승인되었습니다`);
    },
    onError: (error) => {
      console.error('RPPF 수정권한 요청 승인 중 오류 발생:', error);
    },
  });

  const { approveIBProjectComplete } = useIBApproveComplete({
    onSuccess: () => {
      setAlertMessage(`완료를\n승인하였습니다`);
    },
    onError: (error) => {
      console.error('완료 승인 중 오류 발생:', error);
    },
  });

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [wordCounts, setWordCounts] = useState<number[]>([]);
  const handleIbModalOpen = (type: 'CREATE' | 'VIEW') => {
    setIbModalType(type); // 모달 타입 설정
  };

  const handleIbModalClose = () => {
    setIbModalType(null); // 모달 타입 초기화
  };

  useEffect(() => {
    if (rppf?.contents) {
      const initialWordCounts = rppf.contents.map((content) => Number(content.wordCount) || 0);
      setWordCounts(initialWordCounts);
    }
  }, [rppf]);

  const handleWordCountChange = (index: number, count: number) => {
    setWordCounts((prev) => {
      const updatedCounts = [...prev];
      updatedCounts[index] = count;
      return updatedCounts;
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestCreateRPPFDto>({
    defaultValues: {
      contents:
        rppf?.contents.map((data) => ({
          id: data.id,
          sequence: data.sequence,
          text: data.text || '',
          wordCount: Number(data.wordCount) || 0,
        })) || [],
    },
  });

  const onSubmit = (data: RequestCreateRPPFDto) => {
    const filteredContents = data.contents
      .map((content, index) => ({
        ...content,
        id: content.id,
        sequence: index + 1,
        wordCount: wordCounts[index],
      }))
      .filter((content) => content.text && content.text.trim() !== '');

    const updatedData = {
      ...data,
      contents: filteredContents,
    };

    if (id !== undefined) {
      createIBRPPF({ ibId: Number(id), data: updatedData });
    }
    setEditMode(!editMode);
  };

  if (me === undefined) {
    return <div>접속 정보를 불러올 수 없습니다.</div>;
  }

  return (
    <div className="col-span-6">
      <IBLayout
        topContent={
          <div>
            <div className="w-full pb-6 pt-16">
              <div className="flex flex-col items-start gap-3">
                <div className="flex w-full flex-row items-center justify-between">
                  <div className="flex flex-row gap-1">
                    <BadgeV2 color="dark_green" size={24} type="solid_strong" className="self-start">
                      EE
                    </BadgeV2>
                    <BadgeV2 color="gray" size={24} type="solid_regular" className="self-start">
                      RPPF
                    </BadgeV2>
                  </div>
                  <Breadcrumb
                    data={{
                      진행상태: '/teacher/project',
                      EE: `/teacher/ib/ee/${id}`,
                      'RPPF 상세': `/teacher/ib/ee/${id}/rppf/${rppfId}`,
                    }}
                  />
                </div>
                <div className="flex w-full justify-between">
                  <Typography variant="heading">{title}</Typography>
                  <div className="rounded-lg border border-orange-100 bg-orange-50 px-4 py-2 text-16 font-semibold text-orange-800">
                    {klassNum} · {data?.leader?.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        bottomBgColor="bg-primary-gray-50"
        bottomContent={
          <div className="flex flex-grow flex-col">
            <div className="flex h-full flex-row gap-4 py-6">
              <div className="flex w-[848px] flex-col justify-between rounded-xl bg-white p-6">
                {editMode ? (
                  <>
                    <form>
                      <div className="scroll-box flex h-full flex-col gap-6 overflow-auto pb-8 pt-4">
                        <div className="flex flex-row items-center justify-between">
                          <Typography variant="title1">공식 RPPF</Typography>
                          <div className="flex flex-row items-center text-12">
                            <p className="text-primary-gray-500">총 단어 수</p>&nbsp;
                            <p className="font-medium text-primary-orange-800">
                              {wordCounts.reduce((sum, count) => sum + count, 0)}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {rppf?.contents.map((content, index) => (
                            <div
                              className={clsx('flex flex-col gap-4', {
                                'pt-6': index === 0,
                                'py-10': index === 1,
                                'pb-10': index === 2,
                              })}
                              key={content.id}
                            >
                              <div className="flex items-center justify-between">
                                <Typography variant="title2">RPPF {content.sequence}차</Typography>
                                <Typography variant="caption" className="text-primary-gray-500">
                                  최초 저장일 : {format(new Date(content.createdAt), 'yyyy.MM.dd')}
                                </Typography>
                              </div>
                              <input
                                type="hidden"
                                value={Number(content.id)} // Ensure id is an integer
                                {...register(`contents.${index}.id`, { valueAsNumber: true })}
                              />
                              <TextareaV2
                                showWordCount={true}
                                onWordCountChange={(count) => handleWordCountChange(index, count)}
                                value={content.text}
                                placeholder="내용을 입력해주세요."
                                className="h-[308px]"
                                {...register(`contents.${index}.text` as const)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </form>
                  </>
                ) : (
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center justify-between">
                      <Typography variant="title1">공식 RPPF</Typography>
                      <div className="flex flex-row items-center text-12">
                        <p className="text-primary-gray-500">총 단어 수</p>&nbsp;
                        <p className="font-medium text-primary-orange-800">
                          {wordCounts.reduce((sum, count) => sum + count, 0)}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      {rppf?.contents.map((content, index) => (
                        <div
                          className={clsx('flex flex-col gap-4', {
                            'pt-6': index === 0,
                            'py-10': index === 1,
                            'pb-10': index === 2,
                          })}
                          key={content.id}
                        >
                          <div className="flex items-center justify-between">
                            <Typography variant="title2">RPPF {content.sequence}차</Typography>
                            <Typography variant="caption" className="text-primary-gray-500">
                              최초 저장일 : {format(new Date(content.createdAt), 'yyyy.MM.dd')}
                            </Typography>
                          </div>
                          <div className="flex flex-col gap-4 rounded-lg border border-primary-gray-200 p-4">
                            <Typography variant="body2">{content.text}</Typography>
                            <div className="flex flex-row items-center text-12">
                              <p className="text-primary-gray-500">단어 수</p>&nbsp;
                              <p className="font-medium text-primary-orange-800">{content.wordCount}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <footer className={`flex flex-row items-center justify-between`}>
                  {editMode ? (
                    <>
                      <ButtonV2 size={40} variant="solid" color="gray100" onClick={() => setEditMode(!editMode)}>
                        취소
                      </ButtonV2>
                      <ButtonV2 size={40} variant="solid" color="orange100" onClick={handleSubmit(onSubmit)}>
                        저장하기
                      </ButtonV2>
                    </>
                  ) : (
                    <>
                      <ButtonV2 size={40} variant="outline" color="gray400" onClick={() => setEditMode(!editMode)}>
                        수정
                      </ButtonV2>
                      <ButtonV2
                        size={40}
                        variant="solid"
                        color="gray100"
                        onClick={() => history.push(`/teacher/ib/ee/${id}`, { type: 'RPPF' })}
                      >
                        목록 돌아가기
                      </ButtonV2>
                    </>
                  )}
                </footer>
              </div>
              <div className="flex h-[720px] w-[416px] flex-col gap-6 rounded-xl bg-white p-6">
                <Typography variant="title1">진행기록</Typography>
                <div className="h-full w-full">
                  <Feedback referenceId={Number(rppfId)} referenceTable="RPPF" user={me} />
                </div>
              </div>
            </div>
          </div>
        }
        floatingButton={
          <div className="fixed bottom-0 w-inherit bg-white py-5">
            {rppf?.editStatus === 'REQUEST_UNLOCK' && rppf?.status === 'IN_PROGRESS' ? (
              <div className="mx-auto flex w-[1280px] items-center justify-center">
                <ButtonV2
                  className="w-[416px]"
                  size={48}
                  variant="solid"
                  color="orange800"
                  onClick={() => {
                    approveEditStatus({ ibId: Number(id), rppfId: Number(rppfId) });
                  }}
                >
                  RPPF 수정 승인
                </ButtonV2>
              </div>
            ) : (
              <>
                {data?.status === 'COMPLETE' ? (
                  <div className="mx-auto flex w-[1280px] items-center justify-center">
                    <ButtonV2
                      className="w-[416px]"
                      size={48}
                      variant="solid"
                      color="gray700"
                      onClick={() => handleIbModalOpen('VIEW')}
                    >
                      IB제출정보 확인
                    </ButtonV2>
                  </div>
                ) : rppf?.status === 'WAIT_COMPLETE' || rppf?.status === 'COMPLETE' ? (
                  <>
                    {rppf?.academicIntegrityConsent ? (
                      <div className="mx-auto flex w-[1280px] items-center justify-end">
                        <div className="flex items-center gap-4">
                          <ButtonV2
                            className="w-[200px]"
                            size={48}
                            variant="solid"
                            color="gray700"
                            onClick={() => handleIbModalOpen('CREATE')}
                          >
                            IB제출정보 확인 및 수정
                          </ButtonV2>
                          <ButtonV2
                            className="w-[200px]"
                            size={48}
                            variant="solid"
                            color="orange800"
                            onClick={() => approveIBProjectComplete(Number(id))}
                          >
                            완료 승인
                          </ButtonV2>
                        </div>
                      </div>
                    ) : (
                      <div className="mx-auto flex w-[1280px] items-center justify-end">
                        <div className="flex items-center gap-4">
                          <ButtonV2
                            className="w-[200px]"
                            size={48}
                            variant="solid"
                            color="gray700"
                            onClick={() => setRejectModalOpen(true)}
                          >
                            RPPF 보완요청
                          </ButtonV2>
                          <ButtonV2
                            className="w-[200px]"
                            size={48}
                            variant="solid"
                            color="orange800"
                            onClick={() => handleIbModalOpen('CREATE')}
                          >
                            IB제출정보 기입
                          </ButtonV2>
                        </div>
                      </div>
                    )}
                  </>
                ) : rppf?.status === 'REJECT' ? (
                  <div className="mx-auto flex w-[1280px] items-center justify-center">
                    <ButtonV2 variant="solid" color="gray700" size={48} className="w-[416px]" disabled={true}>
                      RPPF 보완요청
                    </ButtonV2>
                  </div>
                ) : null}
              </>
            )}
          </div>
        }
      />
      {/* 제안서 보완 요청 Modal */}
      <PopupModal
        modalOpen={rejectModalOpen}
        setModalClose={() => {
          setRejectModalOpen(false);
          setRejectReason('');
        }}
        title="RPPF 보완 요청"
        bottomBorder={false}
        footerButtons={
          <ButtonV2
            size={48}
            variant="solid"
            color="orange800"
            className="w-[88px]"
            disabled={!Boolean(rejectReason.length).valueOf()}
            onClick={() => rejectPlan({ ibId: Number(id), rppfId: Number(rppfId), data: { content: rejectReason } })}
          >
            전달하기
          </ButtonV2>
        }
      >
        <div className="flex flex-col gap-6">
          <Typography variant="body1">학생에게 RPPF에 대한 피드백을 남겨주세요.</Typography>
          <TextareaV2
            placeholder="내용을 입력하세요."
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          />
        </div>
      </PopupModal>
      {ibModalType && rppf && (
        <RppfIbSubmitInformPopup
          IBData={data}
          rppfId={Number(rppfId)}
          ibId={Number(id)}
          rppfData={rppf}
          modalOpen={Boolean(ibModalType)}
          setModalClose={handleIbModalClose}
          type={ibModalType}
        />
      )}
      {alertMessage && <AlertV2 message={alertMessage} confirmText="확인" onConfirm={() => setAlertMessage(null)} />}
    </div>
  );
};
