import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import NODATA from 'src/assets/images/no-data.png';
import { SuperModal } from 'src/components';
import { Blank } from 'src/components/common';
import AlertV2 from 'src/components/common/AlertV2';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { LayeredTabs, Tab } from 'src/components/common/LayeredTabs';
import { Typography } from 'src/components/common/Typography';
import { CheckList } from 'src/components/ib/CheckList';
import { EvaluationList } from 'src/components/ib/EvaluationList';
import { Feedback } from 'src/components/ib/Feedback';
import { IbEeEssay } from 'src/components/ib/IbEeEssay';
import IBLayout from 'src/components/ib/IBLayout';
import SVGIcon from 'src/components/icon/SVGIcon';
import { useEssayGetByIBId } from 'src/container/ib-essay-find';
import { useIBEssayApproveStatus } from 'src/container/ib-essay-update-edit-status';
import { useEvaluationGetByStudent } from 'src/container/ib-evaluation';
import { useIBGetById } from 'src/container/ib-project-get-student';
import { ResponseIBDtoEditStatus } from 'src/generated/model';
import { meState } from 'src/store';
import { LocationState } from 'src/type/ib';
import { getUrlFromFile, handleDownload } from 'src/util/file';

type tabType = 'feedback' | 'evaluation' | 'checklist';

interface EssayLocationState extends LocationState {
  type?: tabType;
}

export const EEEssayPage = () => {
  const location = useLocation<EssayLocationState>();
  const _type = location.state?.type;
  const { title: locationTitle, student: locationStudentData } = location.state || {};
  const { id, essayId } = useParams<{ id: string; essayId: string }>();
  const { data: ibData, klassNum: ibKlassNum } = useIBGetById(Number(id), 'EE', {
    enabled: !(locationTitle && locationStudentData),
  });
  const data = location.state?.data || ibData;

  const acceptProposalTitle = data?.proposals?.find((proposal) => proposal.status === 'ACCEPT')?.researchTopic;

  const title = acceptProposalTitle;
  const studentId = Number(data?.leader.id);
  const klassNum = location.state?.student.klassNum || ibKlassNum;

  const { push } = useHistory();
  const me = useRecoilValue(meState);
  const [type, setType] = useState<tabType>(_type || 'checklist');
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { data: essayData, isLoading: essayLoading, refetch } = useEssayGetByIBId(Number(id));
  const { data: evaluationData, isFetching: evaluationLoading } = useEvaluationGetByStudent(studentId, {
    location: 'ESSAY',
  });

  const { approveEditStatus, isLoading: editLoading } = useIBEssayApproveStatus({
    onSuccess: () => {
      setConfirmModalOpen(true);
      refetch();
    },
  });

  const handleChange = (selectedType: tabType) => {
    setType(selectedType);
  };

  if (me == null) {
    return <div>접속 정보를 불러올 수 없습니다.</div>;
  }

  if (!essayData || !data) {
    return <Blank />;
  }

  const handleSuccess = () => {
    setIsOpen(!isOpen);
    refetch();
    setAlertMessage(`체크리스트가\n수정되었습니다`);
  };

  const isLoading = essayLoading || evaluationLoading || editLoading;

  const getUI = (type: tabType) => {
    switch (type) {
      case 'feedback':
        return (
          <div className="h-full w-full">
            {essayData.status === 'PENDING' ? (
              <div className="flex flex-col items-center gap-6 py-20">
                <div className="h-12 w-12 px-[2.50px]">
                  <img src={NODATA} className="h-12 w-[43px] object-cover" />
                </div>
                <Typography variant="body2">진행기록이 없습니다.</Typography>
              </div>
            ) : (
              <Feedback referenceId={essayData.id} referenceTable="ESSAY" user={me} />
            )}
          </div>
        );
      case 'evaluation':
        return <EvaluationList evaluationData={evaluationData} studentId={data.leader.id} />;
      case 'checklist':
        return (
          <>
            <CheckList studentId={studentId} type="update" charCount={essayData.charCount} />
            <div className="flex items-center justify-end">
              <ButtonV2
                size={40}
                variant="outline"
                color="gray400"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                수정
              </ButtonV2>
            </div>
          </>
        );
      // return <CheckList.Teacher student={data?.leader} charCount={essayData.charCount} />;
    }
  };

  return (
    <div className="col-span-6">
      {isLoading && <Blank />}
      <IBLayout
        className="bg-gray-50"
        topBgColor="bg-white"
        topContent={
          <div>
            <div className="w-full pb-6 pt-16">
              <div className="flex flex-col items-start gap-3">
                <div className="flex w-full justify-between">
                  <div className="flex flex-row gap-1">
                    <BadgeV2 color="dark_green" size={24} type="solid_strong" className="self-start">
                      EE
                    </BadgeV2>
                    <BadgeV2 color="gray" size={24} type="solid_regular" className="self-start">
                      에세이
                    </BadgeV2>
                  </div>
                  <Breadcrumb
                    data={{
                      진행상태: '/teacher/project',
                      EE: `/teacher/ib/ee/${id}`,
                      '에세이 상세': `/teacher/ib/ee/${id}/essay/${essayId}`,
                    }}
                  />
                </div>
                <div className="flex w-full justify-between">
                  <Typography variant="heading">{title}</Typography>
                  <div className="rounded-lg border border-orange-100 bg-orange-50 px-4 py-2 text-16 font-semibold text-orange-800">
                    {klassNum} · {data?.leader?.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        bottomContent={
          <div className="flex flex-grow flex-col">
            <div className="flex h-full flex-row gap-4 py-6">
              <div className="flex h-[812px] w-[848px] flex-col justify-between gap-6 rounded-xl bg-white p-6">
                <div className="h-full w-full">
                  <iframe
                    src={getUrlFromFile(essayData.filePath)}
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                    title="PDF Viewer"
                  />
                </div>
                <footer className="flex flex-row items-center justify-between">
                  <ButtonV2
                    size={40}
                    variant="outline"
                    color="gray400"
                    onClick={() => {
                      handleDownload(getUrlFromFile(essayData.filePath));
                    }}
                  >
                    다운로드
                  </ButtonV2>

                  {/* TODO: 표절률 */}
                  <div className="flex h-8 w-[161px] items-center gap-1 rounded-lg bg-primary-orange-50 px-[10px] py-[6px]">
                    <SVGIcon.Check color="orange800" size={16} />
                    <Typography variant="body3">
                      표절률이 <span className="text-primary-orange-800">75%</span> 입니다
                    </Typography>
                  </div>
                  <ButtonV2
                    size={40}
                    variant="solid"
                    color="gray100"
                    onClick={() => push(`/teacher/ib/ee/${id}`, { type: 'ESSAY' })}
                  >
                    목록 돌아가기
                  </ButtonV2>
                </footer>
              </div>
              <div className="flex h-[812px] w-[416px] flex-col gap-6 rounded-xl bg-white p-6">
                <LayeredTabs.TwoDepth onChange={handleChange} value={type} fullWidth={true}>
                  <Tab value="feedback">
                    <p>진행기록</p>
                  </Tab>
                  <Tab value="evaluation">
                    <p>평가</p>
                  </Tab>
                  <Tab value="checklist">
                    <p>체크리스트</p>
                  </Tab>
                </LayeredTabs.TwoDepth>
                {getUI(type)}
              </div>
            </div>
          </div>
        }
        floatingButton={
          <>
            {essayData.editStatus === 'LOCK' ? null : (
              <ButtonV2
                variant="solid"
                color="orange800"
                size={48}
                className="w-[416px]"
                disabled={essayData.editStatus !== ResponseIBDtoEditStatus.REQUEST_UNLOCK}
                onClick={() => approveEditStatus({ ibId: Number(id), essayId: Number(essayId) })}
              >
                에세이 수정 승인
              </ButtonV2>
            )}
          </>
        }
      />
      <SuperModal
        modalOpen={confirmModalOpen}
        setModalClose={() => setConfirmModalOpen(false)}
        hasClose={false}
        width="w-[416px]"
      >
        <div className="w-full">
          <div className="flex flex-col gap-2 p-8 text-center">
            <Typography variant="title2">
              에세이 수정권한 요청이
              <br />
              승인되었습니다
            </Typography>
          </div>
          <div className="p-5 pt-0">
            <ButtonV2
              variant="solid"
              color="orange800"
              size={48}
              className="w-full"
              onClick={() => setConfirmModalOpen(false)}
            >
              확인
            </ButtonV2>
          </div>
        </div>
      </SuperModal>
      {isOpen && (
        <IbEeEssay
          modalOpen={isOpen}
          setModalClose={() => setIsOpen(!isOpen)}
          type={'update_check'}
          projectId={Number(id)}
          onSuccess={handleSuccess}
          studentId={studentId}
          user="teacher"
          essayData={essayData}
        />
      )}
      {alertMessage && <AlertV2 message={alertMessage} confirmText="확인" onConfirm={() => setAlertMessage(null)} />}
    </div>
  );
};
