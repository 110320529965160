import { useState } from 'react';
import { RadioV2 } from 'src/components/common/RadioV2';
import { Typography } from 'src/components/common/Typography';
import { useIBRRSSubmissionStatus } from 'src/container/ib-overview';
import { useRRSGetSubmissionStatusCount } from 'src/generated/endpoint';
import { RRSGetSubmissionStatusCountParams, RRSGetSubmissionStatusStatus } from 'src/generated/model';
import RRSOverviewPanel from './RRSOverviewPanel';
import { useHistory } from 'react-router-dom';

export default function RRSView({ grade, klass, ibType }: RRSGetSubmissionStatusCountParams) {
  const { push } = useHistory();

  const [status, setStatus] = useState<RRSGetSubmissionStatusStatus>('SUBMIT_STATUS');

  const { data } = useRRSGetSubmissionStatusCount({
    grade,
    klass: klass === 0 ? undefined : klass,
    ibType,
  });

  const { students = [] } = useIBRRSSubmissionStatus({
    grade,
    klass: klass === 0 ? undefined : klass,
    ibType,
    status,
  });

  return (
    <div>
      <Typography variant="title1">RRS</Typography>
      <RadioV2.Group
        selectedValue={status}
        onChange={(value: RRSGetSubmissionStatusStatus) => setStatus(value)}
        className="flex flex-row gap-4 py-6"
      >
        <RadioV2.Label
          title="미작성"
          currentNum={data?.notSubmitted || 0}
          TotalNum={data?.total || 0}
          value="SUBMIT_STATUS"
        />
        <RadioV2.Label
          title="3개 미만 작성"
          currentNum={data?.lessThanThree || 0}
          TotalNum={data?.total || 0}
          value="LESS_THAN_THREE"
        />
      </RadioV2.Group>

      {status === 'SUBMIT_STATUS' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">RRS 미작성</Typography>
          <RRSOverviewPanel
            title="미작성"
            buttonText="미제출자 알림 보내기"
            buttonHandler={() => alert('미제출자 알림 보내기')}
            data={students}
            type="not"
          />
          <RRSOverviewPanel title="작성" data={students} type="submit" />
        </div>
      )}
      {status === 'LESS_THAN_THREE' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">3개 미만 작성</Typography>
          <RRSOverviewPanel
            title="제출"
            buttonText="최종 에세이 전체 다운로드"
            buttonHandler={() => alert('최종 에세이 전체 다운로드')}
            goDetailPage={(studentIbId) => push(`/teacher/ib/ee/${studentIbId}`, { type: 'RRS' })}
            data={students}
          />
        </div>
      )}
    </div>
  );
}
