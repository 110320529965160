import clsx from 'clsx';
import React, { useState } from 'react';
import SVGIcon from './icon/SVGIcon';

interface ListProps<T> {
  headerComponent: React.ReactNode;
  itemComponent: (item: T) => React.ReactNode;
  page: number;
  pageSize: number;
  totalItems: number;
  items: T[];
  onSelect: (item: T) => void;
}

const FrontPaginatedList = <T,>({
  headerComponent,
  itemComponent,
  page,
  pageSize,
  totalItems,
  items,
  onSelect,
}: ListProps<T>) => {
  const [currentPage, setCurrentPage] = useState(page);
  const totalPages = Math.ceil(totalItems / pageSize);
  const [startPage, setStartPage] = useState(1);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleClick = (item: T) => {
    onSelect(item);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedItems = items.slice(startIndex, startIndex + pageSize);

  return (
    <div className="flex flex-col">
      {/* 헤더 */}
      <div className="bottom-1 flex border-y border-gray-100 text-15 text-gray-500 ">{headerComponent}</div>

      {/* 목록 */}
      <div className="flex  flex-col">
        {paginatedItems.map((item, index) => (
          <div
            key={index}
            className="flex h-13 items-center border-b border-gray-100 text-15 text-gray-900 hover:bg-gray-100"
            onClick={() => handleClick(item)}
          >
            {itemComponent(item)}
          </div>
        ))}
      </div>

      {/* 페이지네이션 */}
      <div className="m-4 flex items-center justify-center space-x-2">
        {/* 이전 버튼 */}
        <button
          onClick={() => {
            if (startPage > 1) {
              setStartPage(startPage - 10);
            }
          }}
          className="h-8 w-8"
          disabled={startPage === 1}
        >
          <SVGIcon.Arrow color={startPage === 1 ? 'gray400' : 'gray700'} size={16} />
        </button>

        {/* 페이지 번호 */}
        {Array.from({ length: Math.min(10, totalPages - startPage + 1) }, (_, idx) => {
          const page = startPage + idx;
          return (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={clsx('h-8 w-8 rounded-md', currentPage === page ? 'bg-gray-700 text-white' : '')}
            >
              {page}
            </button>
          );
        })}

        {/* 다음 버튼 */}
        <button
          onClick={() => {
            if (startPage + 10 <= totalPages) {
              setStartPage(startPage + 10);
            }
          }}
          className="h-8 w-8"
          disabled={startPage + 10 > totalPages}
        >
          <SVGIcon.Arrow color={startPage + 10 > totalPages ? 'gray400' : 'gray700'} size={16} rotate={180} />
        </button>
      </div>
    </div>
  );
};

export default FrontPaginatedList;
