import { useIBCreateIB, useIBUpdateIBStatusWaitComplete, useIBUpdateIBStstusComplete } from 'src/generated/endpoint';
import { RequestIBDto } from 'src/generated/model';

interface UseIBCreateOptions {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
  onClose?: () => void;
}

export const useIBCreate = ({ onSuccess, onError, onClose }: UseIBCreateOptions) => {
  const { mutate, isLoading, isError, error } = useIBCreateIB({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('IB 프로젝트 생성 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const createIBProject = (data: RequestIBDto) => {
    mutate({ data });
  };

  return {
    createIBProject,
    isLoading,
    isError,
    error,
  };
};

export const useIBRequestComplete = ({ onSuccess, onError, onClose }: UseIBCreateOptions) => {
  const { mutate, isLoading, isError, error } = useIBUpdateIBStatusWaitComplete({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('IB 프로젝트 완료 요청 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const requestIBProjectComplete = (id: number) => {
    mutate({ id });
  };

  return {
    requestIBProjectComplete,
    isLoading,
    isError,
    error,
  };
};

export const useIBApproveComplete = ({ onSuccess, onError, onClose }: UseIBCreateOptions) => {
  const { mutate, isLoading, isError, error } = useIBUpdateIBStstusComplete({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('IB 프로젝트 완료 승인 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const approveIBProjectComplete = (id: number) => {
    mutate({ id });
  };

  return {
    approveIBProjectComplete,
    isLoading,
    isError,
    error,
  };
};
