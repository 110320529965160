import { forwardRef, PropsWithChildren } from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'src/components/common/Input';
import SelectBar from 'src/components/common/SelectBar';
import { TextareaV2 } from 'src/components/common/TextareaV2';
import { Typography } from 'src/components/common/Typography';
import { ImageNFileUpload } from '../common/ImageNFileUpload';

interface InputFieldProps {
  label?: string;
  name: string;
  control: any;
  placeholder?: string;
  dropdownWidth?: string;
  type?: 'input' | 'select' | 'textarea' | 'files';
  options?: { id: number; value: string }[];
  size?: 40 | 32 | 48;
  className?: string;
  readOnly?: boolean;
}

export const InputField = forwardRef<HTMLDivElement, PropsWithChildren<InputFieldProps>>(
  (
    {
      label,
      name,
      control,
      placeholder,
      type = 'input',
      options,
      dropdownWidth,
      size = 40,
      className,
      readOnly = false,
    },
    ref,
  ) => {
    return (
      <section ref={ref} className="flex flex-col gap-3">
        {label && (
          <Typography variant="title3" className="font-semibold">
            {label}
          </Typography>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field }) =>
            type === 'select' ? (
              <SelectBar
                dropdownWidth={dropdownWidth}
                options={options || []}
                value={field.value}
                onChange={field.onChange}
                placeholder={placeholder}
                size={size}
                disabled={readOnly}
              />
            ) : type === 'textarea' ? (
              <TextareaV2 className={className} placeholder={placeholder} {...field} readOnly={readOnly} />
            ) : type === 'files' ? (
              <ImageNFileUpload />
            ) : (
              <Input.Basic placeholder={placeholder} size={size} {...field} readOnly={readOnly} className={className} />
            )
          }
        />
      </section>
    );
  },
);
