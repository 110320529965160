import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SelectBar from '../common/SelectBar';
import { Typography } from '../common/Typography';
import FrontPaginatedList from '../FrontPaginatedList ';

const items = [
  { id: 1, grade: '1', ban: '1', studentNum: '1', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '2', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '3', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '4', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '5', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '6', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '7', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '8', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '9', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '10', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '11', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '12', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '13', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '14', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '15', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '16', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '17', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '18', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '19', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '21', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '22', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '3', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '1', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '1', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '1', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '1', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '1', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '1', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '1', name: '김하나', teacher: '김수학' },
  { id: 1, grade: '1', ban: '1', studentNum: '1', name: '김하나', teacher: '김수학' },
];

const grade = [
  { id: 1, value: '1학년' },
  { id: 2, value: '2학년' },
  { id: 3, value: '3학년' },
  { id: 4, value: '4학년' },
];
const klass = [
  { id: 1, value: '1반' },
  { id: 2, value: '2반' },
  { id: 3, value: '3반' },
  { id: 4, value: '4반' },
];
const teacher = [
  { id: 1, value: '김수학' },
  { id: 2, value: '강지은' },
];

export default function CoordinatorEE_Teacher() {
  const { push } = useHistory();

  const [selectedOptions, setSelectedOptions] = useState({
    grade: '',
    klass: '',
  });
  const [selectedTeacher, setSelectedTeacher] = useState<number>();

  const handleOptionChange = (optionType: 'grade' | 'klass', value: number) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [optionType]: value,
    }));
  };

  const Header = () => (
    <>
      <div className="flex-[1] p-2 text-center">체크</div>
      <div className="flex-[2] p-2 text-center">학년</div>
      <div className="flex-[2] p-2 text-center">반</div>
      <div className="flex-[2] p-2 text-center">번호</div>
      <div className="flex-[3] p-2 text-center">이름</div>
      <div className="flex-[3] p-2 text-center">담당교사</div>
    </>
  );

  // Item 컴포넌트
  const Item = ({ item }: { item: any }) => (
    <>
      <div className="flex-[1] p-2 text-center">{'체크'}</div>
      <div className="flex-[2] p-2 text-center">{item.grade}</div>
      <div className="flex-[2] p-2 text-center">{item.ban}</div>
      <div className="flex-[2] p-2 text-center">{item.studentNum}</div>
      <div className="flex-[3] p-2 text-center">{item.name}</div>
      <div className="flex-[3] p-2 text-center">{item.teacher}</div>
    </>
  );

  return (
    <div className="rounded-lg bg-white ">
      <div className=" m-6 ">
        <Typography variant="title1">담당교사 지정</Typography>
      </div>
      <div className=" m-6 mb-5 flex flex-row items-center  justify-between">
        <div className="flex flex-row items-center gap-2">
          <SelectBar
            options={grade}
            value={selectedOptions.grade}
            onChange={(value: number) => handleOptionChange('grade', value)}
            placeholder="학년 선택"
            size={40}
            className="w-30"
          />
          <SelectBar
            options={klass}
            value={selectedOptions.klass}
            onChange={(value: number) => handleOptionChange('klass', value)}
            placeholder="반 선택"
            size={40}
            className="w-30"
          />
        </div>
        <SelectBar
          options={teacher}
          value={selectedTeacher}
          onChange={(value: number) => setSelectedTeacher(value)}
          placeholder="교사 변경하기"
          size={40}
          className="w-40"
        />
      </div>

      <FrontPaginatedList
        headerComponent={<Header />}
        itemComponent={(item) => <Item item={item} />}
        page={1}
        pageSize={2}
        totalItems={items.length}
        items={items}
        onSelect={(item) => console.log('선택된 항목:', item)}
      />
    </div>
  );
}
