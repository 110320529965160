import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import NODATA from 'src/assets/images/no-data.png';
import AlertV2 from 'src/components/common/AlertV2';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { TextareaV2 } from 'src/components/common/TextareaV2';
import { Typography } from 'src/components/common/Typography';
import { Feedback } from 'src/components/ib/Feedback';
import IBLayout from 'src/components/ib/IBLayout';
import { InputField } from 'src/components/ib/InputField';
import SVGIcon from 'src/components/icon/SVGIcon';
import { PopupModal } from 'src/components/PopupModal';
import { EE_SUBJECTS } from 'src/constants/ib';
import { useIBGetById } from 'src/container/ib-project-get-student';
import { useIBProposalDelete } from 'src/container/ib-proposal-delete';
import { useIBProposalEditStatus, useIBProposalUpdate } from 'src/container/ib-proposal-update';
import { RequestIBProposalUpdateDto } from 'src/generated/model';
import { meState } from 'src/store';

export const ProposalDetailPage = () => {
  const history = useHistory();
  const { id: idParam, proposalId: proposalIdParam } = useParams<{ id: string; proposalId: string }>();
  const id = Number(idParam);
  const proposalId = Number(proposalIdParam);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const me = useRecoilValue(meState);
  const { data, isLoading, refetch } = useIBGetById(id, 'EE');
  const [alertMessage, setAlertMessage] = useState<{ text: string; action?: () => void } | null>(null);
  const proposalData = data?.proposals?.find((proposal) => proposal.id === proposalId);
  const { updateIBProposal } = useIBProposalUpdate({
    onSuccess: () => {
      setAlertMessage({ text: `제안서가\n저장되었습니다` });
      refetch();
    },
    onError: (error) => {
      console.error('IB 프로젝트 생성 중 오류 발생:', error);
    },
  });

  const { editIBProposalStatus } = useIBProposalEditStatus({
    onSuccess: () => {
      setAlertMessage({ text: `제안서 수정권한을\n요청하였습니다` });
      setIsOpen(!isOpen);
    },
    onError: (error) => {
      console.error('제안서 수정권한 요청 중 오류 발생:', error);
    },
  });

  const { deleteIBProposal } = useIBProposalDelete({
    onSuccess: () => {
      setAlertMessage({
        text: `제안서를\n삭제하였습니다`,
        action: () => history.push(`/ib/student/ee/${id}`, { type: 'PROPOSAL' }), // 목록으로 이동
      });
    },
    onError: (error) => {
      console.error('제안서 삭제 요청중 오류 발생:', error);
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RequestIBProposalUpdateDto>({
    defaultValues: proposalData,
  });

  const onSubmit = (data: RequestIBProposalUpdateDto) => {
    if (isLoading) return;

    updateIBProposal({ id, proposalId, data });
    setEditMode(!editMode);
  };

  if (me == null) {
    return <div>접속 정보를 불러올 수 없습니다.</div>;
  }

  return (
    <div className="col-span-6">
      <IBLayout
        topContent={
          <div>
            <div className="w-full pb-6 pt-16">
              <div className="flex flex-col items-start gap-3">
                <div className="flex w-full flex-row items-center justify-between">
                  <div className="flex flex-row gap-1">
                    <BadgeV2 color="dark_green" size={24} type="solid_strong" className="self-start">
                      EE
                    </BadgeV2>
                    <BadgeV2 color="gray" size={24} type="solid_regular" className="self-start">
                      제안서
                    </BadgeV2>
                    <BadgeV2 color="gray" size={24} type="line" className="self-start">
                      {proposalData?.rank}순위
                    </BadgeV2>
                    {proposalData?.status === 'SUBMIT' && (
                      <BadgeV2 color="gray" size={24} type="line" className="self-start">
                        제안
                      </BadgeV2>
                    )}
                  </div>
                  <Breadcrumb
                    data={{
                      진행상태: '/ib/student',
                      EE: `/ib/student/ee/${id}`,
                      '제안서 상세': `/ib/student/ee/${id}/proposal/${proposalId}`,
                    }}
                  />
                </div>
                <Typography variant="heading" className="max-w-[848px]">
                  {proposalData?.researchTopic}
                </Typography>
              </div>
            </div>
          </div>
        }
        bottomContent={
          <div className="flex flex-grow flex-col">
            <div className="flex h-full flex-row gap-4 py-6">
              <div className="flex w-[848px] flex-col rounded-xl bg-white p-6">
                {editMode ? (
                  <>
                    <form>
                      <div className="scroll-box flex h-full flex-col gap-6 overflow-auto pb-8 pt-4">
                        <InputField
                          label="지식영역"
                          name="subject"
                          control={control}
                          placeholder="지식영역을 선택해주세요"
                          type="select"
                          options={EE_SUBJECTS}
                          size={40}
                        />
                        <InputField
                          label="모델 논문"
                          name="modelPaper"
                          control={control}
                          placeholder="모델 논문을 입력해주세요"
                        />
                        <InputField
                          label="모델 논문 요약"
                          name="modelPaperSummary"
                          control={control}
                          placeholder="모델 논문 요약을 입력해주세요"
                          className="h-40"
                          type="textarea"
                        />
                        <InputField
                          label="연구주제"
                          name="researchTopic"
                          control={control}
                          placeholder="연구주제를 입력해주세요"
                        />
                        <InputField
                          label="연구 질문"
                          name="researchQuestion"
                          control={control}
                          placeholder="연구 질문을 입력해주세요"
                          className="h-40"
                          type="textarea"
                        />
                        <InputField
                          label="연구의 필요성"
                          name="researchNeed"
                          control={control}
                          placeholder="연구의 필요성을 입력해주세요"
                          className="h-40"
                          type="textarea"
                        />
                        <InputField
                          label="연구 방법"
                          name="researchMethod"
                          control={control}
                          placeholder="연구 방법을 입력해주세요"
                          className="h-40"
                          type="textarea"
                        />
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col gap-4 pb-6">
                      <Typography variant="title3" className="font-semibold">
                        지식영역
                      </Typography>
                      <div className="flex h-12 flex-row items-center gap-3 rounded-lg border border-primary-gray-200 bg-white px-4">
                        <SVGIcon.Note size={20} color="gray700" weight="bold" className="cursor-default" />
                        <Typography variant="body2" className="text-primary-gray-700">
                          {proposalData?.subject}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 py-6">
                      <Typography variant="title3" className="font-semibold">
                        모델 논문
                      </Typography>
                      <div className="rounded-lg border border-primary-gray-200 bg-white px-4 py-[13px]">
                        <Typography variant="body2" className="font-medium text-primary-gray-700">
                          {proposalData?.modelPaper}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 py-6">
                      <Typography variant="title3" className="font-semibold">
                        모델 논문 요약
                      </Typography>
                      <div className="shrink grow basis-0 rounded-lg border border-primary-gray-200 bg-white px-4 py-[13px]">
                        <Typography variant="body2" className="font-medium text-primary-gray-700">
                          {proposalData?.modelPaperSummary}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 py-6">
                      <Typography variant="title3" className="font-semibold">
                        연구 주제
                      </Typography>
                      <div className="shrink grow basis-0 rounded-lg border border-primary-gray-200 bg-white px-4 py-[13px]">
                        <Typography variant="body2" className="font-medium text-primary-gray-700">
                          {proposalData?.researchTopic}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 py-6">
                      <Typography variant="title3" className="font-semibold">
                        연구 질문
                      </Typography>
                      <div className="shrink grow basis-0 rounded-lg border border-primary-gray-200 bg-white px-4 py-[13px]">
                        <Typography variant="body2" className="font-medium text-primary-gray-700">
                          {proposalData?.researchQuestion}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 py-6">
                      <Typography variant="title3" className="font-semibold">
                        연구의 필요성
                      </Typography>
                      <div className="shrink grow basis-0 rounded-lg border border-primary-gray-200 bg-white px-4 py-[13px]">
                        <Typography variant="body2" className="font-medium text-primary-gray-700">
                          {proposalData?.researchNeed}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 pb-10 pt-6">
                      <Typography variant="title3" className="font-semibold">
                        연구 방법
                      </Typography>
                      <div className="shrink grow basis-0 rounded-lg border border-primary-gray-200 bg-white px-4 py-[13px]">
                        <Typography variant="body2" className="font-medium text-primary-gray-700">
                          {proposalData?.researchMethod}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}

                <footer className={`flex flex-row items-center justify-between`}>
                  {editMode ? (
                    <>
                      <ButtonV2 size={40} variant="solid" color="gray100" onClick={() => setEditMode(!editMode)}>
                        취소
                      </ButtonV2>
                      <ButtonV2 size={40} variant="solid" color="orange100" onClick={handleSubmit(onSubmit)}>
                        저장하기
                      </ButtonV2>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-row items-center gap-2">
                        {(proposalData?.status === 'PENDING' ||
                          (data?.editStatus === 'UNLOCK' &&
                            (data?.status === 'REJECT_PLAN' || data?.status === 'IN_PROGRESS'))) && (
                          <ButtonV2 size={40} variant="outline" color="gray400" onClick={() => setEditMode(!editMode)}>
                            수정
                          </ButtonV2>
                        )}

                        {proposalData?.status === 'PENDING' && (
                          <ButtonV2
                            size={40}
                            variant="outline"
                            color="gray400"
                            onClick={() => deleteIBProposal({ id, proposalId })}
                          >
                            삭제
                          </ButtonV2>
                        )}
                        {/* 수정요청이 가능한 경우는 제출 후  */}
                        {/* TODO 마감기한 지났을 경우에도 동일한 조건으로 처리 */}
                        {data?.status !== 'PENDING' &&
                          (data?.editStatus === 'LOCK' || data?.editStatus === 'REQUEST_UNLOCK') && (
                            <ButtonV2
                              size={40}
                              variant="outline"
                              color="gray400"
                              onClick={() => setIsOpen(!isOpen)}
                              disabled={
                                data?.editStatus === 'REQUEST_UNLOCK' ||
                                data?.status === 'WAIT_MENTOR' ||
                                data?.status === 'WAIT_PLAN_APPROVE'
                              }
                            >
                              수정권한 요청
                            </ButtonV2>
                          )}
                        {/* {((proposalData?.status === 'ACCEPT' &&
                          data?.status === 'IN_PROGRESS' &&
                          data.editStatus === 'LOCK') || data?.editStatus === 'LOCK' ||
                          data?.editStatus === 'REQUEST_UNLOCK' ||
                          (proposalData?.status === 'SUBMIT' && data?.status === 'REJECT_PLAN') ||
                          (proposalData?.status === 'SUBMIT' && data?.status === 'WAIT_MENTOR')) && (
                          <ButtonV2
                            size={40}
                            variant="outline"
                            color="gray400"
                            onClick={() => setIsOpen(!isOpen)}
                            disabled={data?.editStatus === 'REQUEST_UNLOCK' || data.status === 'WAIT_MENTOR'}
                          >
                            수정요청
                          </ButtonV2>
                        )} */}
                      </div>
                      <ButtonV2
                        size={40}
                        variant="solid"
                        color="gray100"
                        onClick={() => history.push(`/ib/student/ee/${id}`, { type: 'PROPOSAL' })}
                      >
                        목록 돌아가기
                      </ButtonV2>
                    </>
                  )}
                </footer>
              </div>
              <div className="flex h-[720px] w-[416px] flex-col gap-6 rounded-xl bg-white p-6">
                <Typography variant="title1">진행기록</Typography>
                {proposalData?.status === 'ACCEPT' ||
                (proposalData?.status === 'SUBMIT' && data?.status === 'REJECT_PLAN') ? (
                  <div className="h-full w-full">
                    <Feedback referenceId={id} referenceTable="IB" user={me} />
                  </div>
                ) : (
                  <div className="flex flex-col items-center gap-6 py-20">
                    <div className="h-12 w-12 px-[2.50px]">
                      <img src={NODATA} className="h-12 w-[43px] object-cover" />
                    </div>
                    <Typography variant="body2">진행기록이 없습니다.</Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        }
        // TODO "제안서 보완요청" 상태일 경우 조건 처리
        // floatingButton={
        //   editMode ? null : proposalData?.status === 'PENDING' ||
        //     (proposalData?.status === 'ACCEPT' && data?.status === 'REJECT_PLAN') ? (
        //     <ButtonV2
        //       variant="solid"
        //       color="orange800"
        //       size={48}
        //       className="w-[416px]"
        //       onClick={() => sentIBProposal({ id, proposalId })}
        //       disabled={data?.status === 'WAIT_PLAN_APPROVE'}
        //     >
        //       제안서 승인요청
        //     </ButtonV2>
        //   ) : null
        // }
        bottomBgColor="bg-primary-gray-50"
      />
      {isOpen && (
        <PopupModal
          modalOpen={isOpen}
          setModalClose={() => setIsOpen(!isOpen)}
          title="제안서 수정권한 요청"
          bottomBorder={false}
          footerButtons={
            <div className="flex gap-2">
              <ButtonV2
                variant="solid"
                color="orange800"
                size={48}
                onClick={() => editIBProposalStatus({ id, data: { content } })}
              >
                요청하기
              </ButtonV2>
            </div>
          }
        >
          <div className="flex flex-col gap-4">
            <Typography variant="body1">제안서를 수정하는 사유를 입력해주세요.</Typography>
            <TextareaV2 placeholder="내용을 입력하세요." value={content} onChange={(e) => setContent(e.target.value)} />
          </div>
        </PopupModal>
      )}
      {alertMessage && (
        <AlertV2
          message={alertMessage.text}
          confirmText="확인"
          onConfirm={() => {
            if (alertMessage.action) alertMessage.action();
            setAlertMessage(null);
          }}
        />
      )}
    </div>
  );
};
