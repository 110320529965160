// ProposalInputField.tsx
import { PropsWithChildren } from 'react';
import { Input } from 'src/components/common/Input';
import { TextareaV2 } from 'src/components/common/TextareaV2';
import { Typography } from 'src/components/common/Typography';
import ColorSVGIcon from '../icon/ColorSVGIcon';
import { QA } from './CoordinatorEE_FAQ_AddFaq';

interface FaqInputFieldProps {
  index: number;
  question: QA;
  content: QA[];
  setContent: (content: QA[]) => void;
  size?: 40 | 32 | 48;
  readOnly?: boolean;
}

export const FaqInputField = ({
  index,
  question,
  content = [],
  setContent,
  size = 40,
  readOnly = false,
}: PropsWithChildren<FaqInputFieldProps>) => {
  const onChangeQuestion = (e: React.ChangeEvent<HTMLInputElement>) => {
    content[index].question = e.target.value; // 변경된 값 업데이트
    setContent(content); // 부모 상태 업데이트
  };

  const onChangeAnswer = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    content[index].answer = e.target.value;
    setContent(content);
  };

  const deleteContent = (id: number) => {
    setContent(content.filter((q: QA) => q.id !== id));
  };

  return (
    <section>
      <div className="flex flex-col gap-3 rounded-md bg-gray-50 p-4">
        <div className="flex items-center justify-between">
          <Typography variant="title3" className="font-semibold">
            질문 <span className="text-primary-orange-800">{index + 1}</span>
          </Typography>
          <ColorSVGIcon.Close color="gray700" size={24} onClick={() => deleteContent(question.id)} />
        </div>

        <Input.Basic
          className="bg-white"
          placeholder={'질문을 입력해주세요.'}
          size={size}
          readOnly={readOnly}
          value={question.question} // 부모에서 전달받은 question.question 사용
          onChange={onChangeQuestion}
        />
        <TextareaV2
          className="h-20"
          placeholder={'답변을 입력해주세요.'}
          readOnly={readOnly}
          value={question.answer}
          onChange={onChangeAnswer}
        />
      </div>
    </section>
  );
};
