import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RadioV2 } from 'src/components/common/RadioV2';
import { Typography } from 'src/components/common/Typography';
import EssayOverviewPanel from 'src/components/ib/overview/EssayOverviewPanel';
import { useIBEssayStatus, useIBEssaySubmissionStatus } from 'src/container/ib-overview';
import { EssayGetSubmissionStatusCountParams, EssayGetSubmissionStatusStatus } from 'src/generated/model';

export default function EssayView({ grade, klass, ibType }: EssayGetSubmissionStatusCountParams) {
  const { push } = useHistory();

  const [status, setStatus] = useState<EssayGetSubmissionStatusStatus>('NOT_SUBMITTED');

  const { data } = useIBEssayStatus({
    grade,
    klass: klass === 0 ? undefined : klass,
    ibType,
  });

  const { students = [] } = useIBEssaySubmissionStatus({
    grade,
    klass: klass === 0 ? undefined : klass,
    ibType,
    status,
  });

  return (
    <div>
      <Typography variant="title1">에세이</Typography>
      <RadioV2.Group
        selectedValue={status}
        onChange={(value: EssayGetSubmissionStatusStatus) => setStatus(value)}
        className="flex flex-row gap-4 py-6"
      >
        <RadioV2.Label
          title="미제출"
          currentNum={data?.notSubmitted || 0}
          TotalNum={data?.total || 0}
          value="NOT_SUBMITTED"
        />
        <RadioV2.Label
          title="제출완료"
          currentNum={data?.submitted || 0}
          TotalNum={data?.total || 0}
          value="SUBMITTED"
        />
      </RadioV2.Group>

      {status === 'NOT_SUBMITTED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">에세이 미제출</Typography>
          <EssayOverviewPanel
            title="미제출"
            buttonText="미제출자 알림 보내기"
            buttonHandler={() => alert('미제출자 알림 보내기')}
            data={students}
          />
        </div>
      )}
      {status === 'SUBMITTED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">에세이 제출완료</Typography>
          <EssayOverviewPanel
            title="제출"
            buttonText="최종 에세이 전체 다운로드"
            goDetailPage={(studentId) => push(`/teacher/ib/ee/${studentId}`)}
            buttonHandler={() => alert('최종 에세이 전체 다운로드')}
            data={students}
          />
        </div>
      )}
    </div>
  );
}
