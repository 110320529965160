import { useState } from 'react';
import { RadioV2 } from 'src/components/common/RadioV2';
import { Typography } from 'src/components/common/Typography';
import { useIBRPPFStatus, useIBRPPFSubmissionStatus } from 'src/container/ib-overview';
import { IBGetSubmissionStatusCountParams, RPPFGetSubmissionStatusStatus } from 'src/generated/model';
import RPPFOverviewPanel from './RPPFOverviewPanel';
import RPPFPdfPopup from './RPPFPdfPopup';
import { modifyRppfPdf } from 'src/util/ib_rppf_pdf';
import { saveAs } from 'file-saver';
import { useHistory } from 'react-router-dom';

export default function RPPFView({ grade, klass, ibType }: IBGetSubmissionStatusCountParams) {
  const { push } = useHistory();

  const [status, setStatus] = useState<RPPFGetSubmissionStatusStatus>('FIRST_DRAFT_COMPLETED');
  const [previewPopup, setPreviewPopup] = useState<boolean>(false);
  const [pdfData, setPdfData] = useState<any>({});

  const { data } = useIBRPPFStatus({
    grade,
    klass: klass === 0 ? undefined : klass,
    ibType,
  });

  const { students = [] } = useIBRPPFSubmissionStatus({
    grade,
    klass: klass === 0 ? undefined : klass,
    ibType,
    status,
  });

  const openPreviewPopup = (studentIbId: number) => {
    setPreviewPopup(true);
    const studentData = students.find((data) => data.id === studentIbId);
    setPdfData({ ...studentData?.rppf, ...studentData?.leader });
  };

  const pdfAllDownload = () => {
    const pdfPath = '/EE-RPPF.pdf';
    students.forEach(async (student) => {
      const data = { ...student.rppf, ...student.leader };
      const pdfURL = await modifyRppfPdf({ pdfPath, data });
      if (!pdfURL) {
        console.error(`PDF 생성 실패: 학생 ID ${student.id}`);
        return;
      }
      // TODO: 파일명 변경 필요: EE_응시코드_RPPF
      saveAs(pdfURL, `EE_${student.id}_RPPF`);
    });
  };
  return (
    <div>
      <Typography variant="title1">RPPF</Typography>
      <RadioV2.Group
        selectedValue={status}
        onChange={(value: RPPFGetSubmissionStatusStatus) => setStatus(value)}
        className="flex flex-row gap-4 py-6"
      >
        <RadioV2.Label
          title="1차 작성완료"
          currentNum={data?.firstDraftCompleted || 0}
          TotalNum={data?.total || 0}
          value="FIRST_DRAFT_COMPLETED"
        />
        <RadioV2.Label
          title="2차 작성완료"
          currentNum={data?.secondDraftCompleted || 0}
          TotalNum={data?.total || 0}
          value="SECOND_DRAFT_COMPLETED"
        />
        <RadioV2.Label
          title="3차 작성완료"
          currentNum={data?.thirdDraftCompleted || 0}
          TotalNum={data?.total || 0}
          value="THIRD_DRAFT_COMPLETED"
        />
        <RadioV2.Label
          title="IB제출 지도의견 작성완료"
          currentNum={data?.ibTeacherFeedbackCompleted || 0}
          TotalNum={data?.total || 0}
          value="IB_TEACHER_FEEDBACK_COMPLETED"
        />
      </RadioV2.Group>

      {status === 'FIRST_DRAFT_COMPLETED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">1차 작성완료</Typography>
          <RPPFOverviewPanel
            action={(studentIbId, rppfId) => push(`/teacher/ib/ee/${studentIbId}/rppf/${rppfId}`)}
            title="제출"
            buttonText="제안서 전체 다운로드"
            buttonHandler={() => alert('제안서 전체 다운로드')}
            data={students}
          />
        </div>
      )}
      {status === 'SECOND_DRAFT_COMPLETED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">2차 작성완료</Typography>
          <RPPFOverviewPanel
            action={(studentIbId, rppfId) => push(`/teacher/ib/ee/${studentIbId}/rppf/${rppfId}`)}
            title="제출"
            buttonText="대기자 알림 보내기"
            buttonHandler={() => alert('대기자 알림 보내기')}
            data={students}
          />
        </div>
      )}
      {status === 'THIRD_DRAFT_COMPLETED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">3차 작성완료</Typography>
          <RPPFOverviewPanel
            action={(studentIbId, rppfId) => push(`/teacher/ib/ee/${studentIbId}/rppf/${rppfId}`)}
            title="제출"
            buttonText="알림 보내기"
            buttonHandler={() => alert('알림 보내기')}
            data={students}
          />
        </div>
      )}
      {status === 'IB_TEACHER_FEEDBACK_COMPLETED' && (
        <div className="flex flex-col gap-4">
          <Typography variant="title2">RPPF IB제출 지도의견 작성완료</Typography>
          <RPPFOverviewPanel
            title="미완료"
            buttonText="알림 보내기"
            buttonHandler={() => alert('알림 보내기')}
            data={students}
          />
          <RPPFOverviewPanel
            title="완료"
            buttonText="IB제출 RPPF 전체 다운로드"
            buttonHandler={pdfAllDownload}
            data={students}
            action={openPreviewPopup}
          />
        </div>
      )}

      {previewPopup && (
        <RPPFPdfPopup modalOpen={previewPopup} setModalClose={() => setPreviewPopup(false)} data={pdfData} />
      )}
    </div>
  );
}
