import clsx from 'clsx';

export interface ColorSVGIconProps {
  color?: 'gray700' | 'gray400' | 'orange800' | 'white' | 'dimmed';
  size?: number;
  rotate?: 0 | 90 | 180 | 270;
  className?: string;
  onClick?: () => void;
}

// 공통 SVG 설정 함수
const ColorSVGIconBase = ({
  size = 16,
  rotate = 0,
  className,
  onClick,
  children,
}: ColorSVGIconProps & { children: React.ReactNode }) => {
  return (
    <svg
      width={size}
      height={size}
      onClick={onClick}
      className={clsx(className, {
        'rotate-0': rotate === 0,
        'rotate-90': rotate === 90,
        'rotate-180': rotate === 180,
        'rotate-270': rotate === 270,
      })}
      viewBox="0 0 16 16"
    >
      {children}
    </svg>
  );
};

const NewIcon: React.FC<ColorSVGIconProps> = ({ color = 'gray700', ...props }) => {
  switch (color) {
    case 'gray700':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="6" fill="#E8EAED" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.84281 5.71655C6.68001 5.52523 6.40643 5.45145 6.16052 5.53255C5.9146 5.61365 5.75 5.83194 5.75 6.07695V9.92305C5.75 10.2417 6.02473 10.5 6.36364 10.5C6.70254 10.5 6.97727 10.2417 6.97727 9.92305V7.72161L9.15719 10.2834C9.31999 10.4748 9.59357 10.5485 9.83948 10.4674C10.0854 10.3863 10.25 10.1681 10.25 9.92305V6.07695C10.25 5.75833 9.97527 5.50003 9.63636 5.50003C9.29746 5.50003 9.02273 5.75833 9.02273 6.07695V8.27839L6.84281 5.71655Z"
            fill="#4C5057"
          />
        </ColorSVGIconBase>
      );
    case 'gray400':
      return (
        <ColorSVGIconBase {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM6.16052 5.53255C6.40643 5.45145 6.68001 5.52523 6.84281 5.71655L9.02273 8.27839V6.07695C9.02273 5.75833 9.29746 5.50003 9.63636 5.50003C9.97527 5.50003 10.25 5.75833 10.25 6.07695V9.92305C10.25 10.1681 10.0854 10.3863 9.83948 10.4674C9.59357 10.5485 9.31999 10.4748 9.15719 10.2834L6.97727 7.72161V9.92305C6.97727 10.2417 6.70254 10.5 6.36364 10.5C6.02473 10.5 5.75 10.2417 5.75 9.92305V6.07695C5.75 5.83194 5.9146 5.61365 6.16052 5.53255Z"
            fill="#C7CBD1"
          />
        </ColorSVGIconBase>
      );
    case 'orange800':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="6" fill="#FF600C" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.84281 5.71655C6.68001 5.52523 6.40643 5.45145 6.16052 5.53255C5.9146 5.61365 5.75 5.83194 5.75 6.07695V9.92305C5.75 10.2417 6.02473 10.5 6.36364 10.5C6.70254 10.5 6.97727 10.2417 6.97727 9.92305V7.72161L9.15719 10.2834C9.31999 10.4748 9.59357 10.5485 9.83948 10.4674C10.0854 10.3863 10.25 10.1681 10.25 9.92305V6.07695C10.25 5.75833 9.97527 5.50003 9.63636 5.50003C9.29746 5.50003 9.02273 5.75833 9.02273 6.07695V8.27839L6.84281 5.71655Z"
            fill="white"
          />
        </ColorSVGIconBase>
      );
    case 'white':
      return (
        <ColorSVGIconBase {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM6.16052 5.53255C6.40643 5.45145 6.68001 5.52523 6.84281 5.71655L9.02273 8.27839V6.07695C9.02273 5.75833 9.29746 5.50003 9.63636 5.50003C9.97527 5.50003 10.25 5.75833 10.25 6.07695V9.92305C10.25 10.1681 10.0854 10.3863 9.83948 10.4674C9.59357 10.5485 9.31999 10.4748 9.15719 10.2834L6.97727 7.72161V9.92305C6.97727 10.2417 6.70254 10.5 6.36364 10.5C6.02473 10.5 5.75 10.2417 5.75 9.92305V6.07695C5.75 5.83194 5.9146 5.61365 6.16052 5.53255Z"
            fill="white"
          />
        </ColorSVGIconBase>
      );
    default:
      return null;
  }
};

const CloseIcon: React.FC<ColorSVGIconProps> = ({ color = 'gray700', ...props }) => {
  switch (color) {
    case 'gray700':
      return (
        <ColorSVGIconBase {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM6.85355 6.14645C6.65829 5.95118 6.34171 5.95118 6.14645 6.14645C5.95118 6.34171 5.95118 6.65829 6.14645 6.85355L7.29289 8L6.14645 9.14645C5.95118 9.34171 5.95118 9.65829 6.14645 9.85355C6.34171 10.0488 6.65829 10.0488 6.85355 9.85355L8 8.70711L9.14645 9.85355C9.34171 10.0488 9.65829 10.0488 9.85355 9.85355C10.0488 9.65829 10.0488 9.34171 9.85355 9.14645L8.70711 8L9.85355 6.85355C10.0488 6.65829 10.0488 6.34171 9.85355 6.14645C9.65829 5.95118 9.34171 5.95118 9.14645 6.14645L8 7.29289L6.85355 6.14645Z"
            fill="#4C5057"
          />
        </ColorSVGIconBase>
      );
    case 'gray400':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="6" fill="#C7CBD1" />
          <path d="M6 10L8 8M8 8L10 6M8 8L6 6M8 8L10 10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </ColorSVGIconBase>
      );
    case 'orange800':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="6" fill="#FF600C" />
          <path d="M6 10L8 8M8 8L10 6M8 8L6 6M8 8L10 10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </ColorSVGIconBase>
      );
    case 'white':
      return (
        <ColorSVGIconBase {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM6.35355 5.64645C6.15829 5.45118 5.84171 5.45118 5.64645 5.64645C5.45118 5.84171 5.45118 6.15829 5.64645 6.35355L7.29289 8L5.64645 9.64645C5.45118 9.84171 5.45118 10.1583 5.64645 10.3536C5.84171 10.5488 6.15829 10.5488 6.35355 10.3536L8 8.70711L9.64645 10.3536C9.84171 10.5488 10.1583 10.5488 10.3536 10.3536C10.5488 10.1583 10.5488 9.84171 10.3536 9.64645L8.70711 8L10.3536 6.35355C10.5488 6.15829 10.5488 5.84171 10.3536 5.64645C10.1583 5.45118 9.84171 5.45118 9.64645 5.64645L8 7.29289L6.35355 5.64645Z"
            fill="white"
          />
        </ColorSVGIconBase>
      );
    case 'dimmed':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="6" fill="black" fillOpacity="0.6" />
          <path
            d="M6.5 9.5L8 8M8 8L9.5 6.5M8 8L6.5 6.5M8 8L9.5 9.5"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </ColorSVGIconBase>
      );
    default:
      return null;
  }
};

const PinIcon: React.FC<ColorSVGIconProps> = ({ color = 'gray700', ...props }) => {
  switch (color) {
    case 'gray700':
      return (
        <ColorSVGIconBase {...props}>
          <path
            d="M7.40022 0.666667C7.40022 0.298477 7.66875 0 8 0C8.33125 0 8.59978 0.298477 8.59978 0.666667V15.3333C8.59978 15.7015 8.33125 16 8 16C7.66875 16 7.40022 15.7015 7.40022 15.3333V0.666667Z"
            fill="#4C5057"
          />
          <path
            d="M7.76009 2.31111C7.88004 2.48889 8.11996 2.48889 8.23991 2.31111L9.43948 0.533333C9.58775 0.313588 9.44669 0 9.19956 0H6.80044C6.55331 0 6.41225 0.313588 6.56052 0.533333L7.76009 2.31111Z"
            fill="#4C5057"
          />
          <path
            d="M7.76009 13.6889C7.88004 13.5111 8.11996 13.5111 8.23991 13.6889L9.43948 15.4667C9.58775 15.6864 9.44669 16 9.19956 16H6.80044C6.55331 16 6.41225 15.6864 6.56052 15.4667L7.76009 13.6889Z"
            fill="#4C5057"
          />
        </ColorSVGIconBase>
      );
    case 'gray400':
      return (
        <ColorSVGIconBase {...props}>
          <path
            d="M7.40022 0.666667C7.40022 0.298477 7.66875 0 8 0C8.33125 0 8.59978 0.298477 8.59978 0.666667V15.3333C8.59978 15.7015 8.33125 16 8 16C7.66875 16 7.40022 15.7015 7.40022 15.3333V0.666667Z"
            fill="#C7CBD1"
          />
          <path
            d="M7.76009 2.31111C7.88004 2.48889 8.11996 2.48889 8.23991 2.31111L9.43948 0.533333C9.58775 0.313588 9.44669 0 9.19956 0H6.80044C6.55331 0 6.41225 0.313588 6.56052 0.533333L7.76009 2.31111Z"
            fill="#C7CBD1"
          />
          <path
            d="M7.76009 13.6889C7.88004 13.5111 8.11996 13.5111 8.23991 13.6889L9.43948 15.4667C9.58775 15.6864 9.44669 16 9.19956 16H6.80044C6.55331 16 6.41225 15.6864 6.56052 15.4667L7.76009 13.6889Z"
            fill="#C7CBD1"
          />
        </ColorSVGIconBase>
      );
    case 'orange800':
      return (
        <ColorSVGIconBase {...props}>
          <path
            d="M7.40022 0.666667C7.40022 0.298477 7.66875 0 8 0C8.33125 0 8.59978 0.298477 8.59978 0.666667V15.3333C8.59978 15.7015 8.33125 16 8 16C7.66875 16 7.40022 15.7015 7.40022 15.3333V0.666667Z"
            fill="#FF600C"
          />
          <path
            d="M7.76009 2.31111C7.88004 2.48889 8.11996 2.48889 8.23991 2.31111L9.43948 0.533333C9.58775 0.313588 9.44669 0 9.19956 0H6.80044C6.55331 0 6.41225 0.313588 6.56052 0.533333L7.76009 2.31111Z"
            fill="#FF600C"
          />
          <path
            d="M7.76009 13.6889C7.88004 13.5111 8.11996 13.5111 8.23991 13.6889L9.43948 15.4667C9.58775 15.6864 9.44669 16 9.19956 16H6.80044C6.55331 16 6.41225 15.6864 6.56052 15.4667L7.76009 13.6889Z"
            fill="#FF600C"
          />
        </ColorSVGIconBase>
      );
    case 'white':
      return (
        <ColorSVGIconBase {...props}>
          <path
            d="M7.40022 0.666667C7.40022 0.298477 7.66875 0 8 0C8.33125 0 8.59978 0.298477 8.59978 0.666667V15.3333C8.59978 15.7015 8.33125 16 8 16C7.66875 16 7.40022 15.7015 7.40022 15.3333V0.666667Z"
            fill="white"
          />
          <path
            d="M7.76009 2.31111C7.88004 2.48889 8.11996 2.48889 8.23991 2.31111L9.43948 0.533333C9.58775 0.313588 9.44669 0 9.19956 0H6.80044C6.55331 0 6.41225 0.313588 6.56052 0.533333L7.76009 2.31111Z"
            fill="white"
          />
          <path
            d="M7.76009 13.6889C7.88004 13.5111 8.11996 13.5111 8.23991 13.6889L9.43948 15.4667C9.58775 15.6864 9.44669 16 9.19956 16H6.80044C6.55331 16 6.41225 15.6864 6.56052 15.4667L7.76009 13.6889Z"
            fill="white"
          />
        </ColorSVGIconBase>
      );
    default:
      return null;
  }
};
const ProfileIcon: React.FC<ColorSVGIconProps> = ({ color = 'gray700', ...props }) => {
  switch (color) {
    case 'gray700':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="7" fill="#D8DBDF" />
          <path
            d="M10.1 6.5C10.1 7.60457 9.1598 8.5 8 8.5C6.8402 8.5 5.9 7.60457 5.9 6.5C5.9 5.39543 6.8402 4.5 8 4.5C9.1598 4.5 10.1 5.39543 10.1 6.5Z"
            fill="#4C5057"
          />
          <path
            d="M8 8.5C6.43813 8.5 5.1152 9.25671 4.66479 10.5184C4.47911 11.0386 4.94772 11.5 5.5 11.5H10.5C11.0523 11.5 11.5209 11.0386 11.3352 10.5184C10.8848 9.25671 9.56187 8.5 8 8.5Z"
            fill="#4C5057"
          />
        </ColorSVGIconBase>
      );
    case 'gray400':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="7" fill="#E8EAED" />
          <path
            d="M10.1 6.5C10.1 7.60457 9.1598 8.5 8 8.5C6.8402 8.5 5.9 7.60457 5.9 6.5C5.9 5.39543 6.8402 4.5 8 4.5C9.1598 4.5 10.1 5.39543 10.1 6.5Z"
            fill="#898D94"
          />
          <path
            d="M8 8.5C6.43813 8.5 5.1152 9.25671 4.66479 10.5184C4.47911 11.0386 4.94772 11.5 5.5 11.5H10.5C11.0523 11.5 11.5209 11.0386 11.3352 10.5184C10.8848 9.25671 9.56187 8.5 8 8.5Z"
            fill="#898D94"
          />
        </ColorSVGIconBase>
      );
    case 'orange800':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="7" fill="#FFD8C2" />
          <path
            d="M10.1 6.5C10.1 7.60457 9.1598 8.5 8 8.5C6.8402 8.5 5.9 7.60457 5.9 6.5C5.9 5.39543 6.8402 4.5 8 4.5C9.1598 4.5 10.1 5.39543 10.1 6.5Z"
            fill="#FF600C"
          />
          <path
            d="M8 8.5C6.43813 8.5 5.1152 9.25671 4.66479 10.5184C4.47911 11.0386 4.94772 11.5 5.5 11.5H10.5C11.0523 11.5 11.5209 11.0386 11.3352 10.5184C10.8848 9.25671 9.56187 8.5 8 8.5Z"
            fill="#FF600C"
          />
        </ColorSVGIconBase>
      );
    case 'white':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="7" fill="white" />
          <path
            d="M10.1 6.5C10.1 7.60457 9.1598 8.5 8 8.5C6.8402 8.5 5.9 7.60457 5.9 6.5C5.9 5.39543 6.8402 4.5 8 4.5C9.1598 4.5 10.1 5.39543 10.1 6.5Z"
            fill="#E8EAED"
          />
          <path
            d="M8 8.5C6.43813 8.5 5.1152 9.25671 4.66479 10.5184C4.47911 11.0386 4.94772 11.5 5.5 11.5H10.5C11.0523 11.5 11.5209 11.0386 11.3352 10.5184C10.8848 9.25671 9.56187 8.5 8 8.5Z"
            fill="#E8EAED"
          />
        </ColorSVGIconBase>
      );
    default:
      return null;
  }
};

const ArrowIcon: React.FC<ColorSVGIconProps> = ({ color = 'gray700', rotate = 0, ...props }) => {
  let fillColor;
  switch (color) {
    case 'gray700':
      fillColor = '#4C5057';
      break;
    case 'gray400':
      fillColor = '#C7CBD1';
      break;
    case 'orange800':
      fillColor = '#FF600C';
      break;
    case 'white':
      fillColor = 'white';
      break;
    default:
      fillColor = '#4C5057';
  }

  return (
    <ColorSVGIconBase rotate={rotate} {...props}>
      <path
        d="M11 5.0364V10.9636C11 11.8406 9.90894 12.3197 9.19029 11.7583L5.39701 8.79467C4.86767 8.38111 4.86766 7.61889 5.397 7.20533L9.19029 4.24174C9.90894 3.68028 11 4.15938 11 5.0364Z"
        fill={fillColor}
      />
    </ColorSVGIconBase>
  );
};

const TooltipIcon: React.FC<ColorSVGIconProps> = ({ color = 'gray700', rotate = 0, ...props }) => {
  switch (color) {
    case 'gray700':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="7" fill="#D8DBDF" />
          <path
            d="M6.03039 5.70404C6.31596 5.08618 7.03403 4.7002 7.98877 4.7002C8.61701 4.7002 9.14544 4.87742 9.51632 5.18314C9.88683 5.48855 10.1004 5.92228 10.1004 6.43791C10.1004 6.78751 10.013 7.08171 9.84751 7.33493C9.68183 7.58846 9.43693 7.80193 9.12187 7.98818C8.81773 8.16336 8.62663 8.30726 8.50702 8.46106C8.38746 8.61481 8.34007 8.77744 8.32105 8.98772L8.3203 8.99396C8.29717 9.14226 8.24725 9.26073 8.16603 9.34245C8.08419 9.4248 7.9687 9.47168 7.81219 9.47168C7.63747 9.47168 7.50358 9.41005 7.41357 9.30899C7.32363 9.208 7.27586 9.06583 7.27586 8.90088V8.81325C7.27586 8.53064 7.34694 8.29655 7.4896 8.09068C7.63243 7.88458 7.84836 7.70552 8.13765 7.53543L8.13883 7.53472C8.42652 7.36193 8.62095 7.21115 8.74337 7.047C8.86649 6.88189 8.91621 6.70408 8.91621 6.47942C8.91621 6.22281 8.8152 6.00512 8.6421 5.85208C8.46936 5.69935 8.22518 5.61118 7.93766 5.61118C7.67831 5.61118 7.46127 5.67496 7.28996 5.79825C7.11842 5.9217 6.99344 6.10434 6.91811 6.34032L6.91773 6.34137C6.86746 6.4813 6.80064 6.57831 6.71596 6.64065C6.63079 6.70335 6.52621 6.73197 6.39955 6.73197C6.2406 6.73197 6.11583 6.68387 6.03112 6.5998C5.94677 6.51607 5.90039 6.39496 5.90039 6.24419C5.90039 6.04987 5.94115 5.88277 6.02724 5.71085L6.03039 5.70404Z"
            fill="#4C5057"
          />
          <path
            d="M7.30039 10.7983C7.30039 10.4631 7.56918 10.2002 7.90222 10.2002C8.2358 10.2002 8.50039 10.4636 8.50039 10.7983C8.50039 11.1325 8.23635 11.4002 7.90222 11.4002C7.56862 11.4002 7.30039 11.133 7.30039 10.7983Z"
            fill="#4C5057"
          />
        </ColorSVGIconBase>
      );
    case 'gray400':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="7" fill="#E8EAED" />
          <path
            d="M6.03039 5.70404C6.31596 5.08618 7.03403 4.7002 7.98877 4.7002C8.61701 4.7002 9.14544 4.87742 9.51632 5.18314C9.88683 5.48855 10.1004 5.92228 10.1004 6.43791C10.1004 6.78751 10.013 7.08171 9.84751 7.33493C9.68183 7.58846 9.43693 7.80193 9.12187 7.98818C8.81773 8.16336 8.62663 8.30726 8.50702 8.46106C8.38746 8.61481 8.34007 8.77744 8.32105 8.98772L8.3203 8.99396C8.29717 9.14226 8.24725 9.26073 8.16603 9.34245C8.08419 9.4248 7.9687 9.47168 7.81219 9.47168C7.63747 9.47168 7.50358 9.41005 7.41357 9.30899C7.32363 9.208 7.27586 9.06583 7.27586 8.90088V8.81325C7.27586 8.53064 7.34694 8.29655 7.4896 8.09068C7.63243 7.88458 7.84836 7.70552 8.13765 7.53543L8.13883 7.53472C8.42652 7.36193 8.62095 7.21115 8.74337 7.047C8.86649 6.88189 8.91621 6.70408 8.91621 6.47942C8.91621 6.22281 8.8152 6.00512 8.6421 5.85208C8.46936 5.69935 8.22518 5.61118 7.93766 5.61118C7.67831 5.61118 7.46127 5.67496 7.28996 5.79825C7.11842 5.9217 6.99344 6.10434 6.91811 6.34032L6.91773 6.34137C6.86746 6.4813 6.80064 6.57831 6.71596 6.64065C6.63079 6.70335 6.52621 6.73197 6.39955 6.73197C6.2406 6.73197 6.11583 6.68387 6.03112 6.5998C5.94677 6.51607 5.90039 6.39496 5.90039 6.24419C5.90039 6.04987 5.94115 5.88277 6.02724 5.71085L6.03039 5.70404Z"
            fill="#C7CBD1"
          />
          <path
            d="M7.30039 10.7983C7.30039 10.4631 7.56918 10.2002 7.90222 10.2002C8.2358 10.2002 8.50039 10.4636 8.50039 10.7983C8.50039 11.1325 8.23635 11.4002 7.90222 11.4002C7.56862 11.4002 7.30039 11.133 7.30039 10.7983Z"
            fill="#C7CBD1"
          />
        </ColorSVGIconBase>
      );
    case 'orange800':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="7" fill="#FFD8C2" />
          <path
            d="M6.03039 5.70404C6.31596 5.08618 7.03403 4.7002 7.98877 4.7002C8.61701 4.7002 9.14544 4.87742 9.51632 5.18314C9.88683 5.48855 10.1004 5.92228 10.1004 6.43791C10.1004 6.78751 10.013 7.08171 9.84751 7.33493C9.68183 7.58846 9.43693 7.80193 9.12187 7.98818C8.81773 8.16336 8.62663 8.30726 8.50702 8.46106C8.38746 8.61481 8.34007 8.77744 8.32105 8.98772L8.3203 8.99396C8.29717 9.14226 8.24725 9.26073 8.16603 9.34245C8.08419 9.4248 7.9687 9.47168 7.81219 9.47168C7.63747 9.47168 7.50358 9.41005 7.41357 9.30899C7.32363 9.208 7.27586 9.06583 7.27586 8.90088V8.81325C7.27586 8.53064 7.34694 8.29655 7.4896 8.09068C7.63243 7.88458 7.84836 7.70552 8.13765 7.53543L8.13883 7.53472C8.42652 7.36193 8.62095 7.21115 8.74337 7.047C8.86649 6.88189 8.91621 6.70408 8.91621 6.47942C8.91621 6.22281 8.8152 6.00512 8.6421 5.85208C8.46936 5.69935 8.22518 5.61118 7.93766 5.61118C7.67831 5.61118 7.46127 5.67496 7.28996 5.79825C7.11842 5.9217 6.99344 6.10434 6.91811 6.34032L6.91773 6.34137C6.86746 6.4813 6.80064 6.57831 6.71596 6.64065C6.63079 6.70335 6.52621 6.73197 6.39955 6.73197C6.2406 6.73197 6.11583 6.68387 6.03112 6.5998C5.94677 6.51607 5.90039 6.39496 5.90039 6.24419C5.90039 6.04987 5.94115 5.88277 6.02724 5.71085L6.03039 5.70404Z"
            fill="#FF600C"
          />
          <path
            d="M7.30039 10.7983C7.30039 10.4631 7.56918 10.2002 7.90222 10.2002C8.2358 10.2002 8.50039 10.4636 8.50039 10.7983C8.50039 11.1325 8.23635 11.4002 7.90222 11.4002C7.56862 11.4002 7.30039 11.133 7.30039 10.7983Z"
            fill="#FF600C"
          />
        </ColorSVGIconBase>
      );
    case 'white':
      return (
        <ColorSVGIconBase {...props}>
          <circle cx="8" cy="8" r="7" fill="white" />
          <path
            d="M6.03039 5.70404C6.31596 5.08618 7.03403 4.7002 7.98877 4.7002C8.61701 4.7002 9.14544 4.87742 9.51632 5.18314C9.88683 5.48855 10.1004 5.92228 10.1004 6.43791C10.1004 6.78751 10.013 7.08171 9.84751 7.33493C9.68183 7.58846 9.43693 7.80193 9.12187 7.98818C8.81773 8.16336 8.62663 8.30726 8.50702 8.46106C8.38746 8.61481 8.34007 8.77744 8.32105 8.98772L8.3203 8.99396C8.29717 9.14226 8.24725 9.26073 8.16603 9.34245C8.08419 9.4248 7.9687 9.47168 7.81219 9.47168C7.63747 9.47168 7.50358 9.41005 7.41357 9.30899C7.32363 9.208 7.27586 9.06583 7.27586 8.90088V8.81325C7.27586 8.53064 7.34694 8.29655 7.4896 8.09068C7.63243 7.88458 7.84836 7.70552 8.13765 7.53543L8.13883 7.53472C8.42652 7.36193 8.62095 7.21115 8.74337 7.047C8.86649 6.88189 8.91621 6.70408 8.91621 6.47942C8.91621 6.22281 8.8152 6.00512 8.6421 5.85208C8.46936 5.69935 8.22518 5.61118 7.93766 5.61118C7.67831 5.61118 7.46127 5.67496 7.28996 5.79825C7.11842 5.9217 6.99344 6.10434 6.91811 6.34032L6.91773 6.34137C6.86746 6.4813 6.80064 6.57831 6.71596 6.64065C6.63079 6.70335 6.52621 6.73197 6.39955 6.73197C6.2406 6.73197 6.11583 6.68387 6.03112 6.5998C5.94677 6.51607 5.90039 6.39496 5.90039 6.24419C5.90039 6.04987 5.94115 5.88277 6.02724 5.71085L6.03039 5.70404Z"
            fill="#E8EAED"
          />
          <path
            d="M7.30039 10.7983C7.30039 10.4631 7.56918 10.2002 7.90222 10.2002C8.2358 10.2002 8.50039 10.4636 8.50039 10.7983C8.50039 11.1325 8.23635 11.4002 7.90222 11.4002C7.56862 11.4002 7.30039 11.133 7.30039 10.7983Z"
            fill="#E8EAED"
          />
        </ColorSVGIconBase>
      );
    default:
      return null;
  }
};

const TooltipBoldIcon: React.FC<ColorSVGIconProps> = ({ color = 'gray700', rotate = 0, ...props }) => {
  switch (color) {
    case 'gray700':
      return (
        <ColorSVGIconBase {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8.03799 4.3999C7.05958 4.3999 6.29634 4.79648 5.98883 5.46179L5.987 5.46576C5.89411 5.65126 5.84961 5.83367 5.84961 6.0439C5.84961 6.21537 5.90279 6.36417 6.0099 6.47048C6.11731 6.57709 6.2696 6.63168 6.44876 6.63168C6.59081 6.63168 6.71788 6.59936 6.82447 6.52089C6.92997 6.44322 7.00657 6.32683 7.06129 6.17423L7.0626 6.17044C7.1326 5.95114 7.24641 5.78793 7.39759 5.67912C7.54868 5.57038 7.74418 5.51089 7.98688 5.51089C8.25413 5.51089 8.4735 5.59268 8.62508 5.7267C8.7761 5.86023 8.86543 6.05035 8.86543 6.27913C8.86543 6.48667 8.82025 6.64233 8.71242 6.78692C8.60199 6.935 8.42135 7.07766 8.13656 7.2487L8.13538 7.24941C7.83921 7.42355 7.61075 7.61102 7.45662 7.83343C7.3013 8.05756 7.22508 8.31194 7.22508 8.61295V8.70059C7.22508 8.88422 7.27835 9.05196 7.38811 9.1752C7.49929 9.30004 7.66164 9.37139 7.86141 9.37139C8.03918 9.37139 8.18209 9.31738 8.28617 9.21265C8.38772 9.11048 8.44367 8.9687 8.4686 8.80733L8.46974 8.79789C8.48761 8.59856 8.53097 8.45617 8.63518 8.32216C8.74244 8.18422 8.91952 8.04817 9.22116 7.87445L9.2224 7.87373C9.54638 7.6822 9.80402 7.4593 9.98044 7.18934C10.1574 6.91858 10.2496 6.60506 10.2496 6.23762C10.2496 5.69203 10.0226 5.23004 9.62915 4.90569C9.23681 4.58228 8.68439 4.3999 8.03799 4.3999ZM7.8558 10.1245C7.4395 10.1245 7.10352 10.4531 7.10352 10.8722C7.10352 11.2905 7.4388 11.6245 7.8558 11.6245C8.27347 11.6245 8.60352 11.2898 8.60352 10.8722C8.60352 10.4538 8.27277 10.1245 7.8558 10.1245Z"
            fill="#4C5057"
          />
        </ColorSVGIconBase>
      );
    case 'gray400':
      return (
        <ColorSVGIconBase {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8.03799 4.3999C7.05958 4.3999 6.29634 4.79648 5.98883 5.46179L5.987 5.46576C5.89411 5.65126 5.84961 5.83367 5.84961 6.0439C5.84961 6.21537 5.90279 6.36417 6.0099 6.47048C6.11731 6.57709 6.2696 6.63168 6.44876 6.63168C6.59081 6.63168 6.71788 6.59936 6.82447 6.52089C6.92997 6.44322 7.00657 6.32683 7.06129 6.17423L7.0626 6.17044C7.1326 5.95114 7.24641 5.78793 7.39759 5.67912C7.54868 5.57038 7.74418 5.51089 7.98688 5.51089C8.25413 5.51089 8.4735 5.59268 8.62508 5.7267C8.7761 5.86023 8.86543 6.05035 8.86543 6.27913C8.86543 6.48667 8.82025 6.64233 8.71242 6.78692C8.60199 6.935 8.42135 7.07766 8.13656 7.2487L8.13538 7.24941C7.83921 7.42355 7.61075 7.61102 7.45662 7.83343C7.3013 8.05756 7.22508 8.31194 7.22508 8.61295V8.70059C7.22508 8.88422 7.27835 9.05196 7.38811 9.1752C7.49929 9.30004 7.66164 9.37139 7.86141 9.37139C8.03918 9.37139 8.18209 9.31738 8.28617 9.21265C8.38772 9.11048 8.44367 8.9687 8.4686 8.80733L8.46974 8.79789C8.48761 8.59856 8.53097 8.45617 8.63518 8.32216C8.74244 8.18422 8.91952 8.04817 9.22116 7.87445L9.2224 7.87373C9.54638 7.6822 9.80402 7.4593 9.98044 7.18934C10.1574 6.91858 10.2496 6.60506 10.2496 6.23762C10.2496 5.69203 10.0226 5.23004 9.62915 4.90569C9.23681 4.58228 8.68439 4.3999 8.03799 4.3999ZM7.8558 10.1245C7.4395 10.1245 7.10352 10.4531 7.10352 10.8722C7.10352 11.2905 7.4388 11.6245 7.8558 11.6245C8.27347 11.6245 8.60352 11.2898 8.60352 10.8722C8.60352 10.4538 8.27277 10.1245 7.8558 10.1245Z"
            fill="#C7CBD1"
          />
        </ColorSVGIconBase>
      );
    case 'orange800':
      return (
        <ColorSVGIconBase {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8.03799 4.3999C7.05958 4.3999 6.29634 4.79648 5.98883 5.46179L5.987 5.46576C5.89411 5.65126 5.84961 5.83367 5.84961 6.0439C5.84961 6.21537 5.90279 6.36417 6.0099 6.47048C6.11731 6.57709 6.2696 6.63168 6.44876 6.63168C6.59081 6.63168 6.71788 6.59936 6.82447 6.52089C6.92997 6.44322 7.00657 6.32683 7.06129 6.17423L7.0626 6.17044C7.1326 5.95114 7.24641 5.78793 7.39759 5.67912C7.54868 5.57038 7.74418 5.51089 7.98688 5.51089C8.25413 5.51089 8.4735 5.59268 8.62508 5.7267C8.7761 5.86023 8.86543 6.05035 8.86543 6.27913C8.86543 6.48667 8.82025 6.64233 8.71242 6.78692C8.60199 6.935 8.42135 7.07766 8.13656 7.2487L8.13538 7.24941C7.83921 7.42355 7.61075 7.61102 7.45662 7.83343C7.3013 8.05756 7.22508 8.31194 7.22508 8.61295V8.70059C7.22508 8.88422 7.27835 9.05196 7.38811 9.1752C7.49929 9.30004 7.66164 9.37139 7.86141 9.37139C8.03918 9.37139 8.18209 9.31738 8.28617 9.21265C8.38772 9.11048 8.44367 8.9687 8.4686 8.80733L8.46974 8.79789C8.48761 8.59856 8.53097 8.45617 8.63518 8.32216C8.74244 8.18422 8.91952 8.04817 9.22116 7.87445L9.2224 7.87373C9.54638 7.6822 9.80402 7.4593 9.98044 7.18934C10.1574 6.91858 10.2496 6.60506 10.2496 6.23762C10.2496 5.69203 10.0226 5.23004 9.62915 4.90569C9.23681 4.58228 8.68439 4.3999 8.03799 4.3999ZM7.8558 10.1245C7.4395 10.1245 7.10352 10.4531 7.10352 10.8722C7.10352 11.2905 7.4388 11.6245 7.8558 11.6245C8.27347 11.6245 8.60352 11.2898 8.60352 10.8722C8.60352 10.4538 8.27277 10.1245 7.8558 10.1245Z"
            fill="#FF600C"
          />
        </ColorSVGIconBase>
      );
    case 'white':
      return (
        <ColorSVGIconBase {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8.03799 4.3999C7.05958 4.3999 6.29634 4.79648 5.98883 5.46179L5.987 5.46576C5.89411 5.65126 5.84961 5.83367 5.84961 6.0439C5.84961 6.21537 5.90279 6.36417 6.0099 6.47048C6.11731 6.57709 6.2696 6.63168 6.44876 6.63168C6.59081 6.63168 6.71788 6.59936 6.82447 6.52089C6.92997 6.44322 7.00657 6.32683 7.06129 6.17423L7.0626 6.17044C7.1326 5.95114 7.24641 5.78793 7.39759 5.67912C7.54868 5.57038 7.74418 5.51089 7.98688 5.51089C8.25413 5.51089 8.4735 5.59268 8.62508 5.7267C8.7761 5.86023 8.86543 6.05035 8.86543 6.27913C8.86543 6.48667 8.82025 6.64233 8.71242 6.78692C8.60199 6.935 8.42135 7.07766 8.13656 7.2487L8.13538 7.24941C7.83921 7.42355 7.61075 7.61102 7.45662 7.83343C7.3013 8.05756 7.22508 8.31194 7.22508 8.61295V8.70059C7.22508 8.88422 7.27835 9.05196 7.38811 9.1752C7.49929 9.30004 7.66164 9.37139 7.86141 9.37139C8.03918 9.37139 8.18209 9.31738 8.28617 9.21265C8.38772 9.11048 8.44367 8.9687 8.4686 8.80733L8.46974 8.79789C8.48761 8.59856 8.53097 8.45617 8.63518 8.32216C8.74244 8.18422 8.91952 8.04817 9.22116 7.87445L9.2224 7.87373C9.54638 7.6822 9.80402 7.4593 9.98044 7.18934C10.1574 6.91858 10.2496 6.60506 10.2496 6.23762C10.2496 5.69203 10.0226 5.23004 9.62915 4.90569C9.23681 4.58228 8.68439 4.3999 8.03799 4.3999ZM7.8558 10.1245C7.4395 10.1245 7.10352 10.4531 7.10352 10.8722C7.10352 11.2905 7.4388 11.6245 7.8558 11.6245C8.27347 11.6245 8.60352 11.2898 8.60352 10.8722C8.60352 10.4538 8.27277 10.1245 7.8558 10.1245Z"
            fill="white"
          />
        </ColorSVGIconBase>
      );
    default:
      return null;
  }
};

const ColorSVGIcon = {
  New: NewIcon,
  Close: CloseIcon,
  Pin: PinIcon,
  Arrow: ArrowIcon,
  Profile: ProfileIcon,
  Tooltip: TooltipIcon,
  ToolipBold: TooltipBoldIcon,
};

export default ColorSVGIcon;
