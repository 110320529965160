import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Blank } from 'src/components/common';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { LayeredTabs, Tab } from 'src/components/common/LayeredTabs';
import { Typography } from 'src/components/common/Typography';
import IBLayout from 'src/components/ib/IBLayout';
import TeacherEE_EssayList from 'src/components/ib/TeacherEE_EssayList';
import TeacherEE_ProposalList from 'src/components/ib/TeacherEE_ProposalList';
import TeacherEE_RRSList from 'src/components/ib/TeacherEE_RRSList';
import { useIBGetById } from 'src/container/ib-project-get-student';
import { useLanguage } from 'src/hooks/useLanguage';
import type { EEProject, LocationState } from '../../student/EE/EEMainPage';
import { EERppfPage } from './EERppfPage';

export const EEMainPage = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useLanguage();
  const { push } = useHistory();
  const location = useLocation<LocationState>();
  const { data, klassNum, refetch } = useIBGetById(Number(id), 'EE');
  const [filter, setFilter] = useState<EEProject>(location.state?.type || 'PROPOSAL');
  const [isIbModalOpen, setIsIbModalOpen] = useState<boolean>(false);

  const proposalData = data
    ? {
        ...data,
        proposals: data.proposals?.filter((proposal) => proposal.status !== 'PENDING'),
      }
    : null;

  const studentData =
    klassNum && data
      ? {
          klassNum,
          name: data.leader.name,
        }
      : { klassNum: '', name: '' };

  const acceptProposalTitle =
    data?.status === 'IN_PROGRESS' || data?.status === 'WAIT_COMPLETE' || data?.status === 'COMPLETE'
      ? data.proposals?.find((proposal) => proposal.status === 'ACCEPT')?.researchTopic
      : `${data?.leader.name}의 EE 제안서`;

  if (!proposalData) {
    return <Blank />;
  }

  if (data === undefined) {
    return <Blank />;
  }

  return (
    <>
      <div className="col-span-6 h-screen w-full">
        <IBLayout
          className="bg-gray-50"
          topBgColor="bg-white"
          topContent={
            <div>
              <div className="w-full pb-6 pt-16">
                <div className="flex flex-col items-start gap-3">
                  <div className="flex w-full flex-row items-center justify-between">
                    <div className="flex flex-row items-center gap-1">
                      <BadgeV2 color="dark_green" size={24} type="solid_strong" className="self-start">
                        EE
                      </BadgeV2>
                      <BadgeV2
                        color={
                          data.status === 'IN_PROGRESS' ||
                          data.status === 'WAIT_COMPLETE' ||
                          data.status === 'WAIT_PLAN_APPROVE'
                            ? 'blue'
                            : data.status === 'REJECT_MENTOR' ||
                              data.status === 'REJECT_PLAN' ||
                              data.status === 'REJECT_COMPLETE'
                            ? 'red'
                            : data.status === 'COMPLETE'
                            ? 'green'
                            : 'gray'
                        }
                        size={24}
                        type="line"
                      >
                        {data.status === 'WAIT_PLAN_APPROVE' ? '보완완료' : t(`IBStatus.${data.status}`)}
                      </BadgeV2>
                    </div>
                    <Breadcrumb
                      data={{
                        진행상태: '/teacher/project',
                        EE: `/teacher/ib/ee/${id}`,
                      }}
                    />
                  </div>
                  <div className="flex w-full items-start justify-between">
                    <Typography variant="heading" className="max-w-[698px]">
                      {acceptProposalTitle ? acceptProposalTitle : `${data.leader?.name}의 EE 제안서`}
                    </Typography>
                    <div className="flex items-center space-x-2">
                      <div className="rounded-lg border border-orange-100 bg-orange-50 px-4 py-2 text-16 font-semibold text-orange-800">
                        {klassNum} · {proposalData.leader.name}
                      </div>
                      {proposalData.activityFrequency && (
                        <div className="rounded-lg bg-orange-50 px-4 py-3 text-13 text-gray-300">
                          <span className="text-orange-800">알림</span>{' '}
                          <span className="ml-2 text-gray-700">{proposalData.activityFrequency}</span>
                          {/* TODO: 테스트 필요 / 기본 테스트: 활동 완료 승인 요청이 있습니다. */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex h-full items-center">
                <LayeredTabs.OneDepth onChange={(selectedType: EEProject) => setFilter(selectedType)} value={filter}>
                  <Tab value="PROPOSAL">
                    <p>제안서</p>
                  </Tab>
                  <Tab value="ESSAY">
                    <p>에세이</p>
                  </Tab>
                  <Tab value="RPPF">
                    <p>RPPF</p>
                  </Tab>
                  <Tab value="RRS">
                    <p>RRS</p>
                  </Tab>
                </LayeredTabs.OneDepth>
              </div>
            </div>
          }
          hasContour={false}
          bottomContent={
            <div className="mt-6 h-[664px] space-y-6 rounded-xl bg-white py-6">
              {filter === 'PROPOSAL' && <TeacherEE_ProposalList data={proposalData} refetch={refetch} />}
              {filter === 'ESSAY' && (
                <TeacherEE_EssayList data={proposalData} studentData={studentData} refetch={refetch} />
              )}
              {filter === 'RPPF' && (
                <EERppfPage
                  id={Number(id)}
                  data={proposalData}
                  refetch={refetch}
                  studentData={studentData}
                  title={acceptProposalTitle || `${data?.leader?.name}의 EE 제안서`}
                />
              )}
              {filter === 'RRS' && (
                <TeacherEE_RRSList
                  id={Number(id)}
                  title={acceptProposalTitle || `${data?.leader?.name}의 EE 제안서`}
                  studentData={studentData}
                  data={proposalData}
                />
              )}
            </div>
          }
        />
      </div>

      {/* RPPF IB 제출정보 기입 전 Modal */}
      {/* {isIbModalOpen && (
        <RppfIbSubmitInformPopup
          ibId={Number(id)}
          modalOpen={isIbModalOpen}
          setModalClose={() => setIsIbModalOpen(false)}
        />
      )} */}
    </>
  );
};
