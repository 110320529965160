import clsx from 'clsx';

export interface SolidSVGIconProps {
  color?: 'gray700' | 'gray400' | 'orange800' | 'white';
  size?: number;
  rotate?: number;
  weight?: 'normal' | 'bold';
  onClick?: () => void;
  className?: string;
}

// 공통 SVG 설정 함수
const SVGIconBase = ({
  size = 16,
  rotate = 0,
  className,
  onClick,
  children,
}: SolidSVGIconProps & { children: React.ReactNode }) => {
  return (
    <svg
      width={size}
      height={size}
      onClick={onClick}
      className={clsx(className, {
        'rotate-45': rotate === 45,
        'rotate-90': rotate === 90,
        'rotate-180': rotate === 180,
      })}
      viewBox="0 0 16 16"
    >
      {children}
    </svg>
  );
};

const HandleIcon = ({ color = 'gray700', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: '#4C5057',
    gray400: '#C7CBD1',
    orange800: '#FF600C',
    white: '#FFFFFF',
  };

  return (
    <SVGIconBase {...props}>
      <path
        d="M7.33464 2.66634C7.33464 3.40272 6.73768 3.99967 6.0013 3.99967C5.26492 3.99967 4.66797 3.40272 4.66797 2.66634C4.66797 1.92996 5.26492 1.33301 6.0013 1.33301C6.73768 1.33301 7.33464 1.92996 7.33464 2.66634Z"
        fill={colors[color]}
      />
      <path
        d="M7.33464 7.99972C7.33464 8.73609 6.73768 9.33305 6.0013 9.33305C5.26492 9.33305 4.66797 8.73609 4.66797 7.99972C4.66797 7.26334 5.26492 6.66638 6.0013 6.66638C6.73768 6.66638 7.33464 7.26334 7.33464 7.99972Z"
        fill={colors[color]}
      />
      <path
        d="M7.33464 13.3331C7.33464 14.0695 6.73768 14.6664 6.0013 14.6664C5.26492 14.6664 4.66797 14.0695 4.66797 13.3331C4.66797 12.5967 5.26492 11.9998 6.0013 11.9998C6.73768 11.9998 7.33464 12.5967 7.33464 13.3331Z"
        fill={colors[color]}
      />
      <path
        d="M12.0016 2.66638C12.0016 3.40276 11.4047 3.99972 10.6683 3.99972C9.93191 3.99972 9.33496 3.40276 9.33496 2.66638C9.33496 1.93 9.93191 1.33305 10.6683 1.33305C11.4047 1.33305 12.0016 1.93 12.0016 2.66638Z"
        fill={colors[color]}
      />
      <path
        d="M12.0016 7.99972C12.0016 8.73609 11.4047 9.33305 10.6683 9.33305C9.93191 9.33305 9.33496 8.73609 9.33496 7.99972C9.33496 7.26334 9.93191 6.66638 10.6683 6.66638C11.4047 6.66638 12.0016 7.26334 12.0016 7.99972Z"
        fill={colors[color]}
      />
      <path
        d="M12.0016 13.333C12.0016 14.0694 11.4047 14.6664 10.6683 14.6664C9.93191 14.6664 9.33496 14.0694 9.33496 13.333C9.33496 12.5967 9.93191 11.9997 10.6683 11.9997C11.4047 11.9997 12.0016 12.5967 12.0016 13.333Z"
        fill={colors[color]}
      />
    </SVGIconBase>
  );
};
const BellIcon = ({ color = 'gray700', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: '#D8DBDF',
      secondary: '#4C5057',
    },
    gray400: {
      primary: '#C7CBD1',
      secondary: '#C7CBD1',
    },
    orange800: {
      primary: '#FFBC99',
      secondary: '#FF600C',
    },
    white: {
      primary: 'white',
      secondary: 'white',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      <path
        d="M2.68892 11.5H13.3111C13.8546 11.5 14.1834 10.8981 13.8904 10.4394L12.6099 8.43468C12.6018 8.42203 12.5975 8.40733 12.5975 8.39231V6.10647C12.5975 3.56239 10.5391 1.5 8 1.5C5.46087 1.5 3.40249 3.56239 3.40249 6.10647V8.39231C3.40249 8.40733 3.3982 8.42203 3.39012 8.43468L2.10961 10.4394C1.81661 10.8981 2.14538 11.5 2.68892 11.5Z"
        fill={selectedColors.primary}
      />
      <path
        d="M8 14.45C9.10457 14.45 10 13.5557 10 12.4524V12.25H6V12.4524C6 13.5557 6.89543 14.45 8 14.45Z"
        fill={selectedColors.secondary}
      />
    </SVGIconBase>
  );
};

const HomeIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: weight === 'bold' ? '#4C5057' : '#D8DBDF',
      secondary: weight === 'bold' ? '#61656B' : '#4C5057',
    },
    gray400: {
      primary: weight === 'bold' ? '#C7CBD1' : '#E8EAED',
      secondary: weight === 'bold' ? '#C7CBD1' : '#C7CBD1',
    },
    orange800: {
      primary: weight === 'bold' ? '#FF600C' : '#FFBC99',
      secondary: weight === 'bold' ? '#FF600C' : '#FF600C',
    },
    white: {
      primary: 'white',
      secondary: weight === 'bold' ? '#E8EAED' : 'white',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      {weight === 'bold' ? (
        <path
          d="M8.81727 2.27048C8.33055 1.90984 7.66944 1.90984 7.18273 2.27048L2.94792 5.40833C2.35046 5.85102 2 6.55594 2 7.3028V13C2 13.5523 2.44772 14 3 14H6V9.90909C6 9.40701 6.44772 9 7 9H9C9.55228 9 10 9.40701 10 9.90909V14H13C13.5523 14 14 13.5523 14 13V7.3028C14 6.55594 13.6495 5.85102 13.0521 5.40833L8.81727 2.27048Z"
          fill={selectedColors.primary}
        />
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.18273 2.27048C7.66944 1.90984 8.33055 1.90984 8.81727 2.27048L13.0521 5.40833C13.6495 5.85102 14 6.55594 14 7.3028V12.5969C14 13.3631 13.3887 14 12.6154 14H3.38462C2.61131 14 2 13.3631 2 12.5969V7.3028C2 6.55594 2.35046 5.85102 2.94792 5.40833L7.18273 2.27048Z"
            fill={selectedColors.primary}
          />
          <path
            d="M5.75 9.90909C5.75 9.40701 6.25368 9 6.875 9H9.125C9.74632 9 10.25 9.40701 10.25 9.90909V14H5.75V9.90909Z"
            fill={selectedColors.secondary}
          />
        </>
      )}
    </SVGIconBase>
  );
};

const TalkIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: '#E8EAED',
      secondary: '#4C5057',
    },
    gray400: {
      primary: '#C7CBD1',
      secondary: 'white',
    },
    orange800: {
      primary: '#FFE8DB',
      secondary: '#FF600C',
    },
    white: {
      primary: 'white',
      secondary: '#E8EAED',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      {weight === 'bold' ? (
        <>
          <path
            d="M1.02649 13.0028L1.8477 10.5908C1.40173 9.79541 1.16804 8.90729 1.16733 8.0051C1.16733 4.69433 4.27015 2 8.08367 2C11.8972 2 15 4.69433 15 8.0051C15 11.3107 11.8983 13.9998 8.08367 13.9998C6.67682 14.0087 5.29713 13.6302 4.10981 12.9097L1.70903 13.6544C1.61402 13.6839 1.51233 13.6877 1.41521 13.6654C1.31808 13.6431 1.22929 13.5957 1.15866 13.5282C1.08802 13.4608 1.0383 13.376 1.01497 13.2833C0.99165 13.1906 0.995636 13.0935 1.02649 13.0028Z"
            fill={selectedColors.primary}
          />
          <path
            d="M5.97762 8.11608C5.97762 8.55122 5.62487 8.90397 5.18974 8.90397C4.7546 8.90397 4.40186 8.55122 4.40186 8.11608C4.40186 7.68094 4.7546 7.32819 5.18974 7.32819C5.62487 7.32819 5.97762 7.68094 5.97762 8.11608Z"
            fill={selectedColors.secondary}
          />
          <path
            d="M8.90407 8.11601C8.90407 8.55115 8.55132 8.9039 8.11619 8.9039C7.68105 8.9039 7.32831 8.55115 7.32831 8.11601C7.32831 7.68087 7.68105 7.32812 8.11619 7.32812C8.55132 7.32812 8.90407 7.68087 8.90407 8.11601Z"
            fill={selectedColors.secondary}
          />
          <path
            d="M11.8305 8.11601C11.8305 8.55115 11.4777 8.9039 11.0426 8.9039C10.6075 8.9039 10.2547 8.55115 10.2547 8.11601C10.2547 7.68087 10.6075 7.32812 11.0426 7.32812C11.4777 7.32812 11.8305 7.68087 11.8305 8.11601Z"
            fill={selectedColors.secondary}
          />
        </>
      ) : (
        <>
          <path
            d="M1.02649 13.0028L1.8477 10.5908C1.40173 9.79541 1.16804 8.90729 1.16733 8.0051C1.16733 4.69433 4.27015 2 8.08367 2C11.8972 2 15 4.69433 15 8.0051C15 11.3107 11.8983 13.9998 8.08367 13.9998C6.67682 14.0087 5.29713 13.6302 4.10981 12.9097L1.70903 13.6544C1.61402 13.6839 1.51233 13.6877 1.41521 13.6654C1.31808 13.6431 1.22929 13.5957 1.15866 13.5282C1.08802 13.4608 1.0383 13.376 1.01497 13.2833C0.99165 13.1906 0.995636 13.0935 1.02649 13.0028Z"
            fill={selectedColors.primary}
          />
          <path
            d="M5.75 8C5.75 8.41421 5.41421 8.75 5 8.75C4.58579 8.75 4.25 8.41421 4.25 8C4.25 7.58579 4.58579 7.25 5 7.25C5.41421 7.25 5.75 7.58579 5.75 8Z"
            fill={selectedColors.secondary}
          />
          <path
            d="M8.75 8C8.75 8.41421 8.41421 8.75 8 8.75C7.58579 8.75 7.25 8.41421 7.25 8C7.25 7.58579 7.58579 7.25 8 7.25C8.41421 7.25 8.75 7.58579 8.75 8Z"
            fill={selectedColors.secondary}
          />
          <path
            d="M11.75 8C11.75 8.41421 11.4142 8.75 11 8.75C10.5858 8.75 10.25 8.41421 10.25 8C10.25 7.58579 10.5858 7.25 11 7.25C11.4142 7.25 11.75 7.58579 11.75 8Z"
            fill={selectedColors.secondary}
          />
        </>
      )}
    </SVGIconBase>
  );
};

const InfoIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: '#D8DBDF',
      secondary: '#4C5057',
    },
    gray400: {
      primary: '#E8EAED',
      secondary: '#898D94',
    },
    orange800: {
      primary: '#FFD8C2',
      secondary: '#FF600C',
    },
    white: {
      primary: 'white',
      secondary: '#E8EAED',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      {weight === 'bold' ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM7.99977 6.09916C8.41386 6.09916 8.74955 5.76347 8.74955 5.34938C8.74955 4.9353 8.41386 4.59961 7.99977 4.59961C7.58569 4.59961 7.25 4.9353 7.25 5.34938C7.25 5.76347 7.58569 6.09916 7.99977 6.09916ZM8.75 7.82941C8.75 7.41532 8.41431 7.07963 8.00023 7.07963C7.58614 7.07963 7.25045 7.41532 7.25045 7.82941V10.8508C7.25045 11.2649 7.58614 11.6006 8.00023 11.6006C8.41431 11.6006 8.75 11.2649 8.75 10.8508V7.82941Z"
            fill={selectedColors.primary}
          />
        </>
      ) : (
        <>
          <circle cx="8" cy="8" r="7" fill={selectedColors.primary} />
          <path
            d="M8.60003 5.34982C8.60003 5.68109 8.33148 5.94964 8.00021 5.94964C7.66894 5.94964 7.40039 5.68109 7.40039 5.34982C7.40039 5.01855 7.66894 4.75 8.00021 4.75C8.33148 4.75 8.60003 5.01855 8.60003 5.34982Z"
            fill={selectedColors.secondary}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00057 7C8.33184 7 8.60039 7.26855 8.60039 7.59982V10.9002C8.60039 11.2315 8.33184 11.5 8.00057 11.5C7.6693 11.5 7.40075 11.2315 7.40075 10.9002V7.59982C7.40075 7.26855 7.6693 7 8.00057 7Z"
            fill={selectedColors.secondary}
          />
        </>
      )}
    </SVGIconBase>
  );
};

const MoreIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const isBold = weight === 'bold';

  const colors = {
    gray700: {
      primary: '#4C5057',
    },
    gray400: {
      primary: '#C7CBD1',
    },
    orange800: {
      primary: '#FF600C',
    },
    white: {
      primary: 'white',
    },
  };

  const selectedColors = colors[color];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isBold ? 12 : 10}
      height={2}
      viewBox="0 0 12 2"
      fill="none"
      {...props}
    >
      {isBold ? (
        <>
          <circle cx="1.5" cy="1" r="1" fill={selectedColors.primary} />
          <circle cx="6" cy="1" r="1" fill={selectedColors.primary} />
          <circle cx="10.5" cy="1" r="1" fill={selectedColors.primary} />
        </>
      ) : (
        <>
          <circle cx="1" cy="1" r="0.75" fill={selectedColors.primary} />
          <circle cx="5" cy="1" r="0.75" fill={selectedColors.primary} />
          <circle cx="9" cy="1" r="0.75" fill={selectedColors.primary} />
        </>
      )}
    </svg>
  );
};

const ProfileIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: '#4C5057',
    },
    gray400: {
      primary: '#C7CBD1',
    },
    orange800: {
      primary: '#FF600C',
    },
    white: {
      primary: 'white',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      <path
        d="M11 5.75C11 7.40685 9.65685 8.75 8 8.75C6.34315 8.75 5 7.40685 5 5.75C5 4.09315 6.34315 2.75 8 2.75C9.65685 2.75 11 4.09315 11 5.75Z"
        fill={selectedColors.primary}
      />
      <path
        d="M8 8.75C5.58625 8.75 3.57198 10.0783 3.10303 12.257C2.98682 12.797 3.44772 13.25 4 13.25H12C12.5523 13.25 13.0132 12.797 12.897 12.257C12.428 10.0783 10.4137 8.75 8 8.75Z"
        fill={selectedColors.primary}
      />
    </SVGIconBase>
  );
};

const BulletIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: '#4C5057',
    },
    gray400: {
      primary: '#C7CBD1',
    },
    orange800: {
      primary: '#FF600C',
    },
    white: {
      primary: 'white',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      <circle cx="8" cy="10" r="2" fill={selectedColors.primary} />
    </SVGIconBase>
  );
};

const SolidSVGIcon = {
  Bell: BellIcon,
  Home: HomeIcon,
  Talk: TalkIcon,
  More: MoreIcon,
  Profile: ProfileIcon,
  Handle: HandleIcon,
  Bullet: BulletIcon,
  Info: InfoIcon,
};

export default SolidSVGIcon;
