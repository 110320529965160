import { useRPPFFindAllRPPF } from 'src/generated/endpoint';

export const useRPPFGetByIBIdFindAll = (ibId: number) => {
  const { data: RPPF, isLoading, refetch } = useRPPFFindAllRPPF(ibId);
  const data = RPPF?.items;
  return {
    data,
    isLoading,
    refetch,
  };
};
