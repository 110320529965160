import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NODATA from 'src/assets/images/no-data.png';
import { Blank } from 'src/components/common';
import AlertV2 from 'src/components/common/AlertV2';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { TextareaV2 } from 'src/components/common/TextareaV2';
import { Typography } from 'src/components/common/Typography';
import FeedbackViewer from 'src/components/ib/FeedbackViewer';
import RppfIbSubmitInformPopup from 'src/components/ib/RppfIbSubmitInformPopup';
import ColorSVGIcon from 'src/components/icon/ColorSVGIcon';
import { PopupModal } from 'src/components/PopupModal';
import { useGetFeedbackBatchExist } from 'src/container/ib-feedback';
import { useIBApproveComplete } from 'src/container/ib-project';
import { useRPPFGetByIBIdFindAll } from 'src/container/ib-rppf-findAll';
import { useIBRPPFApproveStatus } from 'src/container/ib-rppf-update-edit-status';
import { useInterviewQNAGetByStudentId } from 'src/container/ib-student-interview';
import { useRPPFUpdateRPPFStatusReject } from 'src/generated/endpoint';
import { FeedbackReferenceTable, ResponseIBDto, ResponseIBProposalDto } from 'src/generated/model';
import { LocationState } from 'src/type/ib';

interface RppfListProps {
  id: number;
  data: ResponseIBDto;
  studentData: LocationState['student'];
  refetch: () => void;
  title: ResponseIBProposalDto['researchTopic'];
}

export const EERppfPage = ({ id, data: proposalData, studentData, refetch, title }: RppfListProps) => {
  const { push } = useHistory();
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  const [feedbackReference, setFeedbackReference] = useState<{
    referenceId: number;
    referenceTable: FeedbackReferenceTable;
  }>();
  const [localUnreadCounts, setLocalUnreadCounts] = useState<Record<string, number>>({});
  const [rejectModalOpen, setRejectModalOpen] = useState(false); // 제안서 보완 요청 Modal
  const [rejectReason, setRejectReason] = useState(''); // 제안서 보완 요청 피드백
  const [ibModalType, setIbModalType] = useState<'CREATE' | 'VIEW' | null>(null); // IB Modal 타입 관리
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const { data: rppfData = [] } = useRPPFGetByIBIdFindAll(id);
  const { data: interviewData = [] } = useInterviewQNAGetByStudentId(proposalData.leader.id, 'EE_RPPF');
  const interviewIdsString = interviewData.map((interview) => interview.qna.id).join(',');

  const mergedData = [
    ...rppfData.map((rppf) => ({ ...rppf, type: 'RPPF' })),
    ...interviewData
      .map((interview) => ({
        ...interview,
        type: 'interview',
      }))
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()),
  ];

  const { data: interviewFeedbacks } = useGetFeedbackBatchExist(
    {
      referenceIds: interviewIdsString,
      referenceTable: 'INTERVIEW',
    },
    { enabled: !!interviewIdsString },
  );

  const { data: rppfFeedbacks } = useGetFeedbackBatchExist(
    {
      referenceIds: String(rppfData[0]?.id),
      referenceTable: 'RPPF',
    },
    { enabled: !!rppfData[0]?.id },
  );

  const { mutate: rejectPlan, isLoading: rejectPlanLoading } = useRPPFUpdateRPPFStatusReject({
    mutation: {
      onSuccess: () => {
        setAlertMessage(`RPPF 보완을\n요청하였습니다`);
        setRejectModalOpen(!rejectModalOpen);
      },
    },
  });

  // RPPF 수정 승인 api 호출
  const { approveEditStatus } = useIBRPPFApproveStatus({
    onSuccess: () => {
      setAlertMessage(`RPPF 수정권한 요청이\n승인되었습니다`);
    },
    onError: (error) => {
      console.error('RPPF 수정권한 요청 승인 중 오류 발생:', error);
    },
  });

  const { approveIBProjectComplete } = useIBApproveComplete({
    onSuccess: () => {
      setAlertMessage(`완료를\n승인하였습니다`);
    },
    onError: (error) => {
      console.error('완료 승인 중 오류 발생:', error);
    },
  });

  // unreadCount를 0으로 업데이트하는 함수
  const markAsRead = (referenceId: number, referenceTable: FeedbackReferenceTable) => {
    const key = `${referenceTable}-${referenceId}`; // 문자열 키 생성
    setLocalUnreadCounts((prevCounts) => {
      const newCounts = { ...prevCounts };
      newCounts[key] = 0;
      return newCounts;
    });
  };

  const handleIbModalOpen = (type: 'CREATE' | 'VIEW') => {
    setIbModalType(type); // 모달 타입 설정
  };

  const handleIbModalClose = () => {
    setIbModalType(null); // 모달 타입 초기화
  };

  const handleFeedbackOpen = (referenceId: number, referenceTable: FeedbackReferenceTable, unreadCount: number) => {
    setFeedbackReference({ referenceId, referenceTable });
    setFeedbackOpen(true);

    if (unreadCount > 0) {
      markAsRead(referenceId, referenceTable); // referenceTable 추가
    }
  };

  useEffect(() => {
    const initialCounts: Record<string, number> = {};

    if (rppfFeedbacks?.items) {
      rppfFeedbacks.items.forEach((item) => {
        initialCounts[`RPPF-${item.referenceId}`] = item.unreadCount || 0;
      });
    }

    if (interviewFeedbacks?.items) {
      interviewFeedbacks.items.forEach((item) => {
        initialCounts[`INTERVIEW-${item.referenceId}`] = item.unreadCount || 0;
      });
    }

    setLocalUnreadCounts(initialCounts);
  }, [rppfFeedbacks, interviewFeedbacks]);

  if (!proposalData) {
    return <Blank />;
  }

  return (
    <section className="flex h-[664px] flex-col">
      <header className="px-6 pb-6">
        <Typography variant="title1">RPPF</Typography>
      </header>
      <main>
        {mergedData.length ? (
          <table className="w-full">
            <thead className="border-y border-y-primary-gray-100 text-[15px] font-medium text-primary-gray-500">
              <tr>
                <td className="w-[176px] py-[9px] pl-6 pr-2 text-center">종류</td>
                <td className="w-[632px] px-2 py-[9px] text-center">제목</td>
                <td className="w-[188px] px-2 py-[9px] text-center">수정일</td>
                <td className="w-[188px] py-[9px] pl-2 pr-6 text-center">학생 댓글</td>
              </tr>
            </thead>
            <tbody>
              {rppfData.map((rppf) => {
                const feedback = rppfFeedbacks?.items?.find((item) => item.referenceId === rppf.id);
                return (
                  <tr key={rppf.id} className="border-b border-b-primary-gray-100">
                    <td className="py-[11px] pl-6 pr-2 text-center">
                      <BadgeV2 type="solid_regular" color={'blue'} size={24}>
                        공식 RPPF
                      </BadgeV2>
                    </td>
                    <td
                      className="cursor-pointer px-2 py-[11px] text-start"
                      onClick={() =>
                        push(`/teacher/ib/ee/${id}/rppf/${rppf.id}`, {
                          title,
                          data: proposalData,
                          student: studentData,
                        })
                      }
                    >
                      공식 RPPF
                    </td>
                    <td className="px-2 py-[11px] text-center">{format(new Date(rppf.updatedAt), 'yyyy.MM.dd')}</td>
                    <td className="flex justify-center py-[11px] pl-2 pr-6">
                      {feedback ? (
                        feedback.totalCount === 0 ? (
                          <>-</>
                        ) : localUnreadCounts[`RPPF-${rppf.id}`] === 0 ? (
                          <ButtonV2
                            variant="outline"
                            color="gray400"
                            size={32}
                            onClick={() =>
                              push(`/teacher/ib/ee/${id}/rppf/${rppf.id}`, {
                                title,
                                data: proposalData,
                                student: studentData,
                              })
                            }
                          >
                            보기
                          </ButtonV2>
                        ) : (
                          <ButtonV2
                            className={`flex flex-row items-center gap-1`}
                            variant="outline"
                            color="gray400"
                            size={32}
                            onClick={() => handleFeedbackOpen(rppf.id, 'RPPF', localUnreadCounts[`RPPF-${rppf.id}`])}
                          >
                            <>
                              <ColorSVGIcon.New color="orange800" />
                              보기
                            </>
                          </ButtonV2>
                        )
                      ) : (
                        <>-</>
                      )}
                    </td>
                  </tr>
                );
              })}
              {interviewData
                ?.slice()
                .sort((a, b) => new Date(b.qna.updatedAt).getTime() - new Date(a.qna.updatedAt).getTime())
                .map((interview) => {
                  const feedback = interviewFeedbacks?.items?.find((item) => item.referenceId === interview.qna.id);
                  return (
                    <tr key={interview.id} className="border-b border-b-primary-gray-100">
                      <td className="py-[11px] pl-6 pr-2 text-center">
                        <BadgeV2 type="solid_regular" color={'gray'} size={24}>
                          인터뷰
                        </BadgeV2>
                      </td>
                      <td
                        className="cursor-pointer px-2 py-[11px] text-start"
                        onClick={() =>
                          push(`/teacher/ib/ee/${id}/interview/${interview.qna.id}`, {
                            title,
                            student: studentData,
                            data: proposalData,
                          })
                        }
                      >
                        {interview.title}
                      </td>
                      <td className="px-2 py-[11px] text-center">
                        {format(new Date(interview.createdAt), 'yyyy.MM.dd')}
                      </td>
                      <td className="flex justify-center py-[11px] pl-2 pr-6">
                        {feedback ? (
                          feedback.totalCount === 0 ? (
                            <>-</>
                          ) : localUnreadCounts[`INTERVIEW-${interview.qna.id}`] === 0 ? (
                            <ButtonV2
                              variant="outline"
                              color="gray400"
                              size={32}
                              onClick={() =>
                                push(`/teacher/ib/ee/${id}/interview/${interview.qna.id}`, {
                                  title,
                                  student: studentData,
                                  data: proposalData,
                                })
                              }
                            >
                              보기
                            </ButtonV2>
                          ) : (
                            <ButtonV2
                              className={`flex flex-row items-center gap-1`}
                              variant="outline"
                              color="gray400"
                              size={32}
                              onClick={() =>
                                handleFeedbackOpen(
                                  interview.qna.id,
                                  'INTERVIEW',
                                  localUnreadCounts[`INTERVIEW-${interview.qna.id}`],
                                )
                              }
                            >
                              <>
                                <ColorSVGIcon.New color="orange800" />
                                보기
                              </>
                            </ButtonV2>
                          )
                        ) : (
                          <>-</>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="flex flex-col items-center gap-6 py-20">
            <div className="h-12 w-12 px-[2.50px]">
              <img src={NODATA} className="h-12 w-[43px] object-cover" />
            </div>
            <Typography variant="body2" className="text-center">
              제안서 승인 후, 학생이 RPPF를 작성해야
              <br />
              확인할 수 있습니다.
            </Typography>
          </div>
        )}
      </main>
      <div className="mt-auto flex w-full justify-center border-t border-primary-gray-100 bg-white/70 px-6 py-5 backdrop-blur-[20px]">
        {rppfData[0]?.editStatus === 'REQUEST_UNLOCK' && rppfData[0]?.status === 'IN_PROGRESS' ? (
          <div className="mx-auto flex w-[1280px] items-center justify-center">
            <ButtonV2
              className="w-[416px]"
              size={48}
              variant="solid"
              color="orange800"
              onClick={() => {
                approveEditStatus({ ibId: Number(id), rppfId: Number(rppfData[0].id) });
              }}
            >
              RPPF 수정 승인
            </ButtonV2>
          </div>
        ) : (
          <>
            {proposalData?.status === 'COMPLETE' ? (
              <div className="mx-auto flex w-[1280px] items-center justify-center">
                <ButtonV2
                  className="w-[416px]"
                  size={48}
                  variant="solid"
                  color="gray700"
                  onClick={() => handleIbModalOpen('VIEW')}
                >
                  IB제출정보 확인
                </ButtonV2>
              </div>
            ) : rppfData[0]?.status === 'WAIT_COMPLETE' || rppfData[0]?.status === 'COMPLETE' ? (
              <>
                {rppfData[0]?.academicIntegrityConsent ? (
                  <div className="mx-auto flex w-[1280px] items-center justify-end">
                    <div className="flex items-center gap-4">
                      <ButtonV2
                        className="w-[200px]"
                        size={48}
                        variant="solid"
                        color="gray700"
                        onClick={() => handleIbModalOpen('CREATE')}
                      >
                        IB제출정보 확인 및 수정
                      </ButtonV2>
                      <ButtonV2
                        className="w-[200px]"
                        size={48}
                        variant="solid"
                        color="orange800"
                        onClick={() => approveIBProjectComplete(Number(id))}
                      >
                        완료 승인
                      </ButtonV2>
                    </div>
                  </div>
                ) : (
                  <div className="mx-auto flex w-[1280px] items-center justify-end">
                    <div className="flex items-center gap-4">
                      <ButtonV2
                        className="w-[200px]"
                        size={48}
                        variant="solid"
                        color="gray700"
                        onClick={() => setRejectModalOpen(true)}
                      >
                        RPPF 보완요청
                      </ButtonV2>
                      <ButtonV2
                        className="w-[200px]"
                        size={48}
                        variant="solid"
                        color="orange800"
                        onClick={() => handleIbModalOpen('CREATE')}
                      >
                        IB제출정보 기입
                      </ButtonV2>
                    </div>
                  </div>
                )}
              </>
            ) : rppfData[0]?.status === 'REJECT' ? (
              <div className="mx-auto flex w-[1280px] items-center justify-center">
                <ButtonV2 variant="solid" color="gray700" size={48} className="w-[416px]" disabled={true}>
                  RPPF 보완요청
                </ButtonV2>
              </div>
            ) : null}
          </>
        )}
      </div>
      <PopupModal
        modalOpen={rejectModalOpen}
        setModalClose={() => {
          setRejectModalOpen(false);
          setRejectReason('');
        }}
        title="RPPF 보완 요청"
        bottomBorder={false}
        footerButtons={
          <ButtonV2
            size={48}
            variant="solid"
            color="orange800"
            className="w-[88px]"
            disabled={!Boolean(rejectReason.length).valueOf()}
            onClick={() =>
              rejectPlan({ ibId: Number(id), rppfId: Number(rppfData[0].id), data: { content: rejectReason } })
            }
          >
            전달하기
          </ButtonV2>
        }
      >
        <div className="flex flex-col gap-6">
          <Typography variant="body1">학생에게 RPPF에 대한 피드백을 남겨주세요.</Typography>
          <TextareaV2
            placeholder="내용을 입력하세요."
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          />
        </div>
      </PopupModal>
      {ibModalType && rppfData[0] && (
        <RppfIbSubmitInformPopup
          IBData={proposalData}
          ibId={Number(id)}
          rppfId={rppfData[0].id}
          modalOpen={Boolean(ibModalType)}
          setModalClose={handleIbModalClose}
          type={ibModalType}
        />
      )}
      {alertMessage && <AlertV2 message={alertMessage} confirmText="확인" onConfirm={() => setAlertMessage(null)} />}

      {feedbackOpen && (
        <FeedbackViewer
          modalOpen={feedbackOpen}
          setModalClose={() => setFeedbackOpen(!feedbackOpen)}
          referenceId={feedbackReference?.referenceId || 0}
          referenceTable={feedbackReference?.referenceTable || 'RPPF'}
        />
      )}
    </section>
  );
};
