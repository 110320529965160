import clsx from 'clsx';
import { useMemo } from 'react';
import { ReactComponent as RightArrow } from 'src/assets/svg/mypage-right-arrow.svg';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { Typography } from 'src/components/common/Typography';
import { ResponseIBRPPFSubmissionStatusDto } from 'src/generated/model';

interface OverviewPanelProps {
  data: ResponseIBRPPFSubmissionStatusDto[];
  title: string;
  buttonText: string;
  type?: 'complete' | 'not';
  buttonHandler: () => void;
  className?: string;
  action?: (studentIbId: number, rppfId: number) => void;
}

export default function RPPFOverviewPanel(props: OverviewPanelProps) {
  const { action, data, title, buttonText, buttonHandler, type = 'not', className } = props;

  const groupedByKlass = useMemo(() => {
    return data.reduce(
      (acc, student) => {
        const klass = student.leader.studentGroup.group.klass;
        if (!acc[klass]) {
          acc[klass] = {
            name: student.leader.studentGroup.group.name,
            students: [],
          };
        }
        acc[klass].students.push(student);
        return acc;
      },
      {} as Record<number, { name: string; students: ResponseIBRPPFSubmissionStatusDto[] }>,
    );
  }, [data]);

  const renderGroupedStudents = () => {
    return Object.entries(groupedByKlass).map(([klass, group]) => (
      <div key={klass}>
        {/* 반 이름 출력 */}
        <div className="mb-4 flex items-center gap-3">
          <Typography variant="body3" className="font-medium text-primary-gray-500">
            {group.name}
          </Typography>
          <hr className="flex-1 border-t border-primary-gray-100" />
        </div>

        {/* 해당 반의 학생 목록 출력 */}
        <div className="grid grid-cols-6 gap-3">
          {group.students
            .sort((a, b) => Number(a.leader.studentGroup.studentNumber) - Number(b.leader.studentGroup.studentNumber))
            .map((student) => (
              <div
                key={student.id}
                className={`flex h-[48px] w-[195px] items-center justify-between rounded-lg bg-primary-gray-50 py-[14px] pl-4 pr-4 text-14 text-primary-gray-700 ${
                  action && 'hover:cursor-pointer'
                }`}
                onClick={action ? () => action(student.id, student.rppf.id) : undefined}
              >
                <div className="flex flex-row items-center">
                  <Typography variant="body3" className="text-primary-gray-700">
                    {student.leader.studentGroup.group.grade}
                    {String(student.leader.studentGroup.group.klass).padStart(2, '0')}
                    {String(student.leader.studentGroup.studentNumber).padStart(2, '0')}
                  </Typography>
                  <span className="mx-1">·</span>
                  <Typography variant="body3" className="text-primary-gray-700">
                    {student.leader.name}
                  </Typography>
                </div>
                {action && <RightArrow className="h-4 w-4" />}
              </div>
            ))}
        </div>
      </div>
    ));
  };

  const containerStyles = clsx('w-full rounded-xl border border-primary-gray-200 p-6 flex flex-col gap-6', className);

  return (
    <div className={containerStyles}>
      <div className="flex justify-between">
        <Typography variant="title3">
          {title} <span className="text-primary-orange-800">{data.length}명</span>
        </Typography>
        {data.length > 0 && (
          <ButtonV2 variant="outline" color="gray400" size={32} onClick={buttonHandler}>
            {buttonText}
          </ButtonV2>
        )}
      </div>
      {renderGroupedStudents()}
    </div>
  );
}
