import {
  useIBChangeProposalRank,
  useIBUpdateIBEditStstusRequestUnlock,
  useIBUpdateIBEditStstusUnlock,
  useIBUpdateIBProposalStatusReject,
  useIBUpdateProposal,
} from 'src/generated/endpoint';
import { RequestIBCommentDto, RequestIBProposalRankItemDto, RequestIBProposalUpdateDto } from 'src/generated/model';

interface UseIBProposalUpdateOptions {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
  onClose?: () => void;
}

interface updateIBProposalProps {
  id: number;
  proposalId: number;
  data: RequestIBProposalUpdateDto;
}

interface changeIBProposalRankProps {
  id: number;
  data: RequestIBProposalRankItemDto;
}

interface editIBProposalstatus {
  id: number;
  data: RequestIBCommentDto;
}

export const useIBProposalUpdate = ({ onSuccess, onError, onClose }: UseIBProposalUpdateOptions) => {
  const { mutate, isLoading, isError, error } = useIBUpdateProposal({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('IB 프로젝트 생성 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const updateIBProposal = ({ id, proposalId, data }: updateIBProposalProps) => {
    mutate({ id, proposalId, data });
  };

  return {
    updateIBProposal,
    isLoading,
    isError,
    error,
  };
};

export const useIBProposalStatusReject = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}) => {
  const { mutate, isLoading, isError, error } = useIBUpdateIBProposalStatusReject({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        console.error('제안서 반려 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const rejectProposal = (id: number, proposalId: number) => {
    mutate({ id, proposalId });
  };

  return {
    rejectProposal,
    isLoading,
    isError,
    error,
  };
};

export const useIBProposalRankChange = ({ onSuccess, onError, onClose }: UseIBProposalUpdateOptions) => {
  const { mutate, isLoading, isError, error } = useIBChangeProposalRank({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('제안서 순위 변경 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const changeIBProposalRank = ({ id, data }: changeIBProposalRankProps) => {
    mutate({ id, data });
  };

  return {
    changeIBProposalRank,
    isLoading,
    isError,
    error,
  };
};

export const useIBProposalEditStatus = ({ onSuccess, onError, onClose }: UseIBProposalUpdateOptions) => {
  const { mutate, isLoading, isError, error } = useIBUpdateIBEditStstusRequestUnlock({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('제안서 수정 요청 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const editIBProposalStatus = ({ id, data }: editIBProposalstatus) => {
    mutate({ id, data });
  };

  return {
    editIBProposalStatus,
    isLoading,
    isError,
    error,
  };
};

export const useIBProposalApproveStatus = ({ onSuccess, onError, onClose }: UseIBProposalUpdateOptions) => {
  const { mutate, isLoading, isError, error } = useIBUpdateIBEditStstusUnlock({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('제안서 수정 요청 승인 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const approveIBProposalStatus = (id: number) => {
    mutate({ id });
  };

  return {
    approveIBProposalStatus,
    isLoading,
    isError,
    error,
  };
};
