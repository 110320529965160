import { useEssayUpdateEditStstusRequestUnlock, useEssayUpdateEditStstusUnlock } from 'src/generated/endpoint';
import { RequestIBCommentDto } from 'src/generated/model';

interface UseIBEssayUpdateOptions {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
  onClose?: () => void;
}

interface approveEditStatusProps {
  ibId: number;
  essayId: number;
}

interface updateEditStatusProps {
  ibId: number;
  essayId: number;
  data: RequestIBCommentDto;
}

export const useIBEssayApproveStatus = ({ onSuccess, onError, onClose }: UseIBEssayUpdateOptions) => {
  const { mutate, isLoading, isError, error } = useEssayUpdateEditStstusUnlock({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('에세이 수정 승인 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const approveEditStatus = ({ ibId, essayId }: approveEditStatusProps) => {
    mutate({ ibId, essayId });
  };

  return {
    approveEditStatus,
    isLoading,
    isError,
    error,
  };
};
export const useIBEssayUpdateEditStatus = ({ onSuccess, onError, onClose }: UseIBEssayUpdateOptions) => {
  const { mutate, isLoading, isError, error } = useEssayUpdateEditStstusRequestUnlock({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('에세이 수정 요청 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const updateEditStatus = ({ ibId, essayId, data }: updateEditStatusProps) => {
    mutate({ ibId, essayId, data });
  };

  return {
    updateEditStatus,
    isLoading,
    isError,
    error,
  };
};
