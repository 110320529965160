import { useIBDeadlineGetItems } from 'src/generated/endpoint';
import { IBDeadlineGetItemsParams } from 'src/generated/model';

interface useIBDeadlineProps {
  type: IBDeadlineGetItemsParams['type'];
  model: 'PROPOSAL' | 'ESSAY' | 'RPPF';
}

export const useIBDeadline = ({ type, model }: useIBDeadlineProps) => {
  const { data, isLoading: isFetching } = useIBDeadlineGetItems({ type });
  const modelToTypeMap: Record<typeof model, string[]> = {
    PROPOSAL: ['EE_PROPOSAL'],
    ESSAY: ['EE_ESSAY'],
    RPPF: ['EE_RPPF_1', 'EE_RPPF_2', 'EE_RPPF_3'],
  };

  // 원하는 데이터만 필터링
  const filteredDeadline = data?.items.filter((item) => modelToTypeMap[model].includes(item.type));

  return {
    deadline: filteredDeadline,
    isFetching,
  };
};
