import { format } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Linkify from 'react-linkify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { Typography } from 'src/components/common/Typography';
import { Feedback } from 'src/components/ib/Feedback';
import IBLayout from 'src/components/ib/IBLayout';
import SVGIcon from 'src/components/icon/SVGIcon';
import { Constants } from 'src/constants';
import { useRRSGetById } from 'src/container/ib-rrs-findId';
import { meState } from 'src/store';
import { downloadFile } from 'src/util/download-image';
import { getFileNameFromUrl } from 'src/util/file';
import { useIBGetById } from 'src/container/ib-project-get-student';
import { LocationState } from 'src/type/ib';

const urlDecorator = (decoratedHref: string, decoratedText: string, key: number) => (
  <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer" className="underline">
    {decoratedText}
  </a>
);

export default function EERrsDetailPage() {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const { id: idParam, rrsId: rrsIdParam } = useParams<{ id: string; rrsId: string }>();
  const id = Number(idParam);
  const rrsId = Number(rrsIdParam);
  const me = useRecoilValue(meState);
  const { data: rrs } = useRRSGetById(id, rrsId);

  const { title: locationTitle, student: locationStudentData } = location.state || {};

  const { data: ibData, klassNum: ibKlassNum } = useIBGetById(Number(id), 'EE', {
    enabled: !(locationTitle && locationStudentData),
  });
  const data = location.state?.data || ibData;

  const acceptProposalTitle = data?.proposals?.find((proposal) => proposal.status === 'ACCEPT')?.researchTopic;

  const title = acceptProposalTitle;
  const klassNum = location.state?.student.klassNum || ibKlassNum;

  if (me == null || rrs === undefined) {
    return <div>접속 정보를 불러올 수 없습니다.</div>;
  }
  return (
    <div className="col-span-6">
      <IBLayout
        topContent={
          <div>
            <div className="w-full pb-6 pt-16">
              <div className="flex flex-col items-start gap-3">
                <div className="flex w-full flex-row items-center justify-between">
                  <div className="flex flex-row gap-1">
                    <BadgeV2 color="dark_green" size={24} type="solid_strong" className="self-start">
                      EE
                    </BadgeV2>
                    <BadgeV2 color="gray" size={24} type="solid_regular" className="self-start">
                      RRS
                    </BadgeV2>
                  </div>
                  <Breadcrumb
                    data={{
                      진행상태: '/ib/student',
                      EE: `/ib/student/ee/${id}`,
                      'RRS 상세': `/ib/student/ee/${id}/rrs/${rrsId}`,
                    }}
                  />
                </div>
                <div className="flex w-full justify-between">
                  <Typography variant="heading">{title}</Typography>
                  <div className="rounded-lg border border-orange-100 bg-orange-50 px-4 py-2 text-16 font-semibold text-orange-800">
                    {klassNum} · {data?.leader?.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        bottomContent={
          <div className="flex flex-grow flex-col">
            <div className="flex h-full flex-row gap-4 py-6">
              <div className="flex w-[848px] flex-col justify-between rounded-xl bg-white p-6">
                <div className="flex flex-col">
                  <div className="flex flex-col items-start gap-1 border-b border-b-primary-gray-100 pb-6">
                    <Typography variant="title1">{rrs.title}</Typography>
                    <Typography variant="body3" className="text-primary-gray-500">
                      {format(new Date(rrs.createdAt), 'yyyy.MM.dd')}
                    </Typography>
                  </div>
                  <div className="pt-6">
                    <Typography variant="body1">
                      <Linkify componentDecorator={urlDecorator}>{rrs.content}</Linkify>
                    </Typography>
                  </div>
                  {!!rrs.images?.length || !!rrs.files?.length ? (
                    <div className="flex flex-col gap-4 py-10">
                      {/* 이미지 컨테이너 */}
                      {!!rrs.images?.length && (
                        <div className="grid w-full flex-grow grid-flow-row grid-cols-6 gap-3">
                          {rrs.images.map((image: string, i: number) => (
                            <div key={i} className="h-30 w-30">
                              <div className="aspect-square cursor-pointer rounded-lg">
                                <LazyLoadImage
                                  src={`${Constants.imageUrl}${image}`}
                                  alt=""
                                  loading="lazy"
                                  className="object-fit h-full w-full rounded-lg"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                      {/* 파일 컨테이너 */}
                      {!!rrs.files?.length && (
                        <div className="flex flex-col gap-3">
                          {rrs.files.map((fileUrl: string, index) => (
                            <div
                              key={index}
                              className="flex h-12 w-max items-center gap-3 rounded-lg border border-primary-gray-200 bg-white px-4"
                            >
                              <SVGIcon.Link size={16} weight="bold" color="gray700" />
                              <button
                                className="text-[15px] text-[#121316]"
                                onClick={() =>
                                  downloadFile(`${Constants.imageUrl}${fileUrl}`, getFileNameFromUrl(fileUrl))
                                }
                              >
                                {getFileNameFromUrl(fileUrl)}
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
                <footer className={`flex flex-row items-center justify-between`}>
                  <ButtonV2
                    size={40}
                    variant="solid"
                    color="gray100"
                    onClick={() => history.push(`/teacher/ib/ee/${id}`, { type: 'RRS' })}
                  >
                    목록 돌아가기
                  </ButtonV2>
                </footer>
              </div>
              <div className="flex h-[720px] w-[416px] flex-col gap-6 rounded-xl bg-white p-6">
                <Typography variant="title1">진행기록</Typography>
                <div className="h-full w-full">
                  <Feedback referenceId={rrsId} referenceTable="RRS" user={me} />
                </div>
              </div>
            </div>
          </div>
        }
        bottomBgColor="bg-primary-gray-50"
      />
    </div>
  );
}
