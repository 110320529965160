import _ from 'lodash';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CAS from 'src/assets/images/CAS.png';
import EE from 'src/assets/images/EE.png';
import TOK from 'src/assets/images/TOK.png';
import NODATA from 'src/assets/images/no-data.png';
import { IBGetIBByStudentIbType, ResponsePaginatedIBDto } from 'src/generated/model';
import { IBProject, ModalType } from 'src/pages/ib/student/IBStudentMainPage';
import { PopupModal } from '../PopupModal';
import Accordion from '../common/Accordion';
import AlertV2 from '../common/AlertV2';
import { ButtonV2 } from '../common/ButtonV2';
import { LayeredTabs, Tab } from '../common/LayeredTabs';
import { RadioV2 } from '../common/RadioV2';
import { Typography } from '../common/Typography';
import { IbEeProposal } from './IbEeProposal';
import ProjectCard from './ProjectCard';

export const STATUS_GROUPS = {
  담당교사_지정대기: ['PENDING', 'WAIT_MENTOR', 'WAITING_FOR_NEXT_PROPOSAL'],
  계획중: ['WAIT_PLAN_APPROVE', 'REJECT_PLAN', 'REJECT_MENTOR'],
  진행중: ['IN_PROGRESS', 'REJECT_COMPLETE', 'WAIT_COMPLETE'],
  완료: ['COMPLETE'],
};

interface StudentIBStatusProps {
  data?: ResponsePaginatedIBDto;
}

export default function StudentIBStatus({ data }: StudentIBStatusProps) {
  const [projectType, setProjectType] = useState<IBGetIBByStudentIbType>('NORMAL');
  const [savedProjectData, setSavedProjectData] = useState<any>(null);
  const [activeModal, setActiveModal] = useState<ModalType>(null);
  const [step, setStep] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState<IBProject>('');
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const isEETypeExists = data?.items?.some((item) => item.ibType === 'EE');
  const { push } = useHistory();

  const toggleProjectSelectionModal = () => {
    setActiveModal(activeModal === 'projectSelection' ? null : 'projectSelection');
    setStep(0);
  };

  const handleSuccess = (action: 'save' | 'requestApproval', data?: any) => {
    setSavedProjectData(data);
    setActiveModal(null);
    setAlertMessage(action === 'save' ? `제안서가 \n저장되었습니다` : `제안서 승인 요청이\n완료되었습니다`);
  };

  const handleNext = () => {
    if (step === 0 && selectedValue !== '') {
      setStep(step + 1);
      if (selectedValue === 'EE') {
        setActiveModal('IbEeProposal');
      }
    } else if (step === 1) {
      setActiveModal(null);
      setStep(0);
    }
  };

  const handleBackToProjectSelection = () => {
    setActiveModal('projectSelection');
    setStep(0);
  };

  const getFilteredData = (statuses: string[]) =>
    _.filter(
      data?.items,
      (item) => statuses.includes(item.status) && (projectType === 'NORMAL' || item.ibType === projectType),
    );

  const pendingItems = getFilteredData(STATUS_GROUPS.담당교사_지정대기);
  const planningItems = getFilteredData(STATUS_GROUPS.계획중);
  const inProgressItems = getFilteredData(STATUS_GROUPS.진행중);
  const completedItems = getFilteredData(STATUS_GROUPS.완료);

  return (
    <main className="w-full">
      <header className="flex flex-row gap-3 py-5">
        <div>
          <LayeredTabs.TwoDepth
            onChange={(selectedType: IBGetIBByStudentIbType) => setProjectType(selectedType)}
            value={projectType}
          >
            <Tab value="NORMAL">
              <p>전체</p>
            </Tab>
            <Tab value="CAS">
              <p>CAS</p>
            </Tab>
            <Tab value="EE">
              <p>EE</p>
            </Tab>
            <Tab value="TOK">
              <p>TOK</p>
            </Tab>
          </LayeredTabs.TwoDepth>
        </div>
      </header>
      <section className="flex flex-col">
        {data?.items.length === 0 ? (
          <div className="flex flex-col items-center gap-6 py-20">
            <div className="h-12 w-12 px-[2.50px]">
              <img src={NODATA} className="h-12 w-[43px] object-cover" />
            </div>
            <span className="flex flex-col items-center">
              <Typography variant="body2">진행중인 프로젝트가 없습니다.</Typography>
              <Typography variant="body2">프로젝트를 생성해주세요.</Typography>
            </span>
            <span className="flex flex-row items-center">
              <ButtonV2 variant="solid" color="orange100" size={40} onClick={toggleProjectSelectionModal}>
                프로젝트 생성
              </ButtonV2>
            </span>
          </div>
        ) : (
          <>
            <Accordion title="담당교사 지정대기" count={pendingItems.length} isFirst={true}>
              {pendingItems.map((item, index) => (
                <ProjectCard data={item} key={item.id} />
              ))}
            </Accordion>

            <Accordion title="계획중" count={planningItems.length}>
              {planningItems.map((item, index) => (
                <ProjectCard data={item} key={item.id} />
              ))}
            </Accordion>

            <Accordion title="진행중" count={inProgressItems.length}>
              {inProgressItems.map((item, index) => (
                <ProjectCard data={item} key={item.id} />
              ))}
            </Accordion>

            <Accordion title="완료" count={completedItems.length}>
              {completedItems.map((item, index) => (
                <ProjectCard data={item} key={item.id} />
              ))}
            </Accordion>
          </>
        )}
      </section>
      {activeModal === 'projectSelection' && (
        <PopupModal
          modalOpen={true}
          setModalClose={() => setActiveModal(null)}
          title={step === 0 ? '프로젝트 유형을 선택해주세요' : '프로젝트 확인'}
          footerButtons={
            <div className="flex gap-2">
              {step > 0 && (
                <ButtonV2 variant="outline" color="gray400" size={48} onClick={() => setStep(step - 1)}>
                  이전
                </ButtonV2>
              )}
              <ButtonV2
                variant="solid"
                color="orange800"
                size={48}
                onClick={handleNext}
                disabled={step === 0 && selectedValue === ''}
              >
                {step === 0 ? '다음' : '완료'}
              </ButtonV2>
            </div>
          }
        >
          <RadioV2.Group
            selectedValue={selectedValue}
            onChange={(value: IBProject) => setSelectedValue(value)}
            className="flex flex-col gap-3"
          >
            <RadioV2.Box image={CAS} value="CAS" title="CAS" content="Creativity, Activity, Service" />
            <RadioV2.Box image={EE} value="EE" title="EE" content="Extended Essay" disabled={isEETypeExists} />
            <RadioV2.Box image={TOK} value="TOK" title="TOK" content="Theory of Knowledge" />
          </RadioV2.Group>
        </PopupModal>
      )}
      {activeModal === 'IbEeProposal' && (
        <IbEeProposal
          modalOpen={true}
          setModalClose={() => setActiveModal(null)}
          type="create"
          handleBack={handleBackToProjectSelection}
          onSuccess={(action, data) => handleSuccess(action, data)}
        />
      )}
      {alertMessage && (
        <AlertV2
          message={alertMessage}
          confirmText="확인"
          onConfirm={() => {
            setAlertMessage(null);
            if (savedProjectData) {
              push(`/ib/student/ee/${savedProjectData.id}`);
            }
          }}
        />
      )}
    </main>
  );
}
