import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { LayeredTabs } from 'src/components/common/LayeredTabs';
import { Typography } from 'src/components/common/Typography';
import { Tab } from 'src/components/common/UnderlineTabs';
import IBLayout from 'src/components/ib/IBLayout';
import TeacherIBOverview from 'src/components/ib/TeacherIBOverview';
import TeacherIBStatus from 'src/components/ib/TeacherIBStatus';
import SVGIcon from 'src/components/icon/SVGIcon';

type ProjectType = 'status' | 'overview';

export default function IBTeacherMainPage() {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const [type, setType] = useState<ProjectType>('status');

  return (
    <div className="col-span-6">
      <div className="h-screen w-full">
        <div className="">
          <IBLayout
            topContent={
              <div className="h-44">
                <div className="h-32 w-full  pt-16">
                  <div className=" flex h-10 w-full flex-row items-center justify-between gap-2">
                    <Typography variant="heading">프로젝트</Typography>
                    <div className="flex flex-row items-center gap-4">
                      <SVGIcon.Bell size={24} color="gray700" />
                      <div className="flex gap-2">
                        <ButtonV2 variant="outline" size={40} color="gray400">
                          자료실
                        </ButtonV2>
                        {/* TODO : 코디일때만 활성화 */}
                        <ButtonV2
                          variant="solid"
                          size={40}
                          color="orange100"
                          onClick={() => push(`/teacher/ib/coordinatorPage/EE`)}
                        >
                          프로젝트 관리
                        </ButtonV2>
                      </div>
                    </div>
                  </div>
                </div>
                <LayeredTabs.OneDepth onChange={(selectedType) => setType(selectedType)} value={type} className="pt-3">
                  <Tab value="status">
                    <p>진행상태</p>
                  </Tab>
                  <Tab value="overview">
                    <p>현황관리</p>
                  </Tab>
                </LayeredTabs.OneDepth>
              </div>
            }
            bottomContent={
              <div className="flex h-full items-center">
                {type === 'status' ? <TeacherIBStatus /> : <TeacherIBOverview />}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
