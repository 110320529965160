// P-T-012 : 코디선생님 프로젝트 메인 화면
// /teacher/ib/coordinatorPage
// https://www.notion.so/superschoolofficial/P-T-012-126e90ac0a99800e94d4da062bdc5417
import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { LayeredTabs, Tab } from 'src/components/common/LayeredTabs';
import { Typography } from 'src/components/common/Typography';
import CoordinatorCAS from 'src/components/ib/CoordinatorCAS';
import CoordinatorEE from 'src/components/ib/CoordinatorEE';
import CoordinatorIB from 'src/components/ib/CoordinatorIB';
import CoordinatorTOK from 'src/components/ib/CoordinatorTOK';
import IBLayout from 'src/components/ib/IBLayout';

type ProjectType = 'IB' | 'CAS' | 'EE' | 'TOK';

export const CoordinatorPage = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const { type: initType } = useParams<{ type: string }>();
  const [type, setType] = useState<ProjectType>(
    ['IB', 'CAS', 'EE', 'TOK'].includes(initType) ? (initType as ProjectType) : 'IB'
  );

  return (
    <div className="col-span-6">
      <div className="h-screen w-full ">
        <div className="">
          <IBLayout
            topContent={
              <div className="h-44">
                <div className="h-32 w-full  pt-16">
                  <div className=" flex h-10 w-full flex-row items-center justify-between gap-2">
                    <Typography variant="heading">프로젝트 관리</Typography>
                  </div>
                </div>
                <LayeredTabs.OneDepth onChange={(selectedType) => setType(selectedType)} value={type}>
                  <Tab value="IB">
                    <p>IB</p>
                  </Tab>
                  <Tab value="CAS">
                    <p>CAS</p>
                  </Tab>
                  <Tab value="EE">
                    <p>EE</p>
                  </Tab>
                  <Tab value="TOK">
                    <p>TOK</p>
                  </Tab>
                </LayeredTabs.OneDepth>
              </div>
            }
            bottomContent={
              <div className="flex h-full items-center ">
                {type === 'IB' && <CoordinatorIB />}
                {type === 'CAS' && <CoordinatorCAS />}
                {type === 'EE' && <CoordinatorEE />}
                {type === 'TOK' && <CoordinatorTOK />}
              </div>
            }
            bottomBgColor="bg-gray-50"
          />
        </div>
      </div>
    </div>
  );
};
