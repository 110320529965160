import { useState } from 'react';
import { LayeredTabs, Tab } from '../common/LayeredTabs';
import CoordinatorEE_FAQ from './CoordinatorEE_FAQ';
import CoordinatorEE_Form from './CoordinatorEE_Form';
import CoordinatorEE_Eval from './CoordinatorEE_Eval';
import CoordinatorEE_Schedule from './CoordinatorEE_Schedule';
import CoordinatorEE_Teacher from './CoordinatorEE_Teacher';

// TODO : 이미 등록된 타입 파일 찾을 시 변경 필요
type EEPreference = 'FAQ' | 'FORM' | 'EVAL' | 'SCHEDULE' | 'TEACHER';

export default function CoordinatorEE() {
  const [EEType, setEEType] = useState<EEPreference>('FAQ');

  return (
    <main className="w-full">
      <header className="flex flex-row gap-3 py-5">
        <div>
          <LayeredTabs.ThirdDepth onChange={(selectedType: EEPreference) => setEEType(selectedType)} value={EEType}>
            <Tab value="FAQ">
              <p>참고자료 및 FAQ</p>
            </Tab>
            <Tab value="FORM">
              <p>양식</p>
            </Tab>
            <Tab value="EVAL">
              <p>평가</p>
            </Tab>
            <Tab value="SCHEDULE">
              <p>일정 및 알림발송</p>
            </Tab>
            <Tab value="TEACHER">
              <p>담당교사 지정</p>
            </Tab>
          </LayeredTabs.ThirdDepth>
        </div>
      </header>
      <section className="flex flex-col">
        {EEType === 'FAQ' && <CoordinatorEE_FAQ />}
        {EEType === 'FORM' && <CoordinatorEE_Form />}
        {EEType === 'EVAL' && <CoordinatorEE_Eval />}
        {EEType === 'SCHEDULE' && <CoordinatorEE_Schedule />}
        {EEType === 'TEACHER' && <CoordinatorEE_Teacher />}
      </section>
    </main>
  );
}
