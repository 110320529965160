import clsx from 'clsx';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import AlertV2 from 'src/components/common/AlertV2';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { TextareaV2 } from 'src/components/common/TextareaV2';
import { Typography } from 'src/components/common/Typography';
import { Feedback } from 'src/components/ib/Feedback';
import { IbEeRPPF } from 'src/components/ib/IbEeRPPF';
import IBLayout from 'src/components/ib/IBLayout';
import { PopupModal } from 'src/components/PopupModal';
import { useIBDeadline } from 'src/container/ib-deadline';
import { useIBRPPFRequestComplete } from 'src/container/ib-rppf';
import { useIBRPPFCreate } from 'src/container/ib-rppf-create';
import { useRPPFGetById } from 'src/container/ib-rppf-findId';
import { useIBRPPFUpdateEditStatus } from 'src/container/ib-rppf-update-edit-status';
import { RequestCreateRPPFDto, ResponseIBDto } from 'src/generated/model';
import { meState } from 'src/store';

interface LocationState {
  title: string;
  data: ResponseIBDto;
}

export default function RPPFDetailPage() {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const title = location.state?.title;
  const data = location.state?.data;

  const me = useRecoilValue(meState);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const { id, rppfId } = useParams<{ id: string; rppfId: string }>();

  const { data: rppf, refetch } = useRPPFGetById(Number(id), Number(rppfId));
  const { deadline } = useIBDeadline({ type: 'IB_EE', model: 'RPPF' });

  const { createIBRPPF } = useIBRPPFCreate({
    onSuccess: () => {
      setAlertMessage(`RPPF가\n수정되었습니다`);
    },
    onError: (error) => {
      console.error('RPPF 생성 중 오류 발생:', error);
    },
  });

  const { requestIBRPPFComplete, isLoading } = useIBRPPFRequestComplete({
    onSuccess: () => {
      setAlertMessage(`완료를\n요청하였습니다`);
      refetch();
    },
    onError: (error) => {
      console.error('RPPF 완료 요청 중 오류 발생:', error);
    },
  });

  const { updateEditStatus } = useIBRPPFUpdateEditStatus({
    onSuccess: () => {
      setAlertMessage(`RPPF 수정권한을\n요청하였습니다`);
      setIsOpen(!isOpen);
      refetch();
    },
    onError: (error) => {
      console.error('RPPF 수정권한 요청중 오류 발생:', error);
    },
  });

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [wordCounts, setWordCounts] = useState<number[]>([]);

  const handleSuccess = () => {
    setAlertMessage(`RPPF가\n저장되었습니다`);
    setModalOpen(!modalOpen);
    refetch();
  };

  useEffect(() => {
    if (rppf?.contents) {
      const initialWordCounts = rppf.contents.map((content) => Number(content.wordCount) || 0);
      setWordCounts(initialWordCounts);
    }
  }, [rppf]);

  const handleWordCountChange = (index: number, count: number) => {
    setWordCounts((prev) => {
      const updatedCounts = [...prev];
      updatedCounts[index] = count;
      return updatedCounts;
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestCreateRPPFDto>({
    defaultValues: {
      contents:
        rppf?.contents.map((data) => ({
          id: data.id,
          sequence: data.sequence,
          text: data.text || '',
          wordCount: Number(data.wordCount) || 0,
        })) || [],
    },
  });

  const onSubmit = (data: RequestCreateRPPFDto) => {
    const filteredContents = data.contents
      .map((content, index) => ({
        ...content,
        id: content.id,
        sequence: index + 1,
        wordCount: wordCounts[index],
      }))
      .filter((content) => content.text && content.text.trim() !== '');

    const updatedData = {
      ...data,
      contents: filteredContents,
    };

    if (id !== undefined) {
      createIBRPPF({ ibId: Number(id), data: updatedData });
    }
    setEditMode(!editMode);
  };

  if (me === undefined) {
    return <div>접속 정보를 불러올 수 없습니다.</div>;
  }
  return (
    <div className="col-span-6">
      <IBLayout
        topContent={
          <div>
            <div className="w-full pb-6 pt-16">
              <div className="flex flex-col items-start gap-3">
                <div className="flex w-full flex-row items-center justify-between">
                  <div className="flex flex-row gap-1">
                    <BadgeV2 color="dark_green" size={24} type="solid_strong" className="self-start">
                      EE
                    </BadgeV2>
                    <BadgeV2 color="gray" size={24} type="solid_regular" className="self-start">
                      RPPF
                    </BadgeV2>
                  </div>
                  <Breadcrumb
                    data={{
                      진행상태: '/ib/student',
                      EE: `/ib/student/ee/${id}`,
                      'RPPF 상세': `/ib/student/ee/${id}/rppf/${rppfId}`,
                    }}
                  />
                </div>
                <Typography variant="heading">{title}</Typography>
              </div>
            </div>
          </div>
        }
        bottomContent={
          <div className="flex flex-grow flex-col">
            <div className="flex h-full flex-row gap-4 py-6">
              <div className="flex w-[848px] flex-col justify-between rounded-xl bg-white p-6">
                {editMode ? (
                  <>
                    <form>
                      <div className="scroll-box flex h-full flex-col gap-6 overflow-auto pb-8 pt-4">
                        <div className="flex flex-row items-center justify-between">
                          <Typography variant="title1">공식 RPPF</Typography>
                          <div className="flex flex-row items-center text-12">
                            <p className="text-primary-gray-500">총 단어 수</p>&nbsp;
                            <p className="font-medium text-primary-orange-800">
                              {wordCounts.reduce((sum, count) => sum + count, 0)}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {rppf?.contents.map((content, index) => (
                            <div
                              className={clsx('flex flex-col gap-4', {
                                'pt-6': index === 0,
                                'py-10': index === 1,
                                'pb-10': index === 2,
                              })}
                              key={content.id}
                            >
                              <div className="flex items-center justify-between">
                                <Typography variant="title2">RPPF {content.sequence}차</Typography>
                                <Typography variant="caption" className="text-primary-gray-500">
                                  최초 저장일 : {format(new Date(content.createdAt), 'yyyy.MM.dd')}
                                </Typography>
                              </div>
                              <input
                                type="hidden"
                                value={Number(content.id)} // Ensure id is an integer
                                {...register(`contents.${index}.id`, { valueAsNumber: true })}
                              />

                              <TextareaV2
                                showWordCount={true}
                                onWordCountChange={(count) => handleWordCountChange(index, count)}
                                value={content.text}
                                placeholder="내용을 입력해주세요."
                                className="h-[308px]"
                                {...register(`contents.${index}.text` as const)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </form>
                  </>
                ) : (
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center justify-between">
                      <Typography variant="title1">공식 RPPF</Typography>
                      <div className="flex flex-row items-center text-12">
                        <p className="text-primary-gray-500">총 단어 수</p>&nbsp;
                        <p className="font-medium text-primary-orange-800">
                          {wordCounts.reduce((sum, count) => sum + count, 0)}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      {rppf?.contents.map((content, index) => (
                        <div
                          className={clsx('flex flex-col gap-4', {
                            'pt-6': index === 0,
                            'py-10': index === 1,
                            'pb-10': index === 2,
                          })}
                          key={content.id}
                        >
                          <div className="flex items-center justify-between">
                            <Typography variant="title2">RPPF {content.sequence}차</Typography>
                            <Typography variant="caption" className="text-primary-gray-500">
                              최초 저장일 : {format(new Date(content.createdAt), 'yyyy.MM.dd')}
                            </Typography>
                          </div>
                          <div className="flex flex-col gap-4 rounded-lg border border-primary-gray-200 p-4">
                            <Typography variant="body2">{content.text}</Typography>
                            <div className="flex flex-row items-center text-12">
                              <p className="text-primary-gray-500">단어 수</p>&nbsp;
                              <p className="font-medium text-primary-orange-800">{content.wordCount}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <footer className={`flex flex-row items-center justify-between`}>
                  {editMode ? (
                    <>
                      <ButtonV2 size={40} variant="solid" color="gray100" onClick={() => setEditMode(!editMode)}>
                        취소
                      </ButtonV2>
                      <ButtonV2 size={40} variant="solid" color="orange100" onClick={handleSubmit(onSubmit)}>
                        저장하기
                      </ButtonV2>
                    </>
                  ) : (
                    <>
                      {/* 수정 요청 및 수정 버튼 */}
                      {(rppf?.status === 'IN_PROGRESS' &&
                        deadline?.[2]?.deadlineTime &&
                        new Date() <= new Date(deadline[2].deadlineTime) && // 3차 마감 기한이 지나지 않은 경우
                        (rppf?.editStatus === 'UNLOCK' || // 수정 권한이 있거나
                          rppf?.editStatus === 'LOCK' || // 수정 권한이 없지만 마감기한이 지나지 않은 경우
                          rppf?.contents.length === 3 || // 모든 차시가 작성된 경우에도 수정 가능
                          rppf?.contents.some((content) => !content.text.trim()))) || // 일부 차시가 작성되지 않은 경우
                      rppf?.editStatus === 'UNLOCK' ? ( // 수정 권한이 있으면 무조건 수정 가능
                        <ButtonV2 size={40} variant="outline" color="gray400" onClick={() => setEditMode(!editMode)}>
                          수정
                        </ButtonV2>
                      ) : (
                        (rppf?.status === 'IN_PROGRESS' ||
                          rppf?.status === 'WAIT_COMPLETE' ||
                          (rppf?.status === 'REJECT' && rppf.editStatus === 'LOCK') ||
                          rppf?.status === 'COMPLETE') &&
                        rppf?.contents.length === 3 &&
                        deadline?.[2]?.deadlineTime &&
                        (new Date() > new Date(deadline[2].deadlineTime) || // 마감기한이 지난 경우
                          rppf?.status === 'REJECT' || // 상태가 'REJECT'인 경우
                          rppf?.status === 'WAIT_COMPLETE' || // 상태가 'WAIT_COMPLETE'인 경우
                          rppf?.status === 'COMPLETE') && ( // 상태가 'COMPLETE'인 경우
                          <ButtonV2
                            size={40}
                            variant="outline"
                            color="gray400"
                            onClick={() => setIsOpen(!isOpen)}
                            disabled={
                              rppf?.editStatus === 'REQUEST_UNLOCK' || // 이미 수정권한 요청 중인 경우 비활성화
                              rppf?.status === 'WAIT_COMPLETE' || // 완료 대기 상태인 경우 비활성화
                              rppf?.status === 'COMPLETE' // 완료 상태인 경우 비활성화
                            }
                          >
                            수정권한 요청
                          </ButtonV2>
                        )
                      )}

                      {/* 목록 돌아가기 버튼 */}
                      <ButtonV2
                        size={40}
                        variant="solid"
                        color="gray100"
                        onClick={() => history.push(`/ib/student/ee/${id}`, { type: 'RPPF' })}
                      >
                        목록 돌아가기
                      </ButtonV2>
                    </>
                  )}
                </footer>
              </div>
              <div className="flex h-[720px] w-[416px] flex-col gap-6 rounded-xl bg-white p-6">
                <Typography variant="title1">진행기록</Typography>
                <div className="h-full w-full">
                  <Feedback referenceId={Number(rppfId)} referenceTable="RPPF" user={me} />
                </div>
              </div>
            </div>
          </div>
        }
        bottomBgColor="bg-primary-gray-50"
        floatingButton={
          !editMode &&
          rppf?.editStatus !== 'REQUEST_UNLOCK' &&
          (() => {
            if (data?.status === 'COMPLETE') {
              return null;
            }

            const currentDate = new Date();

            // 작성 가능 여부 확인 함수
            const isRPPFWritable = (type: string) => {
              const deadlineItem = deadline?.find((item) => item.type === type);
              if (!deadlineItem) return false;

              // 이전 단계의 RPPF 마감기한 확인
              const previousDeadlineItem = deadline?.find(
                (item) => item.type === `EE_RPPF_${Number(type.split('_')[2]) - 1}`,
              );

              // 현재 단계의 작성 가능 여부
              const isWithinCurrentDeadline = currentDate <= new Date(deadlineItem.deadlineTime);

              // 이전 단계의 마감기한을 넘었는지 확인
              const isAfterPreviousDeadline = previousDeadlineItem
                ? currentDate > new Date(previousDeadlineItem.deadlineTime)
                : true; // 이전 단계가 없으면 true

              // 해당 RPPF 데이터가 없는지 확인
              const hasNoData = !rppf?.contents.some((content) => content.sequence === Number(type.split('_')[2]));

              return isWithinCurrentDeadline && isAfterPreviousDeadline && hasNoData;
            };

            // 작성 버튼 출력 여부
            const showWriteRPPF2 = isRPPFWritable('EE_RPPF_2');
            const showWriteRPPF3 = isRPPFWritable('EE_RPPF_3');

            // "완료 승인요청" 조건
            const allRPPFCompleted =
              rppf?.contents.length === 3 && rppf?.contents.every((content) => content.text?.trim() !== '');

            if (rppf?.status === 'COMPLETE') {
              return (
                <ButtonV2 variant="solid" color="orange800" size={48} className="w-[416px]" disabled>
                  완료 승인요청
                </ButtonV2>
              );
            }

            if (allRPPFCompleted) {
              return (
                <ButtonV2
                  variant="solid"
                  color="orange800"
                  size={48}
                  className="w-[416px]"
                  onClick={() => requestIBRPPFComplete({ ibId: Number(id), rppfId: Number(rppfId) })}
                  disabled={rppf?.status === 'WAIT_COMPLETE'}
                >
                  완료 승인요청
                </ButtonV2>
              );
            }

            if (showWriteRPPF2 || showWriteRPPF3) {
              return (
                <ButtonV2
                  variant="solid"
                  color="orange800"
                  size={48}
                  className="w-[416px]"
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  작성하기
                </ButtonV2>
              );
            }

            return null;
          })()
        }
      />
      {isOpen && (
        <PopupModal
          modalOpen={isOpen}
          setModalClose={() => setIsOpen(!isOpen)}
          title="RPPF 수정권한 요청"
          bottomBorder={false}
          footerButtons={
            <div className="flex gap-2">
              <ButtonV2
                variant="solid"
                color="orange800"
                size={48}
                onClick={() => updateEditStatus({ ibId: Number(id), rppfId: Number(rppfId), data: { content } })}
              >
                요청하기
              </ButtonV2>
            </div>
          }
        >
          <div className="flex flex-col gap-4">
            <Typography variant="body1">RPPF를 수정하는 사유를 입력해주세요.</Typography>
            <TextareaV2 placeholder="내용을 입력하세요." value={content} onChange={(e) => setContent(e.target.value)} />
          </div>
        </PopupModal>
      )}
      {modalOpen && (
        <IbEeRPPF
          modalOpen={modalOpen}
          setModalClose={() => setModalOpen(!modalOpen)}
          projectId={Number(id)}
          RPPFData={rppf}
          onSuccess={() => handleSuccess()}
        />
      )}

      {alertMessage && <AlertV2 message={alertMessage} confirmText="확인" onConfirm={() => setAlertMessage(null)} />}
    </div>
  );
}
