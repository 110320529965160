import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AlertV2 from '../common/AlertV2';
import { ButtonV2 } from '../common/ButtonV2';
import { RadioV2 } from '../common/RadioV2';
import { Typography } from '../common/Typography';
import FrontPaginatedList from '../FrontPaginatedList ';
import { PopupModal } from '../PopupModal';
import { CoordinatorEE_FAQ_AddFaq } from './CoordinatorEE_FAQ_AddFaq';
import { CoordinatorEE_FAQ_AddRef } from './CoordinatorEE_FAQ_AddRef';

const items = [
  {
    id: 3,
    title: '평가기준_2023.02',
    date: '2024.08.19',
  },
  {
    id: 2,
    title: '평가기준_2020.02',
    date: '2024.08.19',
  },
  {
    id: 1,
    title: '평가기준_2017.02',
    date: '2024.08.19',
  },
  // ...목록 아이템 추가
];

export type ModalType = 'Category' | 'Add' | null;
export type CategoryType = 'Ref' | 'FAQ' | '';

export default function CoordinatorEE_Eval() {
  const { push } = useHistory();

  const [activeModal, setActiveModal] = useState<ModalType>(null);
  const [selectedCategory, setSelectedCategory] = useState<CategoryType>('');
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const Header = () => (
    <>
      <div className="flex-[1] p-2 text-center">번호</div>
      <div className="flex-[10] p-2 text-center">제목</div>
      <div className="flex-[2] p-2 text-center">작성일</div>
      <div className="flex-[2] p-2 text-center">관리</div>
    </>
  );

  // Item 컴포넌트
  const Item = ({ item }: { item: any }) => (
    <>
      <div className="flex-[1] p-2 text-center">{item.id}</div>
      <div
        className="flex-[10] cursor-pointer p-2 text-left"
        onClick={(e) => {
          e.stopPropagation();
          push(`/teacher/ib/coordinatorDetail/EEFaq/${item.id}`);
          handleEditClick(item);
        }}
      >
        {item.title}
      </div>
      <div className="flex-[2] p-2 text-center">{item.date}</div>
      <div className="flex-[2] p-2 text-center">
        <div className="flex flex-row justify-center">
          <ButtonV2
            variant="outline"
            size={32}
            color="gray400"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item);
            }}
          >
            수정
          </ButtonV2>
          <ButtonV2 variant="outline" size={32} color="gray400">
            삭제
          </ButtonV2>
        </div>
      </div>
    </>
  );

  const handleEditClick = (item: any) => {
    console.log('수정:', item);
  };

  const handleNext = () => {
    if (selectedCategory !== '') {
      setActiveModal('Add');
    }
  };

  const handleBackToProjectSelection = () => {
    setActiveModal(null);
  };

  const handleSuccess = (action: 'save' | 'requestApproval') => {
    setActiveModal(null);
    //    refetch();
    setAlertMessage(action === 'save' ? `제안서가 \n저장되었습니다` : `제안서 승인 요청이\n완료되었습니다`);
  };

  return (
    <div className="rounded-lg bg-white ">
      <div className=" m-6 flex flex-row items-center  justify-between">
        <Typography variant="title1">참고자료 및 FAQ</Typography>
        <ButtonV2 variant="solid" size={40} color="orange800" onClick={() => setActiveModal('Category')}>
          추가하기
        </ButtonV2>
      </div>

      <FrontPaginatedList
        headerComponent={<Header />}
        itemComponent={(item) => <Item item={item} />}
        page={1}
        pageSize={20}
        totalItems={items.length}
        items={items}
        onSelect={(item) => console.log('선택된 항목:', item)}
      />

      {activeModal === 'Category' && (
        <PopupModal
          modalOpen={true}
          setModalClose={() => setActiveModal(null)}
          title={'유형을 선택해주세요'}
          footerButtons={
            <div className="flex gap-2">
              <ButtonV2
                variant="solid"
                color="orange800"
                size={48}
                onClick={handleNext}
                disabled={selectedCategory === ''}
              >
                {'다음'}
              </ButtonV2>
            </div>
          }
        >
          <RadioV2.Group
            selectedValue={selectedCategory}
            onChange={(value: CategoryType) => setSelectedCategory(value)}
            className="flex flex-col gap-3"
          >
            <RadioV2.Box value="Ref" title="참고자료" />
            <RadioV2.Box value="FAQ" title="FAQ" />
          </RadioV2.Group>
        </PopupModal>
      )}
      {activeModal === 'Add' && selectedCategory === 'Ref' && (
        <CoordinatorEE_FAQ_AddRef
          modalOpen={true}
          setModalClose={() => setActiveModal(null)}
          type="create"
          handleBack={handleBackToProjectSelection}
          onSuccess={handleSuccess}
        />
      )}
      {activeModal === 'Add' && selectedCategory === 'FAQ' && (
        <CoordinatorEE_FAQ_AddFaq
          modalOpen={true}
          setModalClose={() => setActiveModal(null)}
          type="create"
          handleBack={handleBackToProjectSelection}
          onSuccess={handleSuccess}
        />
      )}
      {alertMessage && (
        <AlertV2
          message={alertMessage}
          confirmText="확인"
          onConfirm={() => setAlertMessage(null)} // closes the AlertV2 by resetting the message
        />
      )}
    </div>
  );
}
