import { useEssayGetEssay } from 'src/generated/endpoint';

export const useEssayGetByIBId = (ibId: number) => {
  const { data, isLoading, refetch } = useEssayGetEssay(ibId);
  return {
    data,
    isLoading,
    refetch,
  };
};
