// ProposalInputField.tsx
import { PropsWithChildren, ReactElement } from 'react';
import { Typography } from 'src/components/common/Typography';

interface ProposalViewFieldProps {
  label: string;
  value?: string | ReactElement;
}

export const ProposalViewField = ({ label, value, children }: PropsWithChildren<ProposalViewFieldProps>) => {
  return (
    <section className="flex flex-col gap-4">
      <Typography variant="title2">{label}</Typography>
      <Typography variant="body2" className="rounded-lg border border-primary-gray-200 p-4 text-15">
        {value || children}
      </Typography>
    </section>
  );
};
