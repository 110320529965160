import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import CAS from 'src/assets/images/CAS.png';
import EE from 'src/assets/images/EE.png';
import TOK from 'src/assets/images/TOK.png';
import AlertV2 from 'src/components/common/AlertV2';
import { ButtonV2 } from 'src/components/common/ButtonV2';
import { LayeredTabs, Tab } from 'src/components/common/LayeredTabs';
import { RadioV2 } from 'src/components/common/RadioV2';
import { Typography } from 'src/components/common/Typography';
import { IbEeProposal } from 'src/components/ib/IbEeProposal';
import IBLayout from 'src/components/ib/IBLayout';
import StudentIBStatus from 'src/components/ib/StudentIBStatus';
import TeacherIBOverview from 'src/components/ib/TeacherIBOverview';
import SVGIcon from 'src/components/icon/SVGIcon';
import { PopupModal } from 'src/components/PopupModal';
import { useIBGetByStudent } from 'src/container/ib-project-get-student';
import { meState } from 'src/store';

type ProjectType = 'status' | 'portfolio';
export type IBProject = '' | 'CAS' | 'EE' | 'TOK';
export type ModalType = 'projectSelection' | 'IbEeProposal' | null;

export const IBStudentMainPage = () => {
  const [type, setType] = useState<ProjectType>('status');
  const [step, setStep] = useState<number>(0);
  const [activeModal, setActiveModal] = useState<ModalType>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<IBProject>('');
  const me = useRecoilValue(meState);

  const handleSuccess = (action: 'save' | 'requestApproval') => {
    setActiveModal(null);
    refetch();
    setAlertMessage(action === 'save' ? `제안서가 \n저장되었습니다` : `제안서 승인 요청이\n완료되었습니다`);
  };

  const handleNext = () => {
    if (step === 0 && selectedValue !== '') {
      setStep(step + 1);
      if (selectedValue === 'EE') {
        setActiveModal('IbEeProposal');
      }
    } else if (step === 1) {
      setActiveModal(null);
      setStep(0);
    }
  };

  const toggleProjectSelectionModal = () => {
    setActiveModal(activeModal === 'projectSelection' ? null : 'projectSelection');
    setStep(0);
  };

  const handleBackToProjectSelection = () => {
    setActiveModal('projectSelection');
    setStep(0);
  };

  const { data, isLoading, refetch } = useIBGetByStudent(me?.id || 0);
  const isEETypeExists = data?.items?.some((item) => item.ibType === 'EE');

  return (
    <div className="col-span-6">
      <div className="h-screen w-full">
        <div className="">
          <IBLayout
            topContent={
              <div className="h-44">
                <div className="h-32 w-full  pt-16">
                  <div className=" flex h-10 w-full flex-row items-center justify-between gap-2">
                    <Typography variant="heading">프로젝트</Typography>
                    <div className="flex flex-row items-center gap-4">
                      <SVGIcon.Bell size={24} color="gray700" />
                      <ButtonV2 variant="outline" size={40} color="gray400">
                        자료실
                      </ButtonV2>
                      <ButtonV2
                        variant="solid"
                        size={40}
                        color="orange800"
                        className="flex flex-row items-center gap-1"
                        onClick={toggleProjectSelectionModal}
                      >
                        <SVGIcon.Plus color="white" size={16} weight="bold" />
                        프로젝트 생성
                      </ButtonV2>
                    </div>
                  </div>
                </div>
                <LayeredTabs.OneDepth onChange={(selectedType) => setType(selectedType)} value={type}>
                  <Tab value="status">
                    <p>진행상태</p>
                  </Tab>
                  <Tab value="portfolio">
                    <p>CAS Portfolio</p>
                  </Tab>
                </LayeredTabs.OneDepth>
              </div>
            }
            bottomContent={
              <div className="flex h-full items-center">
                {type === 'status' ? <StudentIBStatus data={data} /> : <TeacherIBOverview />}
              </div>
            }
          />
          {activeModal === 'projectSelection' && (
            <PopupModal
              modalOpen={true}
              setModalClose={() => setActiveModal(null)}
              title={step === 0 ? '프로젝트 유형을 선택해주세요' : '프로젝트 확인'}
              footerButtons={
                <div className="flex gap-2">
                  {step > 0 && (
                    <ButtonV2 variant="outline" color="gray400" size={48} onClick={() => setStep(step - 1)}>
                      이전
                    </ButtonV2>
                  )}
                  <ButtonV2
                    variant="solid"
                    color="orange800"
                    size={48}
                    onClick={handleNext}
                    disabled={step === 0 && selectedValue === ''}
                  >
                    {step === 0 ? '다음' : '완료'}
                  </ButtonV2>
                </div>
              }
            >
              <RadioV2.Group
                selectedValue={selectedValue}
                onChange={(value: IBProject) => setSelectedValue(value)}
                className="flex flex-col gap-3"
              >
                <RadioV2.Box image={CAS} value="CAS" title="CAS" content="Creativity, Activity, Service" />
                <RadioV2.Box image={EE} value="EE" title="EE" content="Extended Essay" disabled={isEETypeExists} />
                <RadioV2.Box image={TOK} value="TOK" title="TOK" content="Theory of Knowledge" />
              </RadioV2.Group>
            </PopupModal>
          )}
          {activeModal === 'IbEeProposal' && (
            <IbEeProposal
              modalOpen={true}
              setModalClose={() => setActiveModal(null)}
              type="create"
              handleBack={handleBackToProjectSelection}
              onSuccess={handleSuccess}
            />
          )}
        </div>
      </div>
      {alertMessage && (
        <AlertV2
          message={alertMessage}
          confirmText="확인"
          onConfirm={() => setAlertMessage(null)} // closes the AlertV2 by resetting the message
        />
      )}
    </div>
  );
};
