import clsx from 'clsx';
import { useState } from 'react';
import SVGIcon from '../icon/SVGIcon';

interface StepperProps {
  setNumber: (value: number) => void;
  number: number;
  readonly?: boolean;
  disabled?: boolean;
}

const Stepper: React.FC<StepperProps> = ({ number, setNumber, readonly = false, disabled = false }) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const handleMinusClick = () => {
    setNumber(number - 1);
  };

  const handlePlusClick = () => {
    setNumber(number + 1);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '') {
      setNumber(0);
    } else {
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue)) {
        setNumber(parsedValue);
      }
    }
  };
  return (
    <div
      className={clsx('flex w-max flex-row items-center gap-1 rounded-md border border-primary-gray-200', {
        'bg-whte': !disabled,
        'border-primary-gray-700': isFocused,
        'bg-primary-gray-100': disabled || readonly,
        'cursor-not-allowed': readonly || disabled,
        'cursor-pointer': !readonly && !disabled,
      })}
      onFocus={!readonly && !disabled ? handleFocus : undefined}
      onBlur={!readonly && !disabled ? handleBlur : undefined}
    >
      <div className="flex h-8 w-8 items-center justify-center border-r border-r-primary-gray-200">
        <button onClick={handleMinusClick} disabled={disabled || readonly}>
          <SVGIcon.Minus size={16} color={`${disabled || readonly ? 'gray400' : 'gray700'}`} weight="bold" />
        </button>
      </div>
      <div className="flex h-8 w-8 items-center justify-center overflow-hidden">
        <input
          type="number"
          value={number}
          onChange={handleInputChange}
          readOnly={readonly}
          disabled={disabled}
          className="h-full w-full border-none bg-transparent p-0 text-center text-sm font-medium text-primary-gray-700 caret-primary-blue-800 focus:outline-none focus:ring-0 disabled:text-primary-gray-400"
        />
      </div>
      <div className="flex h-8 w-8 items-center justify-center border-l border-l-primary-gray-200">
        <button onClick={handlePlusClick} disabled={disabled || readonly}>
          <SVGIcon.Plus size={16} color={`${disabled || readonly ? 'gray400' : 'gray700'}`} weight="bold" />
        </button>
      </div>
    </div>
  );
};

export default Stepper;
