export const EERrsFolderPage = () => {
  return (
    <div>
      <a
        href="https://www.notion.so/superschoolofficial/P-T-008-EE-RRS-126e90ac0a9980ea8445e44345d0c8c3"
        target="_blank"
      >
        P-T-008 : 선생님 EE RRS 폴더
      </a>
    </div>
  );
};
