export const EE_SUBJECTS = [
  { id: 0, value: '지식영역 전체' },
  { id: 1, value: '언어와문학' },
  { id: 2, value: '수학 분석과 접근' },
  { id: 3, value: '세계사' },
  { id: 4, value: '생명과학' },
  { id: 5, value: '화학' },
  { id: 6, value: '영어B' },
  { id: 7, value: '영어연극이론과 창작(영어)' },
  { id: 8, value: '비주얼아트(영어)' },
  { id: 9, value: '물리(영어)' },
  { id: 10, value: '컴퓨터과학(영어)' },
];

export const EE_SUBJECTS_CREATE = [
  { id: 1, value: '언어와문학' },
  { id: 2, value: '수학 분석과 접근' },
  { id: 3, value: '세계사' },
  { id: 4, value: '생명과학' },
  { id: 5, value: '화학' },
  { id: 6, value: '영어B' },
  { id: 7, value: '영어연극이론과 창작(영어)' },
  { id: 8, value: '비주얼아트(영어)' },
  { id: 9, value: '물리(영어)' },
  { id: 10, value: '컴퓨터과학(영어)' },
];
