import _ from 'lodash';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { EE_SUBJECTS } from 'src/constants/ib';
import {
  useGroupsFindAllKlass,
  useIBGetIBBycoordinator,
  useIBGetIBByGradeAndKlass,
  useIBGetIBByStudent,
  useStudentGroupsFindByGroupId,
} from 'src/generated/endpoint';
import { ResponseIBDto } from 'src/generated/model';
import { meState } from 'src/store';
import { padLeftstr } from 'src/util/status';
import Accordion from '../common/Accordion';
import { Check } from '../common/Check';
import { Input } from '../common/Input';
import { LayeredTabs, Tab } from '../common/LayeredTabs';
import SelectBar from '../common/SelectBar';
import { Typography } from '../common/Typography';
import ProjectCard from './ProjectCard';
import clsx from 'clsx';

const STATUS_GROUPS = {
  담당교사_지정대기: ['PENDING', 'WAIT_MENTOR', 'WAITING_FOR_NEXT_PROPOSAL'],
  계획중: ['WAIT_PLAN_APPROVE', 'REJECT_PLAN', 'REJECT_MENTOR'],
  진행중: ['IN_PROGRESS', 'REJECT_COMPLETE', 'WAIT_COMPLETE'],
  완료: ['COMPLETE'],
};

// TODO : 이미 등록된 타입 파일 찾을 시 변경 필요
type IBProject = 'ALL' | 'CAS' | 'EE' | 'TOK';

interface FilterOption {
  grade: number;
  klass: number;
}

export default function TeacherIBStatus() {
  const [projectType, setProjectType] = useState<IBProject>('ALL');
  const me = useRecoilValue(meState);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption>({
    grade: 0,
    klass: 0,
  });
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [selectedSubject, setSelectedSubject] = useState('지식영역 전체');
  const [selectedStudentId, setSelectedStudentId] = useState(0);
  const [searchStudentName, setSearchStudentName] = useState('');
  const [checked, setChecked] = useState(false);

  const { data: klassGroups } = useGroupsFindAllKlass();
  const { data: studentGroups } = useStudentGroupsFindByGroupId(selectedGroupId, {
    query: { enabled: !!selectedGroupId },
  });

  const isOptionSelected = !!selectedOptions.grade && !!selectedOptions.klass;

  // 전체 조회
  // TODO IBProject => IBGetIBBycoordinatorIbType 로 수정하기
  const { data: AllIBData } = useIBGetIBBycoordinator();

  // 학년, 반 조회
  const { data: IBDataByGradeKlass } = useIBGetIBByGradeAndKlass(
    {
      ...selectedOptions,
      klass: selectedOptions.klass === 0 ? undefined : selectedOptions.klass,
    },
    {
      query: { enabled: isOptionSelected },
    },
  );

  // 학생 데이터 조회
  const { data: IBDataByStudentId } = useIBGetIBByStudent(
    selectedStudentId,
    {},
    {
      query: { enabled: !!selectedStudentId },
    },
  );

  const handleOptionChange = (optionType: 'grade' | 'klass' | 'student', value: number) => {
    let updatedOptions = { ...selectedOptions };

    if (optionType === 'grade') {
      updatedOptions = {
        grade: value,
        klass: 0,
      };
    } else {
      updatedOptions = {
        ...updatedOptions,
        [optionType]: value,
      };
    }

    setSelectedOptions(updatedOptions);

    const groupDatas = klassGroups?.filter(
      (group) => group.grade === updatedOptions.grade && group.klass === updatedOptions.klass,
    );
    setSelectedGroupId(groupDatas?.[0]?.id || 0);
    setSelectedStudentId(0);
  };

  const resetOption = () => {
    setSelectedOptions({ grade: 0, klass: 0 });
    setSelectedGroupId(0);
    setSelectedStudentId(0);
  };

  const filterByMentor = (data: ResponseIBDto[], mentorId: number) => {
    return data.filter((item) => item.mentor?.id === mentorId);
  };

  const filterBySubject = (data: ResponseIBDto[], subject: string) => {
    if (subject === '지식영역 전체') {
      return data;
    }
    return data.filter(
      (item) =>
        item.proposals?.some(
          (proposal) => (proposal.status === 'ACCEPT' || proposal.status === 'PENDING') && proposal.subject === subject,
        ) || false,
    );
  };

  // 현재 선택된 데이터를 결정하는 부분 수정
  const rawData = selectedStudentId ? IBDataByStudentId : isOptionSelected ? IBDataByGradeKlass : AllIBData;

  if (me == null) {
    return <div>접속 정보를 불러올 수 없습니다.</div>;
  }

  const mentorFilteredData = checked ? filterByMentor(rawData?.items || [], me.id) : rawData?.items || [];

  // 선택된 지식영역 필터링 처리
  const subjectFilteredData = selectedSubject
    ? filterBySubject(mentorFilteredData, selectedSubject)
    : mentorFilteredData;

  // 데이터 필터링 함수
  const getFilteredData = (statuses: string[]) =>
    _.filter(
      subjectFilteredData,
      (item) =>
        statuses.includes(item.status) &&
        item.status !== 'PENDING' &&
        (projectType === 'ALL' || item.ibType === projectType) &&
        (!searchStudentName || item.leader.name.toLowerCase().includes(searchStudentName.toLowerCase())),
    );

  const pendingItems = getFilteredData(STATUS_GROUPS.담당교사_지정대기);
  const planningItems = getFilteredData(STATUS_GROUPS.계획중);
  const inProgressItems = getFilteredData(STATUS_GROUPS.진행중);
  const completedItems = getFilteredData(STATUS_GROUPS.완료);

  const searchResultCount = pendingItems.length + planningItems.length + inProgressItems.length + completedItems.length;

  const grades = _(klassGroups)
    .map('grade')
    .uniq()
    .map((grade, index) => ({ id: index + 1, value: grade, text: `${grade}학년` }))
    .concat([{ id: 0, value: 0, text: '학년 전체' }])
    .orderBy('value')
    .value();

  const klasses = _(klassGroups)
    .filter((group) => group.grade === selectedOptions.grade)
    .map('klass')
    .uniq()
    .map((klass, index) => ({ id: index + 1, value: klass, text: `${klass}반` }))
    .concat([{ id: 0, value: 0, text: '반 전체' }])
    .orderBy('value')
    .value();

  const students = _(studentGroups)
    .map((sg, index) => {
      const { grade, klass } = selectedOptions;
      return {
        id: index + 1,
        value: sg.user.id,
        text: `${grade}${padLeftstr(klass)}${padLeftstr(sg.studentNumber)} ${sg.user.name}`,
      };
    })
    .value();

  return (
    <main className="w-full">
      <header className="flex flex-row items-center gap-3 py-5">
        <div className="flex w-full justify-between">
          <div className="flex flex-row items-center gap-3">
            <div className="flex flex-row items-center gap-2">
              <SelectBar
                options={grades}
                value={selectedOptions.grade}
                onChange={(value: number) => handleOptionChange('grade', value)}
                placeholder="학년 선택"
                size={40}
                className="w-30"
                priorityFontClass="text-primary-gray-900"
              />
              <SelectBar
                disabled={!selectedOptions.grade}
                options={klasses}
                value={selectedOptions.klass}
                onChange={(value: number) => handleOptionChange('klass', value)}
                placeholder="반 선택"
                size={40}
                className={clsx('w-30', { 'cursor-not-allowed': !selectedOptions.grade })}
                priorityFontClass={clsx({ 'text-primary-gray-900': selectedOptions.grade })}
              />
              <SelectBar
                disabled={!isOptionSelected}
                options={students}
                value={selectedStudentId}
                onChange={(value: number) => setSelectedStudentId(value)}
                placeholder="이름 선택"
                size={40}
                className="w-30"
                priorityFontClass={clsx({ 'text-primary-gray-900': isOptionSelected })}
              />
              {/* <Button.lg className="border border-primary-gray-700 " onClick={() => resetOption()} children="초기화" /> */}
            </div>
            <div className="h-[40px] w-[244px]">
              <LayeredTabs.TwoDepth
                onChange={(selectedType: IBProject) => setProjectType(selectedType)}
                value={projectType}
                fullWidth
              >
                <Tab
                  value="ALL"
                  childrenWrapperClassName={clsx(
                    projectType === 'ALL' ||
                      projectType === 'CAS' ||
                      'relative after:absolute after:right-0 after:h-[14px] after:w-[1px] after:bg-primary-gray-200 after:content-[""] after:z-10',
                  )}
                >
                  <p className={clsx({ 'text-primary-gray-700': projectType === 'ALL' })}>전체</p>
                </Tab>
                <Tab
                  value="CAS"
                  childrenWrapperClassName={clsx(
                    projectType === 'CAS' ||
                      projectType === 'EE' ||
                      'relative after:absolute after:right-0 after:h-[14px] after:w-[1px] after:bg-primary-gray-200 after:content-[""] after:z-10',
                  )}
                >
                  <p className={clsx({ 'text-primary-gray-700': projectType === 'CAS' })}>CAS</p>
                </Tab>
                <Tab
                  value="EE"
                  childrenWrapperClassName={clsx(
                    projectType === 'EE' ||
                      projectType === 'TOK' ||
                      'relative after:absolute after:right-0 after:h-[14px] after:w-[1px] after:bg-primary-gray-200 after:content-[""] after:z-10',
                  )}
                >
                  <p className={clsx({ 'text-primary-gray-700': projectType === 'EE' })}>EE</p>
                </Tab>
                <Tab value="TOK">
                  <p className={clsx({ 'text-primary-gray-700': projectType === 'TOK' })}>TOK</p>
                </Tab>
              </LayeredTabs.TwoDepth>
            </div>
            {projectType === 'EE' && ( // 'EE'일 때만 출력
              <SelectBar
                options={EE_SUBJECTS}
                value={selectedSubject}
                onChange={(value: string) => setSelectedSubject(value)}
                placeholder="지식영역 전체"
                size={40}
                dropdownWidth="w-[240px]"
              />
            )}
          </div>
          <div className="flex flex-row items-center gap-3">
            <span className="flex flex-row items-center gap-2">
              <Check.Basic onChange={() => setChecked(!checked)} checked={checked} size={20} />
              <Typography variant="body3" className="font-medium text-primary-gray-700">
                담당학생만 보기
              </Typography>
            </span>
            <Input.Basic
              size={40}
              placeholder="이름 검색"
              type="text"
              value={searchStudentName}
              className="w-[160px]"
              isSearch
              onChange={(e) => setSearchStudentName(e.target.value)}
            />
          </div>
        </div>
      </header>
      <section className="flex flex-col">
        {searchStudentName && (
          <div className="flex items-center gap-4 py-4">
            <hr className="flex-1" />
            <Typography variant="body3">
              {searchStudentName} <span className="text-primary-gray-700">검색결과</span>{' '}
              <span className="text-primary-orange-800">{searchResultCount}</span>
            </Typography>
            <hr className="flex-1" />
          </div>
        )}
        <Accordion title="감독교사 지정대기" count={pendingItems.length} isFirst={true}>
          <div className="grid grid-cols-4 gap-4">
            {pendingItems.map((item, index) => (
              <ProjectCard data={item} key={item.id} userType="teacher" />
            ))}
          </div>
        </Accordion>

        <Accordion title="계획중" count={planningItems.length}>
          <div className="grid grid-cols-4 gap-4">
            {planningItems.map((item, index) => (
              <ProjectCard data={item} key={item.id} userType="teacher" />
            ))}
          </div>
        </Accordion>

        <Accordion title="진행중" count={inProgressItems.length}>
          <div className="grid grid-cols-4 gap-4">
            {inProgressItems.map((item, index) => (
              <ProjectCard data={item} key={item.id} userType="teacher" />
            ))}
          </div>
        </Accordion>

        <Accordion title="완료" count={completedItems.length}>
          <div className="grid grid-cols-4 gap-4">
            {completedItems.map((item, index) => (
              <ProjectCard data={item} key={item.id} userType="teacher" />
            ))}
          </div>
        </Accordion>
      </section>
    </main>
  );
}
