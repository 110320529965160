import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SolidSVGIcon from '../icon/SolidSVGIcon';
import SVGIcon from '../icon/SVGIcon';
import { Typography } from './Typography';

interface BreadcrumbProps {
  className?: string;
  data: Record<string, string>;
}

const notLastDepthStyle = 'text-primary-gray-700 hover:cursor-pointer';

export default function Breadcrumb({ className, data }: BreadcrumbProps) {
  const { push } = useHistory();

  return (
    <div className={clsx('flex items-center justify-center gap-2', className)}>
      <SolidSVGIcon.Home weight="bold" color="gray400" onClick={() => push('/')} className="cursor-pointer" />
      {Object.keys(data).map((depth, index) => (
        <React.Fragment key={depth}>
          <SVGIcon.Arrow color="gray400" size={8} rotate={180} />
          <Typography
            variant="caption"
            onClick={() => {
              if (data[depth]) push(data[depth]);
            }}
            key={depth}
            className={clsx(index !== Object.keys(data).length - 1 && notLastDepthStyle)}
          >
            {depth}
          </Typography>
        </React.Fragment>
      ))}
    </div>
  );
}
