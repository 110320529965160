import {
  useInterviewCreateQnaByStudent,
  useInterviewFindInterviewByStudentId,
  useInterviewFindQnaById,
  useInterviewFindQnaByStudentId,
  useInterviewUpdateQnaByStudent,
} from 'src/generated/endpoint';
import { InterviewFindInterviewByStudentIdCategory, RequestCreateQnaDto } from 'src/generated/model';

export const useInterviewGetByStudentId = (id: number, category: InterviewFindInterviewByStudentIdCategory) => {
  const { data, isLoading, refetch } = useInterviewFindInterviewByStudentId(id, { category });
  return {
    data,
    isLoading,
    refetch,
  };
};

export const useInterviewQNAGetByStudentId = (
  studentId: number,
  category: InterviewFindInterviewByStudentIdCategory,
) => {
  const { data, isLoading, refetch } = useInterviewFindQnaByStudentId(studentId, { category });
  return {
    data,
    isLoading,
    refetch,
  };
};

export const useInterviewQNA = (qndId: number) => {
  const { data, isLoading, refetch } = useInterviewFindQnaById(qndId);
  return {
    data,
    isLoading,
    refetch,
  };
};

interface UseIBInterviewOptions {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
  onClose?: () => void;
}

interface IBInterviewProps {
  id: number;
  data: RequestCreateQnaDto;
}

export const useIBInterviewCreate = ({ onSuccess, onError, onClose }: UseIBInterviewOptions) => {
  const { mutate, isLoading, isError, error } = useInterviewCreateQnaByStudent({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('인터뷰 생성 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const createIBInterview = ({ id, data }: IBInterviewProps) => {
    mutate({ id, data });
  };

  return {
    createIBInterview,
    isLoading,
    isError,
    error,
  };
};

export const useIBInterviewUpdate = ({ onSuccess, onError, onClose }: UseIBInterviewOptions) => {
  const { mutate, isLoading, isError, error } = useInterviewUpdateQnaByStudent({
    mutation: {
      onSuccess: (data) => {
        onSuccess?.(data);
        onClose?.();
      },
      onError: (error) => {
        console.error('인터뷰 생성 중 오류 발생:', error);
        onError?.(error);
      },
    },
  });

  const updateIBInterview = ({ id, data }: IBInterviewProps) => {
    mutate({ qnaId: id, data });
  };

  return {
    updateIBInterview,
    isLoading,
    isError,
    error,
  };
};
