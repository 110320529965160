import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetFeedbackExist, useGetUnreadFeedbackCount } from 'src/container/ib-feedback';
import { ResponseIBDto } from 'src/generated/model';
import { ButtonV2 } from '../common/ButtonV2';
import { Typography } from '../common/Typography';
import ColorSVGIcon from '../icon/ColorSVGIcon';
import FeedbackViewer from './FeedbackViewer';

interface ProposalListProps {
  data: ResponseIBDto;
  refetch: () => void;
}

export default function TeacherEE_ProposalList({ data, refetch }: ProposalListProps) {
  const { push } = useHistory();
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useState<number | undefined>(undefined);

  const { data: unReadFeedbackCount } = useGetUnreadFeedbackCount({
    referenceTable: 'IB',
    referenceId: data.id || 0,
  });

  const { data: feedback } = useGetFeedbackExist({
    referenceId: data?.id || 0,
    referenceTable: 'IB',
  });

  useEffect(() => {
    if (unReadFeedbackCount !== undefined) {
      setUnreadCount(unReadFeedbackCount);
    }
  }, [unReadFeedbackCount]);

  const handleFeedbackOpen = () => {
    setFeedbackOpen(true);
    if (unreadCount && unreadCount > 0) {
      setUnreadCount(0);
    }
  };

  return (
    <div>
      <Typography variant="title1" className="px-6 pb-6">
        제안서
      </Typography>
      <table className="w-full">
        <thead className="border-y border-y-primary-gray-100 text-[15px] font-medium text-primary-gray-500">
          <tr>
            <th className="w-[68px] py-[9px] pl-6 pr-2">순위</th>
            <th className="w-[200px] px-2 py-[9px]">지식영역</th>
            <th className="w-[632px] px-2 py-[9px]">제목</th>
            <th className="w-[92px] px-2 py-[9px]">수정일</th>
            <th className="w-[176px] py-[9px] pl-2 pr-6">학생 댓글</th>
          </tr>
        </thead>
        <tbody>
          {data?.proposals
            ?.filter((proposal) => proposal.status !== 'PENDING')
            ?.sort((a, b) => a.rank - b.rank)
            .map((proposal) => (
              <tr key={proposal.id} className="border-b border-b-primary-gray-100">
                <td className="py-4 pl-6 pr-2 text-center">{proposal.rank}</td>
                <td className="overflow-hidden text-ellipsis whitespace-nowrap px-2 py-4 text-center">
                  {proposal.subject}
                </td>
                <td
                  className="max-w-[600px] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap px-2 py-4 text-start"
                  onClick={() => push(`/teacher/ib/ee/${data.id}/proposal/${proposal.id}`)}
                >
                  {proposal.researchTopic}
                </td>
                <td className="px-2 py-4 text-center">{format(new Date(proposal.createdAt), 'yyyy.MM.dd')}</td>
                <td className="flex justify-center py-4 pl-2 pr-6">
                  {(proposal.status === 'ACCEPT' && feedback) ||
                  (proposal.status === 'SUBMIT' && data.status === 'REJECT_PLAN') ? (
                    <ButtonV2
                      variant="outline"
                      color="gray400"
                      size={32}
                      className={`${unreadCount && unreadCount > 0 && 'flex flex-row items-center gap-1'}`}
                      onClick={() => {
                        if (unreadCount) {
                          handleFeedbackOpen();
                        } else {
                          push(`/teacher/ib/ee/${data.id}/proposal/${proposal.id}`);
                        }
                      }}
                    >
                      {unreadCount && unreadCount > 0 ? (
                        <>
                          <ColorSVGIcon.New color="orange800" />
                          보기
                        </>
                      ) : (
                        '보기'
                      )}
                    </ButtonV2>
                  ) : (
                    <>-</>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {feedbackOpen && (
        <FeedbackViewer
          modalOpen={feedbackOpen}
          setModalClose={() => setFeedbackOpen(!feedbackOpen)}
          referenceId={data.id}
          referenceTable="IB"
        />
      )}
    </div>
  );
}
