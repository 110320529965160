import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { BadgeV2 } from 'src/components/common/BadgeV2';
import Breadcrumb from 'src/components/common/Breadcrumb';
import { LayeredTabs, Tab } from 'src/components/common/LayeredTabs';
import { Typography } from 'src/components/common/Typography';
import EssayList from 'src/components/ib/EssayList';
import IBLayout from 'src/components/ib/IBLayout';
import ProposalList from 'src/components/ib/ProposalList';
import RPPFList from 'src/components/ib/RPPFList';
import RRSList from 'src/components/ib/RRSList';
import { useIBGetById } from 'src/container/ib-project-get-student';
import { useLanguage } from 'src/hooks/useLanguage';
import { meState } from 'src/store';

export type EEProject = 'PROPOSAL' | 'ESSAY' | 'RPPF' | 'RRS';
export type LocationState = {
  type?: EEProject;
};

export const EEMainPage = () => {
  const me = useRecoilValue(meState);
  const { t } = useLanguage();
  const { id: idParams } = useParams<{ id: string }>();
  const location = useLocation<LocationState>();
  const initialType = location.state?.type || 'PROPOSAL';
  const id = Number(idParams);
  const [EEType, setEEType] = useState<EEProject>(initialType);
  const { data, isLoading, refetch } = useIBGetById(id, 'EE');

  if (data === undefined) {
    return <div>제안서를 불러올 수 없습니다.</div>;
  }

  const approvedProposalTopic =
    data?.status === 'IN_PROGRESS' || data?.status === 'WAIT_COMPLETE'
      ? data.proposals?.find((proposal) => proposal.status === 'ACCEPT')?.researchTopic
      : `${me?.name}의 EE 제안서`;

  return (
    <div className="col-span-6">
      <div className="h-screen w-full">
        <div className="">
          <IBLayout
            topContent={
              <div>
                <div className="w-full pb-6 pt-16">
                  <div className="flex flex-col items-start gap-3">
                    <div className="flex w-full flex-row items-center justify-between">
                      <div className="flex flex-row items-center gap-1">
                        <BadgeV2 color="dark_green" size={24} type="solid_strong" className="self-start">
                          EE
                        </BadgeV2>
                        <BadgeV2
                          color={
                            data.status === 'IN_PROGRESS' ||
                            data.status === 'WAIT_COMPLETE' ||
                            data.status === 'WAIT_PLAN_APPROVE'
                              ? 'blue'
                              : data.status === 'REJECT_MENTOR' ||
                                data.status === 'REJECT_PLAN' ||
                                data.status === 'REJECT_COMPLETE'
                              ? 'red'
                              : data.status === 'COMPLETE'
                              ? 'green'
                              : 'gray'
                          }
                          size={24}
                          type="line"
                        >
                          {data.status === 'WAIT_PLAN_APPROVE' ? '보완완료' : t(`IBStatus.${data.status}`)}
                        </BadgeV2>
                      </div>
                      <Breadcrumb
                        data={{
                          진행상태: '/ib/student',
                          EE: `/ib/student/ee/${id}`,
                        }}
                      />
                    </div>
                    <Typography variant="heading">
                      {approvedProposalTopic ? approvedProposalTopic : `${me?.name}의 EE 제안서`}
                    </Typography>
                  </div>
                </div>
                <LayeredTabs.OneDepth onChange={(selectedType: EEProject) => setEEType(selectedType)} value={EEType}>
                  <Tab value="PROPOSAL">
                    <p>제안서</p>
                  </Tab>
                  <Tab value="ESSAY">
                    <p>에세이</p>
                  </Tab>
                  <Tab value="RPPF">
                    <p>RPPF</p>
                  </Tab>
                  <Tab value="RRS">
                    <p>RRS</p>
                  </Tab>
                </LayeredTabs.OneDepth>
              </div>
            }
            bottomContent={
              <div className="flex h-full items-center pt-6">
                <div className="flex w-full flex-col rounded-xl bg-white">
                  {EEType === 'PROPOSAL' && <ProposalList data={data} refetch={refetch} />}
                  {EEType === 'ESSAY' && <EssayList data={data} refetch={refetch} />}
                  {EEType === 'RPPF' && (
                    <RPPFList data={data} title={approvedProposalTopic || `${me?.name}의 EE 제안서`} />
                  )}
                  {EEType === 'RRS' && <RRSList id={id} title={approvedProposalTopic || `${me?.name}의 EE 제안서`} />}
                </div>
              </div>
            }
            bottomBgColor="bg-primary-gray-50"
          />
        </div>
      </div>
    </div>
  );
};
