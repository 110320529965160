import {
  useEssayGetSubmissionStatus,
  useEssayGetSubmissionStatusCount,
  useIBGetSubmissionStatus,
  useIBGetSubmissionStatusCount,
  useRPPFGetSubmissionStatus,
  useRPPFGetSubmissionStatusCount,
  useRRSGetSubmissionStatus,
  useRRSGetSubmissionStatusCount,
} from 'src/generated/endpoint';
import {
  EssayGetSubmissionStatusCountParams,
  EssayGetSubmissionStatusParams,
  IBGetSubmissionStatusCountParams,
  IBGetSubmissionStatusParams,
  RPPFGetSubmissionStatusCountParams,
  RPPFGetSubmissionStatusParams,
  RRSGetSubmissionStatusCountParams,
  RRSGetSubmissionStatusParams,
} from 'src/generated/model';

export const useIBProposalStatus = ({ grade, klass, ibType }: IBGetSubmissionStatusCountParams) => {
  const { data, isLoading } = useIBGetSubmissionStatusCount({ grade, klass, ibType });

  return {
    data,
    isLoading,
  };
};

export const useIBProposalSubmissionStatus = ({ grade, klass, ibType, status }: IBGetSubmissionStatusParams) => {
  const { data, isLoading } = useIBGetSubmissionStatus({ grade, klass, status, ibType });
  const students = data?.items;
  return {
    students,
    isLoading,
  };
};

export const useIBEssayStatus = ({ grade, klass, ibType }: EssayGetSubmissionStatusCountParams) => {
  const { data, isLoading } = useEssayGetSubmissionStatusCount({ grade, klass, ibType });

  return {
    data,
    isLoading,
  };
};

export const useIBEssaySubmissionStatus = ({ grade, klass, ibType, status }: EssayGetSubmissionStatusParams) => {
  const { data, isLoading } = useEssayGetSubmissionStatus({ grade, klass, status, ibType });
  const students = data?.items;
  return {
    students,
    isLoading,
  };
};

export const useIBRPPFStatus = ({ grade, klass, ibType }: RPPFGetSubmissionStatusCountParams) => {
  const { data, isLoading } = useRPPFGetSubmissionStatusCount({ grade, klass, ibType });

  return {
    data,
    isLoading,
  };
};

export const useIBRPPFSubmissionStatus = ({ grade, klass, ibType, status }: RPPFGetSubmissionStatusParams) => {
  const { data, isLoading } = useRPPFGetSubmissionStatus({ grade, klass, status, ibType });
  const students = data?.items;
  return {
    students,
    isLoading,
  };
};

export const useIBRRSStatus = ({ grade, klass, ibType }: RRSGetSubmissionStatusCountParams) => {
  const { data, isLoading } = useRRSGetSubmissionStatusCount({ grade, klass, ibType });

  return {
    data,
    isLoading,
  };
};

export const useIBRRSSubmissionStatus = ({ grade, klass, ibType, status }: RRSGetSubmissionStatusParams) => {
  const { data, isLoading } = useRRSGetSubmissionStatus({ grade, klass, status, ibType });
  const students = data?.items;
  return {
    students,
    isLoading,
  };
};
